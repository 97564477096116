import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import Loader from "../../components/Loader/Loader";
import useGetService from "../../services/getservice";
import { useParams } from 'react-router-dom';
const ProductComparisons = () => {
  const [alldata, setAlldata] = useState([]);
  const [tableheaderresponse, settableheaderresponse] = useState([]);
  const [tablebodyresponse, settablebodyresponse] = useState([]);
  let {childcategoryid} = useParams();
  console.log("user",childcategoryid);
  let {response,isLoadingProducts}=useGetService(`api/category/getProductsFromChildCategoryId/${childcategoryid}`);
  useEffect(() => {
    if (response !==""){
     setAlldata(response.data.productList)
     let key={}
     let ByLineInfo=response.data.productList[0].item_info?.ByLineInfo 
    //  let Features=response.data.productList[0].item_info?.Features
     let ProductInfo=response.data.productList[0].item_info?.ProductInfo
     if (ByLineInfo !==undefined){
      key.ByLineInfo=ByLineInfo
     }
    //  if (Features !==undefined){
    //   key.Features=Features
    //  }
     if (ProductInfo !==undefined){
      key.ProductInfo=ProductInfo
     }
     function findAllByKey(obj, keyToFind) {
      return Object.entries(obj)
        .reduce((acc, [key, value]) => (key === keyToFind )
          ? acc.concat(value)
          : (typeof value === 'object')
          ? acc.concat(findAllByKey(value, keyToFind))
          : acc
        , [])
    }
    
    // USAGE
    let displayvalue=findAllByKey(key, 'DisplayValue')
    let labelvalue=findAllByKey(key, 'Label')

     console.log("Great2",key)
     settableheaderresponse(labelvalue)
     settablebodyresponse(displayvalue)
    }
  }, [response]);
  console.log("respon",tablebodyresponse)
  const handleDetail=(id)=>{
    window.open(
      `${id}`,"_blank"
    );
  }
  return (
    <AdminLayout>
      {isLoadingProducts && <Loader></Loader>}
      <ListHeader />
      {/* <ToastContainer /> */}
      {/* <ConfirmationDialog
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
        action={"Delete"}
        onClick={() => handleDelete(selected_id)}
      ></ConfirmationDialog> */}
      {/* <ConfirmationDialog
        isOpen={reset2faModal}
        toggle={() => setReset2faModal(false)}
        action={"Reset 2FA"}
        onClick={() => handleReset2faSubmit(selected_id)}
      ></ConfirmationDialog>
      <ConfirmationDialog
        isOpen={resetModal}
        toggle={() => setResetModal(false)}
        action={"Reset Password"}
        onClick={() => handleResetSubmit(selected_id)}
      ></ConfirmationDialog> */}
      <Modal
        className="modal-dialog-centered"
        // isOpen={userModal}
        // toggle={() => setUserModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            ChildCategory Information
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            // onClick={() => setUserModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="align-items-center" responsive>
            <tbody>
              <tr>
                <th scope="col">Firstname</th>
                <td>Hii</td>
              </tr>
              <tr>
                <th scope="col">Lastname</th>
                <td>Hello</td>
              </tr>
              <tr>
                <th scope="col">Email</th>
                <td>Hello</td>
              </tr>
              <tr>
                <th scope="col">Phone</th>
                <td>Phone</td>
              </tr>
              <tr>
                <th scope="col">Status</th>
                <td>Active</td>
              </tr>
              <tr>
                <th scope="col">Created At</th>
                <td>
                 Hello
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="mb-0">Product Comparisons</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-4 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "80%",
                        display: "inline-block",
                        marginRight: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                        //   onChange={(e) => onSearchChange(e.target.value)}
                        />
                      </div>
                    </div>
                    <UncontrolledDropdown className="inline">
                        <DropdownToggle
                          className="btn-icon-only text-dark"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            style={{ display: "none" }}
                          >
                            Make Active
                          </DropdownItem>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            style={{ display: "none" }}
                          >
                            Make Inactive
                          </DropdownItem>

                          <DropdownItem
                            // href="javascript:void(0)"
                            // onClick={() => history.push("/admin/user/create")}
                          >
                            Add New User
                          </DropdownItem>

                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            style={{ display: "none" }}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive style={{
                marginBottom: '25px'
              }}>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Image</th>
                    <th scope="col">Price</th>
                    {tableheaderresponse?.map((item,i)=>(
                      <th scope="col" key={i}>
                        {item}
                        </th>
                    ))}
                    <th scope="col"/>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.length >0 ?
                  alldata?.map((item,i)=>(
                    <tr key={i}>
                      <td>{item.title?.slice(0,60)} {
                        i ===0 ?<h4 className="background-color">Comparison Winner</h4>:null
                      }</td>
                      <td>
                      <img src={item.primary_image} width="200" height="150" alt="Product" /></td>
                      <td>{item.price}</td>
                      {tablebodyresponse?.map((item2,i2)=>(
                       <td key={i2}>
                        {!item2?"No":item2}
                        </td>
                      ))}
                      <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" container="body">
                            <DropdownItem
                                  // href="javascript:void(0)"
                                  onClick={() => handleDetail(item.detail_page_url)}
                                >
                                  Details
                                </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                    </tr>
                  ))
                  :null}
               
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                     className="disabled"
                    //   className={currentPage === 1 ? "disabled" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                        //   onPageChange(currentPage - 2);
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(5)].map((_, i) => {
                      return (
                        <PaginationItem
                          key={i}
                          className="active"
                        //   className={currentPage === i + 1 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                            //   onPageChange(i);
                            }}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                    <PaginationItem
                    className="disabled"
                    //   className={currentPage === pages ? "disabled" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                        //   onPageChange(currentPage);
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      
    </AdminLayout>
   
  );
};

export default ProductComparisons;
