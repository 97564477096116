import { useQueries } from 'react-query';
import axios from '../Api';
const useParallelService = (apis) => {
    const fetchdata = async (api) => {
        return await axios.get(`${api}`)
    }
    const queryResults = useQueries(
        apis.map((api, index) => ({
            queryKey: ['data', index],
            queryFn: () => fetchdata(api)
        }))
    )
    let isOk = queryResults?.every((query) => query.isSuccess);
    return isOk && queryResults?.map(obj => obj?.isSuccess && obj?.data?.data);
}

export default useParallelService;