import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import ReactQuill from "react-quill";

import ConfirmationDialog from "../../components/confirmation.dialog";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import AdminLayout from "../../layouts/AdminLayout";
// import useDeleteService from "../../services/deleteservice";
import useGetService from "../../services/getservice";
// import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import useConditiongetService from "../../services/conditiongetService";
import Loader from "../../components/Loader/Loader";
import usePostService from "../../services/postservice";

const SubscriptionManagementList = () => {
  // const [addedAttorney, setAddedAttorney] = useState(false);
  const [updatedSubscription, setUpdatedSubscription] = useState(false);
  // const [deleteAttorney, setDeleteAttorney] = useState(false);
  let [userModal, setUserModal] = useState(false);
  const [addSubscription, setAddSubscription] = useState({
    role_id: 6,
    sub_name: "",
    monthly_price: 0,
    tot_call_monthly: 0,
    tot_download_monthly: 0,
    yearly_price: 0,
    tot_call_yearly: 0,
    tot_download_yearly: 0,
    description: "",
    is_active: "0",
    updated_by: 1,
    planId: 0,
  });

  const [roleList, setRolesList] = useState([]);

  const [editModal, setEditModal] = useState("add");
  const [subscriptionList, setSubscriptionList] = useState([]);

  const [selectedSubscriptionList, setSelectedSubscriptionList] =
    useState(null);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [sortName, setSortName] = useState("");

  const [asc, setAsc] = useState("asc");

  const [error, setError] = useState({
    name: "",
    monthly_price: "",
    tot_call_monthly: "",
    tot_download_monthly: "",
    yearly_price: "",
    tot_call_yearly: "",
    tot_download_yearly: "",
    description: "",
  });

  const [getListData, setListData] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const regex = /(<([^>]+)>)/gi;

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    setListData(true);

    // if (searchInput !== "") {
    //   const filteredData = subscriptionList.filter((item) => {
    //     return Object.values(item)
    //       .join("")
    //       .toLowerCase()
    //       .includes(searchInput.toLowerCase());
    //   });
    //   setFilteredResults(filteredData);
    // } else {
    //   setFilteredResults(subscriptionList);
    // }
  };

  useEffect(() => {
    if (asc === "asc") {
      let sorted = subscriptionList.sort((a, b) => a.id - b.id);
      setFilteredResults(sorted);
    } else {
      let sorted = subscriptionList.sort((a, b) => b.id - a.id);
      setFilteredResults(sorted);
    }
  }, [asc]);

  const handleSort = (sort) => {
    setSortName(sort);
    if (asc === "asc") {
      setAsc("desc");
    } else {
      setAsc("asc");
    }
    setListData(true);
  };

  useEffect(() => {
    setListData(true);
  }, []);

  const { response2, isFetchItems } = useConditiongetService(
    `api/subscription/getSubscriptions?sort_name=${sortName}&sort_order=${asc}&search=${searchInput}`,
    getListData
  );
  // const { response: getAllSubscriptions } = useGetService(
  //   `api/subscription/getSubscriptions`
  // );

  let updateSubscriptionData = {
    "sub_name": addSubscription.sub_name,
    "monthly_price": null,
    "yearly_price": null,
    "description": addSubscription.description,
    "is_active": addSubscription.is_active,
    "updated_by": addSubscription.updated_by,
    "tot_call_monthly": addSubscription.tot_call_monthly,
    "tot_download_monthly": addSubscription.tot_download_monthly,
    "tot_call_yearly": addSubscription.tot_call_yearly,
    "tot_download_yearly": addSubscription.tot_download_yearly,
    "planId": addSubscription.planId
  }

  if (selectedSubscriptionList?.monthly_price != addSubscription?.monthly_price || selectedSubscriptionList?.yearly_price != addSubscription?.yearly_price) {
    updateSubscriptionData = {
      ...updateSubscriptionData,
      "monthly_price": addSubscription.monthly_price,
      "yearly_price": addSubscription.yearly_price,
    }
  }

  const updateSubscription = usePostService(
    updateSubscriptionData,
    // `api/subscription/updateSubscription/${selectedSubscriptionList?.id}`,
    `api/subscription/updateSubscriptionPrice`,
    updatedSubscription
  );

  let viewUser = (subscription) => {
    setSelectedSubscriptionList(subscription);

    setUserModal(true);
  };
  let [createModal, setCreateModal] = useState(false);
  let createAttorney = (id) => {
    setCreateModal(true);
  };

  useEffect(() => {
    if (response2 !== "") {
      setSubscriptionList(response2.data.data);
      setListData(false);
    }
  }, [response2]);

  useEffect(() => {
    if (updateSubscription.status !== "200-OK" && updateSubscription !== "") {
      toast.error(updateSubscription.data.message);
    } else if (updateSubscription !== "") {
      toast.success(updateSubscription.data.message);
      setCreateModal(false);
      setListData(true);
    }
    setUpdatedSubscription(false);
    // setSelectedSubscriptionList(null);
    // setAddSubscription({
    //   role_id: 6,
    //   name: "",
    //   monthly_price: 0,
    //   yearly_price: 0,
    //   description: "",
    //   is_active: "0",
    //   updated_by: 1,
    // });
  }, [updateSubscription]);

  const changeHandler = (e) => {
    const temp = { ...addSubscription };
    temp[e.target.name] = e.target.value;
    setAddSubscription(temp);
  };

  const descriptionChangeHandler = (e) => {
    const temp = { ...addSubscription };
    temp["description"] = e;
    setAddSubscription(temp);
  };

  const validateCreateUpdateForm = () => {
    const { sub_name, monthly_price, tot_call_monthly, tot_download_monthly, yearly_price, tot_call_yearly, tot_download_yearly, description } = addSubscription;
    let isError = false,
      errName = "",
      errMonthly_price = "",
      errMonthly_call = "",
      errMonthly_download = "",
      erryearly_price = "",
      erryearly_call = "",
      erryearly_download = "",
      errdescription = "";

    if (!sub_name) {
      errName = "select a Name";
      isError = true;
    }

    if (!monthly_price) {
      errMonthly_price = " Enter the monthly_price";
      isError = true;
    }
    if (tot_call_monthly < 0) {
      errMonthly_call = "Enter a total call monthly";
      isError = true;
    }
    if (tot_download_monthly < 0) {
      errMonthly_download = "Enter a total download monthly";
      isError = true;
    }

    if (!yearly_price) {
      erryearly_price = "Enter the yearly_price yearly";
      isError = true;
    }
    if (tot_call_yearly < 0) {
      erryearly_call = "Enter a total call";
      isError = true;
    }
    if (tot_download_yearly < 0) {
      erryearly_download = "Enter a total download yearly";
      isError = true;
    }
    if (!description) {
      errdescription = "Enter a description";
      isError = true;
    }

    setError({
      ...error,
      name: errName,
      monthly_price: errMonthly_price,
      tot_call_monthly: errMonthly_call,
      tot_download_monthly: errMonthly_download,
      yearly_price: erryearly_price,
      tot_call_yearly: erryearly_call,
      tot_download_yearly: erryearly_download,
      description: errdescription,
    });
    return !isError;
  };

  return (
    <AdminLayout name="Subscription Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />

      <Modal
        className="modal-dialog-centered"
        isOpen={userModal}
        toggle={() => setUserModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Membership Information
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setUserModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="align-items-center" responsive>
            <tbody>
              <tr>
                <th scope="col">Id</th>
                <td>{selectedSubscriptionList?.id}</td>
              </tr>
              <tr>
                <th scope="col">Name</th>
                <td>{selectedSubscriptionList?.name} </td>
              </tr>
              <tr>
                <th scope="col">Monthly Price ($)</th>
                <td> {selectedSubscriptionList?.monthly_price}</td>
              </tr>
              <tr>
                <th scope="col">Total Call Monthly</th>
                <td> {selectedSubscriptionList?.tot_call_monthly || 0}</td>
              </tr>
              <tr>
                <th scope="col">Total Download Monthly</th>
                <td> {selectedSubscriptionList?.tot_download_monthly || 0}</td>
              </tr>
              <tr>
                <th scope="col">Yearly Price ($)</th>
                <td> {selectedSubscriptionList?.yearly_price}</td>
              </tr>
              <tr>
                <th scope="col">Total Call Yearly</th>
                <td> {selectedSubscriptionList?.tot_call_yearly || 0}</td>
              </tr>
              <tr>
                <th scope="col">Total Download Yearly</th>
                <td> {selectedSubscriptionList?.tot_download_yearly || 0}</td>
              </tr>
              <tr>
                <th scope="col">Description</th>
                <td>
                  {" "}
                  {selectedSubscriptionList?.description.replace(regex, "")}
                </td>
              </tr>
              <tr>
                <th scope="col">Role ID.</th>
                <td> {selectedSubscriptionList?.role_name}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={createModal}
        toggle={() => {
          setAddSubscription({
            role_id: 6,
            name: "",
            monthly_price: 0,
            yearly_price: 0,
            description: "",
            is_active: "0",
            updated_by: 1,
          });
          setCreateModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editModal === "add" ? "Add" : "Update"} Subscription
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setAddSubscription({
                name: "",
                monthly_price: 0,
                yearly_price: 0,
                description: "",
                is_active: "0",
                updated_by: 1,
              });
              setCreateModal(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form role="form">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Plan Name
              </label>
              <Input
                type="text"
                name="sub_name"
                id="name"
                value={addSubscription.sub_name}
                onChange={changeHandler}
              >
                {/* <option value="">Select plan name</option> */}

                {/* <option value="Basic">Basic</option>
                <option value="Exclusive">Exclusive</option>
                <option value="Platinum">Platinum</option> */}
              </Input>
              {error.name ? <ErrorMessage errorMsg={error.name} /> : null}
            </FormGroup>
            {/* <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Validity
              </label>
              <Input
                type="select"
                name="validity"
                id="validity"
                value={addSubscription.validity}
                onChange={changeHandler}
              >
                <option value="">Select validity</option>

                <option value="month">Month</option>
                <option value="year">Year</option>
              </Input>
              {error.category ? (
                <ErrorMessage errorMsg={error.category} />
              ) : null}
            </FormGroup> */}
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Monthly Price ($)
              </label>
              <Input
                defaultValue=""
                id="monthly_price"
                placeholder="monthly_price"
                type="text"
                name="monthly_price"
                autoComplete="nope"
                value={addSubscription.monthly_price}
                onChange={changeHandler}
              />
              {error.price ? <ErrorMessage errorMsg={error.price} /> : null}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="tot_call_monthly">
                Total Call Monthly
              </label>
              <Input
                defaultValue=""
                id="tot_call_monthly"
                placeholder="Total Call Monthly"
                type="number"
                name="tot_call_monthly"
                autoComplete="nope"
                value={addSubscription.tot_call_monthly}
                onChange={changeHandler}
              />
              {error.tot_call_monthly ? <ErrorMessage errorMsg={error.tot_call_monthly} /> : null}
            </FormGroup>

            <FormGroup>
              <label className="form-control-label" htmlFor="tot_download_monthly">
                Total Download Monthly
              </label>
              <Input
                defaultValue=""
                id="tot_download_monthly"
                placeholder="Total Download Monthly"
                type="number"
                name="tot_download_monthly"
                autoComplete="nope"
                value={addSubscription.tot_download_monthly}
                onChange={changeHandler}
              />
              {error.tot_download_monthly ? <ErrorMessage errorMsg={error.tot_download_monthly} /> : null}
            </FormGroup>


            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Yearly Price ($)
              </label>
              <Input
                defaultValue=""
                id="yearly_price"
                placeholder="yearly_price"
                type="text"
                name="yearly_price"
                autoComplete="nope"
                value={addSubscription.yearly_price}
                onChange={changeHandler}
              />
              {error.price ? <ErrorMessage errorMsg={error.price} /> : null}
            </FormGroup>

            <FormGroup>
              <label className="form-control-label" htmlFor="tot_call_yearly">
                Total Call Yearly
              </label>
              <Input
                defaultValue=""
                id="tot_call_yearly"
                placeholder="Total Call Yearly"
                type="number"
                name="tot_call_yearly"
                autoComplete="nope"
                value={addSubscription.tot_call_yearly}
                onChange={changeHandler}
              />
              {error.tot_call_yearly ? <ErrorMessage errorMsg={error.tot_call_yearly} /> : null}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="tot_download_yearly">
                Total Download Yearly
              </label>
              <Input
                defaultValue=""
                id="tot_download_yearly"
                placeholder="Total Download Yearly"
                type="number"
                name="tot_download_yearly"
                autoComplete="nope"
                value={addSubscription.tot_download_yearly}
                onChange={changeHandler}
              />
              {error.tot_download_yearly ? <ErrorMessage errorMsg={error.tot_download_yearly} /> : null}
            </FormGroup>



            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                Description
              </label>
              <ReactQuill
                style={{ height: 300 }}
                className="pb-4"
                id="description"
                name="description"
                theme="snow"
                value={addSubscription.description}
                onChange={descriptionChangeHandler}
              />

              {error.price ? <ErrorMessage errorMsg={error.price} /> : null}
            </FormGroup>

            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={() => {
                  if (validateCreateUpdateForm()) {
                    if (editModal === "add") {
                      // setAddedAttorney(true);
                    } else {
                      setUpdatedSubscription(true);
                    }
                  }
                }}
              >
                {editModal === "add" ? "Create" : "Update"}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-7">
                    <h3 className="mb-0">Subscription List</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-4 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "80%",
                        display: "inline-block",
                        marginLift: "15px",
                      }}
                    >
                      {/* <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                          onChange={(e) => searchItems(e.target.value)}
                        />
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div>
                      <button onClick={() => handleSort()}>
                        <i class="fa fa-fw fa-sort"></i>
                      </button>
                    </div>
                  </div> */}
                  {/* <div className="col-md-3">
                    <div
                      onClick={() => {
                        setEditModal("add");
                        createAttorney(1);
                      }}
                    >
                      <h3 className="mb-0"> +Add Subscription</h3>
                    </div>
                  </div> */}
                </div>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  marginBottom: "25px",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      ID
                      <i
                        onClick={() => handleSort("id")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Name
                      <i
                        onClick={() => handleSort("name")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>

                    <th scope="col">
                      Monthly Price ($)
                      <i
                        onClick={() => handleSort("monthly_price")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Total Call Monthly
                      <i
                        onClick={() => handleSort("")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Total Download Monthly
                      <i
                        onClick={() => handleSort("")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>

                    <th scope="col">
                      Yearly Price ($)
                      <i
                        onClick={() => handleSort("yearly_price")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Total Call Yearly
                      <i
                        onClick={() => handleSort("")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Total Download Yearly
                      <i
                        onClick={() => handleSort("")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Description
                      <i
                        onClick={() => handleSort("description")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                {
                  // searchInput.length > 1
                  //   ? filteredResults.map((subscription, index) => {
                  //     return (
                  //       <tr>
                  //         <td key={index}>{subscription?.id}</td>
                  //         <td>{subscription?.name}</td>
                  //         <td>{subscription?.monthly_price}</td>
                  //         <td>{subscription?.tot_call_monthly}</td>
                  //         <td>{subscription?.tot_download_monthly}</td>
                  //         <td>{subscription?.yearly_price}</td>
                  //         <td>{subscription?.tot_call_yearly}</td>
                  //         <td>{subscription?.tot_download_yearly}</td>
                  //         <td>{subscription?.description.replace(regex, "")}</td>
                  //         <td className="text-right">
                  //           <UncontrolledDropdown>
                  //             <DropdownToggle
                  //               className="btn-icon-only text-light"
                  //               href="#pablo"
                  //               role="button"
                  //               size="sm"
                  //               color=""
                  //               onClick={(e) => e.preventDefault()}
                  //             >
                  //               <i className="fas fa-ellipsis-v" />
                  //             </DropdownToggle>
                  //             <DropdownMenu
                  //               className="dropdown-menu-arrow"
                  //               container="body"
                  //             >
                  //               <DropdownItem
                  //                 onClick={() => viewUser(subscription)}
                  //               >
                  //                 View
                  //               </DropdownItem>
                  //               <DropdownItem
                  //                 onClick={() => {
                  //                   setEditModal("edit");
                  //                   setAddSubscription({
                  //                     first_name: subscription.first_name,
                  //                     last_name: subscription.last_name,
                  //                     phone: subscription.phone,
                  //                     email: subscription.email,
                  //                   });
                  //                   createAttorney(1);
                  //                   setSelectedSubscriptionList(subscription);
                  //                 }}
                  //               >
                  //                 Edit
                  //               </DropdownItem>
                  //               <DropdownItem
                  //                 onClick={() => {
                  //                   setConfirmationModal(true);
                  //                   setSelectedSubscriptionList(subscription);
                  //                 }}
                  //               >
                  //                 Delete
                  //               </DropdownItem>
                  //             </DropdownMenu>
                  //           </UncontrolledDropdown>
                  //         </td>
                  //       </tr>
                  //     );
                  //   })
                  //   : 
                  subscriptionList.length > 0 &&
                  subscriptionList.map((subscription, index) => {
                    console.log("subscription?.id==>>", subscription?.id);
                    return (
                      <tr key={index}>
                        <td>{subscription?.id}</td>
                        <td>{subscription?.name}</td>
                        <td>{subscription?.monthly_price}</td>
                        <td>{subscription?.tot_call_monthly}</td>
                        <td>{subscription?.tot_download_monthly}</td>
                        <td>{subscription?.yearly_price}</td>
                        <td>{subscription?.tot_call_yearly}</td>
                        <td>{subscription?.tot_download_yearly}</td>
                        <td>{subscription?.description.replace(regex, "")}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              container="body"
                            >
                              <DropdownItem
                                onClick={() => viewUser(subscription)}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setEditModal("edit");
                                  setAddSubscription({
                                    // name: subscription.name,
                                    sub_name: subscription.name,
                                    monthly_price: subscription.monthly_price,
                                    yearly_price: subscription.yearly_price,
                                    description: subscription.description,
                                    is_active: "0",
                                    updated_by: 1,
                                    tot_call_monthly: subscription?.tot_call_monthly,
                                    tot_download_monthly: subscription?.tot_download_monthly,
                                    tot_call_yearly: subscription?.tot_call_yearly,
                                    tot_download_yearly: subscription?.tot_download_yearly,
                                    planId: subscription?.id
                                  });
                                  createAttorney(1);
                                  setSelectedSubscriptionList(subscription);
                                }}
                              >
                                Edit
                              </DropdownItem>
                              {/* <DropdownItem
                                  onClick={() => {
                                    setConfirmationModal(true);
                                    setSelectedAttorneyList(subscription);
                                    // {

                                    // }
                                    // // onClick={() => handleDelete(court?.id)}
                                  }}
                                >
                                  Delete
                                </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </Table>
              {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className="disabled"
                    //   className={currentPage === 1 ? "disabled" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          //   onPageChange(currentPage - 2);
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(5)].map((_, i) => {
                      return (
                        <PaginationItem
                          key={i}
                          className="active"
                        //   className={currentPage === i + 1 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              //   onPageChange(i);
                            }}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                    <PaginationItem
                      className="disabled"
                    //   className={currentPage === pages ? "disabled" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          //   onPageChange(currentPage);
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
            </Card>
          </div>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default SubscriptionManagementList;
