import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";
import ReactQuill from "react-quill";
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import lblErr from "../../language/CreateUpdateErr";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import useGetService from "../../services/getservice";
// import TextField from "../../components/TextField/TextField";
const CreateUpdateContant = (props) => {
    let { contantId } = useParams();
    let navigate = useNavigate();
    let [fromData, setFromData] = useState({
        id: contantId || '',
        title: '',
        description: ''
    });

    let [mode, setMode] = useState("create");
    const [buttonState, setbuttonState] = useState(false);
    const [updateButtonState, setUpdateButtonState] = useState(false);

    let [error, setError] = useState({
        title: '',
        description: '',
    });

    useEffect(() => {
        if (contantId) {
            setMode('update');
        }
    }, [])


    let handleInput = (e) => {
        setFromData((preItem) => ({ ...preItem, [e.target.name]: e.target.value }))
    }

    const { response } = useGetService(`/api/cms/getAllCmsList`);
    // let response = usePostService(fromData, '/api/user/add_user', buttonState);
    let updateResponse = usePostService(fromData, '/api/user/update_usr', updateButtonState);

    useEffect(() => {
        if (response.status === "200-OK") {
            let contentList = response?.data?.data;
            let content = contentList.find(contentItem => parseInt(contentItem?.id) === parseInt(contantId));
            setFromData({ id: parseInt(content?.id), title: content?.title, description: content?.description });
        }
        // title: content?.title, description: content?.description
    }, [response]);

    // useEffect(() => {
    //     if (updateResponse !== "") {
    //         setbuttonState(false)
    //         if (updateResponse.status !== "200-OK") {
    //             toast.error(updateResponse.data.message);
    //         }
    //         else {
    //             toast.success(updateResponse.data.message)
    //             navigate("/content-management");
    //         }
    //     }
    // }, [updateResponse]);

    const descriptionChangeHandler = (e) => {
        const temp = { ...fromData };
        temp["description"] = e;
        setFromData(temp);
    };

    // let handleSubmit = () => {
    //     if (validateCreateUpdateForm()) {
    //         if (mode === 'create')
    //             setbuttonState(true);
    //         else
    //             setUpdateButtonState(true);
    //     }
    // }

    // let validateCreateUpdateForm = () => {
    //     let isError = false,
    //         errTitle = "",
    //         errDescription = "";

    //     if (!title) {
    //         errTitle = 'Enter Title';
    //         isError = true;
    //     }

    //     if (!description) {
    //         errDescription = lblErr.enterLastName;
    //         isError = true;
    //     }

    //     setError({
    //         ...error,
    //         title: errTitle,
    //         description: errDescription
    //     });
    //     return !isError;
    // };
    let { title, description, id } = fromData;
    console.log("fromData===>>>", fromData);
    return (
        <AdminLayout>
            <>
                {/* {loading && <Loader></Loader>} */}
                <ListHeader />
                {/* <ToastContainer /> */}
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col" >
                            <div className="row">
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">
                                                        {(mode === 'create') ? 'Create New Content' : 'Update Content'}
                                                        {/* <AdminLayout>
                            Hello world
                        </AdminLayout> */}
                                                        {/* {currentURL.includes('create')
                            ? "Create New Admin"
                            : "Update Admin"} */}
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Form>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Content information
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-title"
                                                                >
                                                                    Title
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-title"
                                                                    placeholder="Title"
                                                                    type="text"
                                                                    name="title"
                                                                    // autoComplete="nope"
                                                                    value={title}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.title ? (
                                                                    <ErrorMessage errorMsg={error.title} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label className="form-control-label" htmlFor="input-first-name">
                                                                    Description
                                                                </label>
                                                                {/* <TextField value={description} onChangeText={descriptionChangeHandler} /> */}
                                                                {/* <ReactQuill
                                                                    style={{ height: 300 }}
                                                                    className="pb-4"
                                                                    id="description"
                                                                    name="description"
                                                                    theme="snow"
                                                                    value={description}
                                                                    onChange={descriptionChangeHandler}
                                                                /> */}

                                                                {error.description ? <ErrorMessage errorMsg={error.description} /> : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <hr className="my-4" />
                                                <div className="pl-lg-4">
                                                    <FormGroup className="d-flex justify-content-end">
                                                        {
                                                            (mode === 'update') ? <Button
                                                                color="info"
                                                                className="no-transform"
                                                                onClick={(e) => console.log('hit')}
                                                            >
                                                                Update
                                                            </Button> : <Button
                                                                color="info"
                                                                className="no-transform"
                                                                onClick={(e) => console.log('hit')}
                                                            >
                                                                Submit
                                                            </Button>
                                                        }
                                                        <Button
                                                            color="warning"
                                                            className="no-transform"
                                                            onClick={() => navigate("/content-management")}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </FormGroup>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        </AdminLayout>
    );
};

export default CreateUpdateContant;