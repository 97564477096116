import React, {useState, useEffect} from 'react'
import { Col, Container, Row } from 'reactstrap'
import TopBar from './TopBar';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardHome from './DashboardHome';
import ChatIcon from '../../components/svg/ChatIcon';
import { useClientType } from '../../ContextApi/ClientTypeContext';


const Dashboard = () => {
    const [activeTab, setActiveTab] = useState('transfer');
    const [activeComponent, setActiveComponent] = useState(<DashboardHome />);
    const [title, setTitle] = useState('Dashboard');
    const { clientType } = useClientType();

    const handleComponentChange = (component) => {
      setActiveComponent(component);
    };

    
  return (
    <div>
        <Container fluid className='normal-dashboard'>
            <Row>
                <Col md={2} className='dashboard-sidebar px-0'> 
                    <Sidebar 
                        onComponentChange={handleComponentChange} 
                        onTitleChange={setTitle} 
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        clientType={clientType}
                    />
                </Col>
                <Col md={10} className='dashboard-main-content'>
                    <Container fluid className='px-0 mx-0 p-0 m-0'>
                        <Row className='px-0 mx-0 p-0 m-0'>
                            <Col md={12} className='px-0'>
                                <TopBar title={title} />
                            </Col>

                            <Col md={12} className='px-0'>
                                {activeComponent}
                            </Col>

                            <Col md={12} className='px-0'>
                                <p className='copyright-footer d-flex align-items-center'>
                                    Copyright © 2024 Dualmoney.com. All Rights Reserved | 
                                    <Link to=''>Terms and Condition</Link> | 
                                    <Link to=''>Privacy Policy</Link> | 
                                    <Link to=''>About Us</Link>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>

            <div className='chat-icon'>
                <ChatIcon color='#fff' fill="#CC9752" />
            </div>
        </Container>
    </div>
  )
}

export default Dashboard