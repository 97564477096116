import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query';
import axios from '../Api';
const useDeleteService = (api, getbutton) => {
    const [response, setresponse] = useState("");
    const { isLoading: isPostingTutorial, mutate: deleteItem } = useMutation(
        async () => {
            return await axios.delete(`${api}`);
        },
        {
            onSuccess: (res) => {
                const result = {
                    status: res.status + "-" + res.statusText,
                    headers: res.headers,
                    data: res.data,
                };
                setresponse(result)
                // setPostResult(fortmatResponse(result));
            },
            onError: (err) => {
                setresponse(err.response)
                // setPostResult(fortmatResponse(err.response?.data || err));
            },
        }
    );
    useEffect(() => {
        if (getbutton !== false) {
            deleteItem()
        }
    }, [getbutton]);
    return response
}

export default useDeleteService