import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../components/Validation/ErrorMessage';
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  FormGroup,
  Input,
  Button,
  Form,
} from 'reactstrap';
// core components
// core components
import ListHeader from '../../components/Headers/ListHeader';
import AdminLayout from '../../layouts/AdminLayout';
import Loader from '../../components/Loader/Loader';
import useGetService from '../../services/getservice';
import ReactQuill from 'react-quill';
import usePutService from '../../services/putservice';
import { toast } from 'react-toastify';
import useConditiongetService from '../../services/conditiongetService';
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs';

const regex = /(<([^>]+)>)/gi;
const ContantList = () => {
  const navigate = useNavigate();
  let [contentList, setContentList] = useState([]);
  const [updatedContent, setUpdatedContent] = useState(false);
  let [editDetails, setEditDetails] = useState({});
  const [homedata, sethomedata] = useState([]);
  const [aboutdata, setaboutdata] = useState([]);
  const [servicedata, setservicedata] = useState([]);
  const [membershipdata, setmembershipdata] = useState([]);
  const [individualdata, setindividualdata] = useState([]);
  const [privacydata, setprivacydata] = useState([]);
  const [condition, setcondition] = useState('');
  let [error, setError] = useState({
    title: '',
    description: '',
  });

  let { response: response2, isFetchItems } = useGetService(
    `/api/admin/service/getCmsList`
  );
  // const { response2, isFetchItems } = useConditiongetService(`/api/admin/service/getCmsList`, getContent);
  let updateContent = {
    title: editDetails?.title,
    description: editDetails?.description,
  };
  const updateContentManagement = usePutService(
    updateContent,
    `/api/cms/updateCms/${editDetails?.id}`,
    updatedContent
  );

  useEffect(() => {
    if (response2.status === '200-OK') {
      const homeObjects = [];
      const aboutObjects = [];
      const serviceObjects = [];
      const membershipObjects = [];
      const individualObjects = [];
      const privacyObjects = [];
      response2?.data?.data.forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((key) => {
          if (key.startsWith('Home')) {
            homeObjects.push(item[key]);
          }
          if (key.startsWith('About')) {
            aboutObjects.push(item[key]);
          }
          if (key.startsWith('Service')) {
            serviceObjects.push(item[key]);
          }
          if (key.startsWith('Membership')) {
            membershipObjects.push(item[key]);
          }
          if (key.startsWith('Individual')) {
            individualObjects.push(item[key]);
          }
          if (key.startsWith('Privacy')) {
            privacyObjects.push(item[key]);
          }
        });
      });
      sethomedata(homeObjects);
      setaboutdata(aboutObjects);
      setservicedata(serviceObjects);
      setmembershipdata(membershipObjects);
      setindividualdata(individualObjects);
      setprivacydata(privacyObjects);

      setContentList(response2?.data?.data);
    }
  }, [response2]);
  useEffect(() => {
    if (
      updateContentManagement.status !== '200-OK' &&
      updateContentManagement !== ''
    ) {
      toast.error(updateContentManagement.data.message);
    } else if (updateContentManagement !== '') {
      toast.success(updateContentManagement.data.message);
    }
    setUpdatedContent(false);
  }, [updateContentManagement]);
  const navigationHandler = (pagename, id) => {
    if (pagename.indexOf('header') !== -1) {
      navigate(`/header-description/${id}`);
    }
    if (pagename.indexOf('description') !== -1) {
      navigate(`/contact-details/${id}`);
    }
    if (pagename.indexOf('rights') !== -1) {
      navigate(`/right-description/${id}`);
    }
  };
  const handlecondition = (type) => {
    if (condition === type) {
      setcondition('');
    } else setcondition(type);
  };

  return (
    <AdminLayout name="Content Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="mb-0">Contents List</h3>
                  </div>
                  <div
                    style={{ textAlign: 'right' }}
                    className="col-md-4 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: '80%',
                        display: 'inline-block',
                        marginRight: '15px',
                      }}
                    ></div>
                  </div>
                </div>
              </CardHeader>
              <Table
                className="align-items-center table-flush s-table s-table--content-list"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="u-width--95-p">
                      Contact Type
                    </th>
                    <th scope="col" className="u-width--5-p">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className="border-bottom border-gray-200 u-cursor--pointer"
                    onClick={() => handlecondition('Home')}
                  >
                    <td className="border-0 u-min-height--53 font-weight-bolder">
                      Home page
                    </td>
                    <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53">
                      {condition === 'Home' ? (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactUp />
                        </div>
                      ) : (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactDown />
                        </div>
                      )}
                    </td>
                  </tr>
                  {condition === 'Home' ? (
                    <tr className="border-bottom border-gray-200 u-background--color-blue-1-1">
                      <td colSpan={2} className="p-0 u-min-height--53">
                        {condition === 'Home' &&
                          homedata?.map((item, i) => (
                            <tr
                              className="border-bottom border-gray-200 w-100 u-cursor--pointer"
                              key={i}
                            >
                              <td
                                className="border-0 u-min-height--53 u-width--95-p"
                                onClick={() =>
                                  navigationHandler(item.page_name, item?.id)
                                }
                              >
                                {item.name}
                              </td>
                              <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53 u-width--5-p">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light mr-0"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    container="body"
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        navigationHandler(
                                          item.page_name,
                                          item?.id
                                        )
                                      }
                                    >
                                      Edit
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  <tr
                    className="border-bottom border-gray-200 u-cursor--pointer"
                    onClick={() => handlecondition('About')}
                    // onClick={() => navigationHandler(item.page_name, item?.id)}
                  >
                    <td className="border-0 u-min-height--53 font-weight-bolder">
                      About page
                    </td>
                    <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53">
                      {condition === 'About' ? (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactUp />
                        </div>
                      ) : (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactDown />
                        </div>
                      )}
                    </td>
                  </tr>
                  {condition === 'About' ? (
                    <tr className="border-bottom border-gray-200 u-background--color-blue-1-1">
                      <td colSpan={2} className="p-0 u-min-height--53">
                        {condition === 'About' &&
                          aboutdata?.map((item, i) => (
                            <tr
                              className="border-bottom border-gray-200 w-100 u-cursor--pointer"
                              key={i}
                            >
                              <td
                                className="border-0 u-min-height--53 u-width--95-p"
                                onClick={() =>
                                  navigationHandler(item.page_name, item?.id)
                                }
                              >
                                {item.name}
                              </td>
                              <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53 u-width--5-p">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light mr-0"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    container="body"
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        navigationHandler(
                                          item.page_name,
                                          item?.id
                                        )
                                      }
                                    >
                                      Edit
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  <tr
                    className="border-bottom border-gray-200 u-cursor--pointer"
                    onClick={() => handlecondition('Service')}
                    // onClick={() => navigationHandler(item.page_name, item?.id)}
                  >
                    <td className="border-0 u-min-height--53 font-weight-bolder">
                      Service page
                    </td>
                    <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53">
                      {condition === 'Service' ? (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactUp />
                        </div>
                      ) : (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactDown />
                        </div>
                      )}
                    </td>
                  </tr>
                  {condition === 'Service' ? (
                    <tr className="border-bottom border-gray-200 u-background--color-blue-1-1">
                      <td colSpan={2} className="p-0 u-min-height--53">
                        {condition === 'Service' &&
                          servicedata?.map((item, i) => (
                            <tr
                              className="border-bottom border-gray-200 w-100 u-cursor--pointer"
                              key={i}
                            >
                              <td
                                className="border-0 u-min-height--53 u-width--95-p"
                                onClick={() =>
                                  navigationHandler(item.page_name, item?.id)
                                }
                              >
                                {item.name}
                              </td>
                              <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53 u-width--5-p">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light mr-0"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    container="body"
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        navigationHandler(
                                          item.page_name,
                                          item?.id
                                        )
                                      }
                                    >
                                      Edit
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  <tr
                    className="border-bottom border-gray-200 u-cursor--pointer"
                    onClick={() => handlecondition('MemberShip')}
                    // onClick={() => navigationHandler(item.page_name, item?.id)}
                  >
                    <td className="border-0 u-min-height--53 font-weight-bolder">
                      Membership page
                    </td>
                    <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53">
                      {condition === 'MemberShip' ? (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactUp />
                        </div>
                      ) : (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactDown />
                        </div>
                      )}
                    </td>
                  </tr>
                  {condition === 'MemberShip' ? (
                    <tr className="border-bottom border-gray-200 u-background--color-blue-1-1">
                      <td colSpan={2} className="p-0 u-min-height--53">
                        {condition === 'MemberShip' &&
                          membershipdata?.map((item, i) => (
                            <tr
                              className="border-bottom border-gray-200 w-100 u-cursor--pointer"
                              key={i}
                            >
                              <td
                                className="border-0 u-min-height--53 u-width--95-p"
                                onClick={() =>
                                  navigationHandler(item.page_name, item?.id)
                                }
                              >
                                {item.name}
                              </td>
                              <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53 u-width--5-p">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light mr-0"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    container="body"
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        navigationHandler(
                                          item.page_name,
                                          item?.id
                                        )
                                      }
                                    >
                                      Edit
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  <tr
                    className="border-bottom border-gray-200 u-cursor--pointer"
                    onClick={() => handlecondition('IndividualDepo')}
                    // onClick={() => navigationHandler(item.page_name, item?.id)}
                  >
                    <td className="border-0 u-min-height--53 font-weight-bolder">
                      Individualdepo page
                    </td>
                    <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53">
                      {condition === 'IndividualDepo' ? (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactUp />
                        </div>
                      ) : (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactDown />
                        </div>
                      )}
                    </td>
                  </tr>
                  {condition === 'IndividualDepo' ? (
                    <tr className="border-bottom border-gray-200 u-background--color-blue-1-1">
                      <td colSpan={2} className="p-0 u-min-height--53">
                        {condition === 'IndividualDepo' &&
                          individualdata?.map((item, i) => (
                            <tr
                              className="border-bottom border-gray-200 w-100 u-cursor--pointer"
                              key={i}
                            >
                              <td
                                className="border-0 u-min-height--53 u-width--95-p"
                                onClick={() =>
                                  navigationHandler(item.page_name, item?.id)
                                }
                              >
                                {item.name}
                              </td>
                              <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53 u-width--5-p">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light mr-0"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    container="body"
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        navigationHandler(
                                          item.page_name,
                                          item?.id
                                        )
                                      }
                                    >
                                      Edit
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  <tr
                    className="border-bottom border-gray-200 u-cursor--pointer"
                    onClick={() => handlecondition('Privacy')}
                    // onClick={() => navigationHandler(item.page_name, item?.id)}
                  >
                    <td className="border-0 u-min-height--53 font-weight-bolder">
                      Privacy page
                    </td>
                    <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53">
                      {condition === 'Privacy' ? (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactUp />
                        </div>
                      ) : (
                        <div className="u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden">
                          <BsChevronCompactDown />
                        </div>
                      )}
                    </td>
                  </tr>
                  {condition === 'Privacy' ? (
                    <tr className="border-bottom border-gray-200 u-background--color-blue-1-1">
                      <td colSpan={2} className="p-0 u-min-height--53">
                        {condition === 'Privacy' &&
                          privacydata?.map((item, i) => (
                            <tr
                              className="border-bottom border-gray-200 w-100 u-cursor--pointer"
                              key={i}
                            >
                              <td
                                className="border-0 u-min-height--53 u-width--95-p"
                                onClick={() =>
                                  navigationHandler(item.page_name, item?.id)
                                }
                              >
                                {item.name}
                              </td>
                              <td className="d-flex align-self-center justify-content-center border-0 u-min-height--53 u-width--5-p">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light mr-0"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    container="body"
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        navigationHandler(
                                          item.page_name,
                                          item?.id
                                        )
                                      }
                                    >
                                      Edit
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {/* {contentList.map(contentItem => {
                                        let item = Object.values(contentItem)[0];
                                        return (<tr key={contentItem?.id}>
                                            <td style={{ width: '30%',cursor:"pointer" }} 
                                            onClick={() => navigationHandler(item.page_name, item?.id)}
                                            >{item.name}</td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        href="#pablo"
                                                        role="button"
                                                        size="sm"
                                                        color=""
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" container="body">
                                                        <DropdownItem
                                                            onClick={() => navigationHandler(item.page_name, item?.id)}
                                                        >
                                                            Edit
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>)
                                    })
                                    } */}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default ContantList;
