import React, { useState } from 'react'
import { LuUpload } from "react-icons/lu";
import Signature from './Signature';


const UploadButton = ({title, isRequired, btnType = 'button', text, files , key , uploadedFile}) => {

    const [fileName, setFileName] = useState(uploadedFile || '');

    const handleFileChange = (e , key) => {
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name); 
        files(file)
      }
    };
const getSignature  = (file) => {
  console.log(file)
  files(file)
}

  return (
    <div className='upload-btn '>
        <p className='title'>{title}<span className='text-danger'>*</span></p>
        <div className={`upload-box ${btnType === 'button' ? 'bordered' : 'solid'} p-4 overflow-hidden`} style={{height: '140px'}}>
            { 
                btnType === 'button' ? (
                    <div className='d-flex flex-column justify-content-center align-items-center upload-wrapper'>
                    <input type="file" className="hidden-file w-100" onChange={(e) => handleFileChange(e ,key)}
                    />
                    <LuUpload />
                    <p className="mb-0 pb-0 mt-3">
                        {fileName ? `Uploaded: ${fileName}` : <p className='text-center'> Upload jpg, png images <p> (5mb max)</p></p>} <br />
                    </p>
                    
                    </div>
                ) : (
                    <div className='d-flex justify-content-center content signature-wrap p-0'>
                        <Signature signatureFile={(file) => getSignature(file)}  />
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default UploadButton