import React from 'react';
import ButtonStatus from './ButtonStatus';

const tableData = [
  {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
  {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
  {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
  {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
  {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
  {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
  {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
]

const TransactionTable = () => {
  return (
    <div className="container mt-4 px-0">
      <table className="table table-bordered-bottom">
        <thead>
          <tr>
            <th>Transaction Id</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th className='text-center'>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-data'>
          {
            tableData.map( (Item) => {
              const {TransactionId, date, amount, type, status, action} = Item;
              return(
                <tr key={TransactionId}>
                    <td className='pl-5'>{TransactionId}</td>
                    <td className='pr-3'>{date}</td>
                    <td className={status === 'Successful' ? 'green-color pr-3' : 'grey-color pr-3'}>{amount}</td>
                    <td className='pr-5'>{type} <span>{status !== 'Successful' ? <img src='../assets/triangle-alert.svg' /> : ''}</span></td>
                    <td className='pr-3 text-center' align='center'>
                      {
                        status === 'Successful' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Successful'
                            className=''
                            status='Successful'
                          />
                        ) : (
                          <ButtonStatus
                            img='../assets/circle-red.svg'
                            content='Rejected'
                            className=''
                            status='Rejected'
                          />
                        )
                      }
                    </td>
                    <td className='action'>
                      {
                        status === 'Successful' ? ( 
                          <>
                          <button className="">
                            <img src='../assets/eye-orange.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-orange.svg' />
                          </button>
                          </>
                        ) : (
                          <>
                          <button className="">
                            <img src='../assets/eye-grey.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-grey.svg' />
                          </button>
                          </>
                        )
                      }
                    </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
