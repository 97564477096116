import React, {useState} from 'react'
import { LuWallet2, } from "react-icons/lu";
import { GoBell } from "react-icons/go";
import { MdKeyboardArrowDown } from "react-icons/md";


const TopBar = ({title}) => {
    const [isNotification, setIsNotification] = useState(true);

  return (
    <div className='topbar d-flex justify-content-between pt-4'>
        <p className='left mb-0'>{title}</p>
        <div className='right d-flex justify-content-end align-items-center'>
            <div className='wallet'><LuWallet2 /></div>
            <div className={`${isNotification ? 'notified' : 'no-notified'} ml-3`}><GoBell /></div>
            <div className='user d-flex align-items-center'>
                <div className='user-pic ml-4'><img src='../assets/user-pic.webp' /></div>
                <p className='mb-0 pl-2'>Daniel Gallego</p>
                <MdKeyboardArrowDown className='icon' />
            </div>
        </div>
    </div>
  )
}

export default TopBar