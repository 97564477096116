import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import ConfirmationDialog from "../../components/confirmation.dialog";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import AdminLayout from "../../layouts/AdminLayout";
import useDeleteService from "../../services/deleteservice";
import useGetService from "../../services/getservice";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import useConditiongetService from "../../services/conditiongetService";
import Loader from "../../components/Loader/Loader";
import { validate_email } from "../../helpers/utils";
import ViewDetailsModal from "../lawfirm_management/ViewDetailsModal";
import AddModal from "./AddModal";
import EditModal from "./EditModal";

const CourtReporterManagementList = () => {
  // const [addedCourtReporter, setAddedCourtReporter] = useState(false);
  // const [updatedCourtReporter, setUpdatedCourtReporter] = useState(false);
  const [deleteCourtReporter, setDeleteCourtReporter] = useState(false);
  let [userModal, setUserModal] = useState(false);
  const [addCourtReporter, setAddCourtReporter] = useState({
    first_name: "",
    last_name: "",
    phone: '',
    email: "",
    created_by: 1,
    status: 1,
    city: '',
    state: '',
    zipcode: '',
    street_address: ''
  });
  const [editModal, setEditModal] = useState("");
  const [courtReporterList, setCourtReporterList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [selectedCourtReporterList, setSelectedCourtReporterList] =
    useState(null);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [sortName, setSortName] = useState("");

  const [asc, setAsc] = useState("asc");

  // const [error, setError] = useState({
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   // phone: "",
  //   created_by: 1,
  //   city: '',
  //   state: '',
  //   zipcode: '',
  //   street_address: ''
  // });

  const [getListData, setListData] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const [totalPage, settotalPage] = useState(0);
  const [page, setPage] = useState(1);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    setListData(true)
    // if (searchInput !== "") {
    //   const filteredData = courtReporterList.filter((item) => {
    //     return Object.values(item)
    //       .join("")
    //       .toLowerCase()
    //       .includes(searchInput.toLowerCase());
    //   });
    //   setFilteredResults(filteredData);
    // } else {
    //   setFilteredResults(courtReporterList);
    // }
  };

  useEffect(() => {
    if (asc === "asc") {
      let sorted = courtReporterList.sort((a, b) => a.id - b.id);
      setFilteredResults(sorted);
    } else {
      let sorted = courtReporterList.sort((a, b) => b.id - a.id);
      setFilteredResults(sorted);
    }
  }, [asc]);

  const handleSort = (sort) => {
    setSortName(sort)
    if (asc === "asc") {
      setAsc("desc");
    } else {
      setAsc("asc");
    }
    setListData(true);
  };

  const { response } = useGetService(`/api/case/getAllCaseViewList`);

  useEffect(() => {
    if (response.status === "200-OK") {
      setRoleList(response?.data?.data?.allRoleList.map(roleItem => ({ value: roleItem?.id, label: roleItem?.role })));
      setListData(true);
    }
  }, [response]);

  const { response2: getCourtReporters, isFetchItems } = useConditiongetService(
    `api/attendee/getCourtReporters?sort_name=${sortName}&sort_order=${asc}&search=${searchInput}&size=20&page=${page}`,
    getListData
  );
  const deletedCourtReporter = useDeleteService(
    `api/attendee/delete_court_reporter/${selectedCourtReporterList?.id}`,
    deleteCourtReporter
  );

  let viewUser = (court) => {
    setSelectedCourtReporterList(court);
    setUserModal(true);
  };
  let [createModal, setCreateModal] = useState(false);
  let createPhysicians = (id) => {
    setCreateModal(true);
  };

  useEffect(() => {
    if (getCourtReporters?.status === "200-OK" && getListData) {
      setCourtReporterList(getCourtReporters.data.data);
      settotalPage(getCourtReporters?.data?.totalPages);
      setListData(false);
    }
  }, [getCourtReporters]);

  useEffect(() => {
    if (
      deletedCourtReporter.status !== "200-OK" &&
      deletedCourtReporter !== ""
    ) {
      toast.error("Court Reporter Deleted Error");
    } else if (deletedCourtReporter !== "") {
      toast.success("Court Reporter Deleted Successfully");
      setListData(true);
    }
    setSelectedCourtReporterList(null);
    setDeleteCourtReporter(false);
  }, [deletedCourtReporter]);

  const handleDelete = (id) => {
    setDeleteCourtReporter(true);
  };

  const onPageChange = (value) => {
    setPage(value)
    setListData(true)
  }

  return (
    <AdminLayout name="Court Reporter Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />

      <ConfirmationDialog
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
        action={"Delete"}
        onClick={() => {
          setConfirmationModal(false);
          handleDelete();
        }}
      ></ConfirmationDialog>

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-0">Court Reporter List</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-8 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "40%",
                        display: "inline-block",
                        marginRight: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                          onChange={(e) => searchItems(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-2">
                    <div>
                      <button onClick={() => handleSort()}>
                        <i class="fa fa-fw fa-sort"></i>
                        Sort
                      </button>
                    </div>
                  </div> */}
                    {/* <div className="col-md-2">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setEditModal("add");
                        createPhysicians(1);
                      }}
                    >
                      <h3 className="mb-0"> Send Invitation</h3>
                    </div>
                  </div> */}

                    <UncontrolledDropdown className="inline">
                      <DropdownToggle
                        className="btn-icon-only text-dark"
                        href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Make Active
                        </DropdownItem>
                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Make Inactive
                        </DropdownItem>

                        <DropdownItem
                          // href="javascript:void(0)"
                          onClick={() => {
                            setEditModal("add");
                            createPhysicians(1);
                          }}
                        >
                          Send Invitation
                        </DropdownItem>

                        <DropdownItem
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          style={{ display: "none" }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                  </div>
                </div>
              </CardHeader>

              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  marginBottom: "25px",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    {/* <th scope="col">
                      ID
                      <i
                        onClick={() => handleSort("id")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th> */}
                    <th scope="col">
                      Name
                      <i
                        onClick={() => handleSort("first_name")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Email ID
                      <i
                        onClick={() => handleSort("email")}
                        class="fa fa-fw fa-sort"
                      ></i>
                    </th>
                    <th scope="col">
                      Phone No
                      {/* <i
                        onClick={() => handleSort("phone")}
                        class="fa fa-fw fa-sort"
                      ></i> */}
                    </th>

                    <th scope="col">Approved</th>
                    <th scope="col">Status</th>

                    <th scope="col" />
                  </tr>
                </thead>
                {searchInput.length > 1
                  ? courtReporterList.map((court, index) => {
                    return (
                      <tr>
                        {/* <td key={index}>{court.id}</td> */}
                        <td>
                          {court.first_name} {court.last_name}
                        </td>
                        <td>{court.email}</td>
                        <td>{court.phone || '--'}</td>
                        <td>{(court.is_approved == 1) ? 'Active' : 'Inactive'}</td>
                        <td>{(court.is_suspend == 1) ? 'Suspend' : court.is_active == 1 ? 'Active' : 'Inactive'}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              container="body"
                            >
                              <DropdownItem onClick={() => viewUser(court)}>
                                View
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setEditModal("edit");
                                  setAddCourtReporter({
                                    first_name: court.first_name,
                                    last_name: court.last_name,
                                    phone: court?.phone || '',
                                    email: court.email,
                                    status: court.is_suspend,
                                    // city: court.city,
                                    city: court?.city ? [{
                                      value: court.city,
                                      label: court.city,
                                    }] : [],
                                    // state: court.state,
                                    state: court?.state ? [{
                                      value: court.state,
                                      label: court.state,
                                    }] : [],
                                    street_address: court.street_address,
                                    zipcode: court.zipcode
                                  });
                                  createPhysicians(1);
                                  setSelectedCourtReporterList(court);
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setConfirmationModal(true);
                                  setSelectedCourtReporterList(court);
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })
                  : courtReporterList !== undefined &&
                  courtReporterList.map((phycisins, index) => {
                    return (
                      <tr key={index}>
                        {/* <td key={index}>{phycisins.id}</td> */}
                        <td>
                          {phycisins.first_name} {phycisins.last_name}
                        </td>
                        <td>{phycisins.email}</td>
                        <td>{phycisins.phone || '--'}</td>
                        <td>{(phycisins.is_approved == 1) ? 'Active' : 'Inactive'}</td>
                        <td>{(phycisins.is_suspend == 1) ? 'Suspend' : phycisins.is_active == 1 ? 'Active' : 'Inactive'}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              container="body"
                            >
                              <DropdownItem
                                onClick={() => viewUser(phycisins)}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setEditModal("edit");
                                  setAddCourtReporter({
                                    first_name: phycisins.first_name,
                                    last_name: phycisins.last_name,
                                    phone: phycisins?.phone || '',
                                    email: phycisins.email,
                                    status: phycisins.is_suspend,
                                    // city: phycisins.city,
                                    city: phycisins?.city ? [{
                                      value: phycisins.city,
                                      label: phycisins.city,
                                    }] : [],
                                    // state: phycisins.state,
                                    state: phycisins?.state ? [{
                                      value: phycisins.state,
                                      label: phycisins.state,
                                    }] : [],
                                    street_address: phycisins.street_address,
                                    zipcode: phycisins.zipcode
                                  });
                                  createPhysicians(1);
                                  setSelectedCourtReporterList(phycisins);
                                }}
                              >
                                Edit
                              </DropdownItem>
                              {/* <DropdownItem
                                onClick={() => {
                                  setConfirmationModal(true);
                                  setSelectedCourtReporterList(phycisins);
                                  // {

                                  // }
                                  // // onClick={() => handleDelete(court?.id)}
                                }}
                              >
                                Delete
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
              </Table>
              <CardFooter className="py-4">
                {totalPage > 1 ?
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        // className="disabled"
                        className={page < 2 ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page - 2);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPage)].map((_, i) => {
                        return (
                          <PaginationItem
                            key={i}
                            // className="active"
                            className={page === i + 1 ? "active" : ""}
                          >
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                onPageChange(i + 1);
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      <PaginationItem
                        // className="disabled"
                        className={page + 2 > totalPage ? "disabled" : ""}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page + 2);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                  : null}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {userModal && <ViewDetailsModal title={'Court Reporter Information'} isOpen={userModal} attorneyDetail={selectedCourtReporterList} roleList={roleList} viewHandler={() => setUserModal(false)} />}

      {editModal === 'add' && <AddModal isOpen={editModal === 'add'} addModalHandler={(isSuccess) => {
        if (isSuccess == 1) {
          setEditModal("")
          setListData(true)
        } else { setEditModal("") }
      }} />}

      {editModal === 'edit' && <EditModal
        userId={selectedCourtReporterList?.id}
        isOpen={editModal === 'edit'}
        roleList={roleList}
        attorneyDetails={addCourtReporter}
        editModalHandler={(isSuccess) => {
          if (isSuccess == 1) {
            setEditModal("")
            setListData(true)
          } else {
            setEditModal("")
          }
        }} />}

    </AdminLayout >
  );
};

export default CourtReporterManagementList;
