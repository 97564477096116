import React, { useState, useEffect } from 'react'
import axios from '../Api';
import { useQuery } from 'react-query';
const useConditiongetService = (api, getbutton) => {
  const [response2, setresponse2] = useState("");
  const { isLoading: isLoadingProducts, refetch: getresponse, isFetching: isFetchItems } = useQuery(
    "query-get-condition-data",
    async () => {
      return await axios.get(api);
    },
    {
      enabled: false,
      onSuccess: (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        // setGetResult(fortmatResponse(result));
        setresponse2(result)
        console.log("api",res.data)
        // setAllProducts(result?.data)
      },
      onError: (err) => {
        // setGetResult(fortmatResponse(err.response?.data || err));
      },
    }
  );
  useEffect(() => {
      getresponse()
    
  }, [getbutton]);
  //   useEffect(() => {
  //     getresponse()
  //   }, []);
  return { response2, isLoadingProducts, isFetchItems }
}

export default useConditiongetService