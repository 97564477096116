import React, { useState } from 'react';

const evaluatePasswordStrength = (password) => {
  return password.length < 5 ? 'Bad' : 'Good';
};

const PasswordStrength = ({password}) => {
  const strength = evaluatePasswordStrength(password);

  const getColor = (index) => {
    const charsTyped = password.length;
    if (charsTyped < 5) {
      if (index < 2) {
        return index < Math.ceil(charsTyped / 2) ? 'red' : '#C6BDAF';
      }
      return '#C6BDAF';
    } else {
      if (index < 2) {
        return '#19B25F';
      } else {
        return index < Math.max(0, charsTyped - 2) ? '#19B25F' : '#C6BDAF';
      }
    }
  };

  const getTextColor = (index) => {
    if (index < password.length) {
      return strength === 'Good' ? '#19B25F' : 'red';
    }
    return '#C6BDAF'; // Default color
  };

  return (
    <div className="password-strength-container d-flex">
    {password.length > 0 && (
        <>
          <label htmlFor="password mb-0 d-flex align-items-center">Password:</label>
          <div className="password-strength-indicator d-flex align-items-center">
              <div className="line" style={{ backgroundColor: getColor(0) }}></div>
              <div className="line" style={{ backgroundColor: getColor(1) }}></div>
              <div className="line" style={{ backgroundColor: getColor(2) }}></div>
              <div className="line" style={{ backgroundColor: getColor(3) }}></div>
          </div>
        </>
    )}
    {password.length > 0 && (
        <p className="strength-text mb-0" style={{ color: getTextColor() }}>{strength}</p>
    )}
    </div>
  );
};

export default PasswordStrength;
