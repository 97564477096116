import React, { useState, useEffect } from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import ListHeader from '../../components/Headers/ListHeader';
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Modal,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Button,
} from "reactstrap";
import useDebounce from "../../components/custom-hooks/useDebounce";
import useConditiongetService from '../../services/conditiongetService';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import { DateFormat } from '../../helpers/utils';

const ContactList = () => {
    const navigate = useNavigate()
    const [contactList, setcontactList] = useState([]);
    const [getListData, setListData] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [contactModal, setContactModal] = useState(false);

    const [sortName, setSortName] = useState("createdAt");
    const [totalPage, settotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [asc, setAsc] = useState("asc");

    let debouncedSearchTerm = useDebounce(searchInput, 500);

    const { response2, isFetchItems } = useConditiongetService(
        `/api/contact/getContactList?page=${page}&size=20&order=${asc}`,
        getListData
    );

    useEffect(() => {
        setListData(true)
        setPage(1)
        setSortName("createdAt")
        setAsc("asc")
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (response2.status === "200-OK" && getListData) {
            setcontactList(response2?.data?.list)
            setListData(false);
            settotalPage(Math.floor(parseInt(response2?.data?.totalCount) / 5))
        }
        if (response2.status === "202-Accepted" && getListData) {
            setcontactList(response2?.data?.data?.rows)
            setListData(false)
            settotalPage(0)
        }
    }, [response2]);

    const handlesort = (sort, condition) => {
        setAsc(condition)
        setSortName(sort)
        setListData(true)
    }
    const onPageChange = (value) => {
        setPage(value)
        setListData(true)
    }

    let viewUser = (contact) => {
        setSelectedContact(contact);
        setContactModal(true);
    };

    return (
        <AdminLayout name="Request Contact">
            {isFetchItems && <Loader />}
            <ListHeader />


            <Modal
                className="modal-dialog-centered"
                isOpen={contactModal}
                toggle={() => setContactModal(false)}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Contact Information
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setContactModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Table className="align-items-center" responsive>
                        <tbody>
                            <tr>
                                <th scope="col">Id</th>
                                <td>{selectedContact?.id}</td>
                            </tr>
                            <tr>
                                <th scope="col">Name</th>
                                <td>
                                    {selectedContact?.first_name}{" "}
                                    {selectedContact?.last_name}
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Email ID</th>
                                <td>{selectedContact?.email}</td>
                            </tr>
                            <tr>
                                <th scope="col">Phone No.</th>
                                <td>{selectedContact?.phone}</td>
                            </tr>
                            <tr>
                                <th scope="col" >Message</th>
                                <td style={{whiteSpace:"normal"}}>{selectedContact?.message}</td>
                            </tr>
                            <tr>
                                <th scope="col">Status</th>
                                <td>{parseInt(selectedContact?.status) ? 'Active' : 'Inactive'}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Modal>


            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h3 className="mb-0">Request Contact List</h3>
                                    </div>
                                    <div
                                        style={{ textAlign: "right" }}
                                        className="col-md-8 align-right"
                                    >
                                        <div
                                            className="mb-0 form-group inline"
                                            style={{
                                                width: "40%",
                                                display: "inline-block",
                                                marginRight: "15px",
                                            }}
                                        >
                                            {/* <div className="input-group-alternative input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-search"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    placeholder="Search"
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(e) => searchItems(e.target.value)}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>
                            <Table
                                className="align-items-center table-flush"
                                responsive
                                style={{
                                    marginBottom: "25px",
                                }}
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">
                                            First Name
                                        </th>
                                        <th scope="col">
                                            Last Name
                                        </th>
                                        <th scope="col">
                                            Email
                                        </th>
                                        <th scope="col">
                                            Phone
                                        </th>
                                        <th scope="col">
                                            Create Date
                                            <span>
                                                <i className="fa fa-fw fa-sort-down"
                                                    onClick={() => handlesort("createdAt", "desc")}
                                                    style={{
                                                        color: sortName === "createdAt" && asc === "desc" ? 'green' : null,
                                                        width: "0.5em",
                                                        cursor: "pointer"
                                                    }}
                                                ></i>
                                                <i className="fa fa-fw fa-sort-up"
                                                    onClick={() => handlesort("createdAt", "asc")}
                                                    style={{
                                                        color: sortName === "createdAt" && asc === "asc" ? 'green' : null,
                                                        width: "0.5em",
                                                        cursor: "pointer"
                                                    }}
                                                ></i>
                                            </span>
                                        </th>
                                        <th scope="col">
                                            Status
                                        </th>
                                        <th scope="col" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contactList?.length > 0 ? contactList?.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item?.first_name}</td>
                                            <td>{item?.last_name}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.phone}</td>
                                            <td>
                            {item?.createdAt
                              ? moment(
                                DateFormat(item?.createdAt)
                              ).format('MM/DD/yyyy H:mm')
                              : '--'}
                          </td>
                                            {/* <td>{moment(item?.createdAt).format('MM/DD/YYYY')}</td> */}
                                            <td>{item?.status == "1" ? "Active" : "Inactive"}</td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        href="#pablo"
                                                        role="button"
                                                        size="sm"
                                                        color=""
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" container="body">

                                                        <DropdownItem
                                                            onClick={() => viewUser(item)}
                                                        >
                                                            View
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )) :
                                        <tr>
                                            <td>
                                                Sorry no request contact found
                                            </td>

                                        </tr>
                                    }
                                </tbody>

                            </Table>
                            <CardFooter className="py-4">
                                {totalPage > 1 ?
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem
                                                // className="disabled"
                                                className={page < 2 ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page - 2);
                                                    }}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {[...Array(totalPage)].map((_, i) => {
                                                return (
                                                    <PaginationItem
                                                        key={i}
                                                        className={page === i + 1 ? "active" : ""}
                                                    >
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onPageChange(i + 1);
                                                            }}
                                                        >
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                            })}
                                            <PaginationItem
                                                // className="disabled"
                                                className={page + 2 > totalPage ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page + 2);
                                                    }}
                                                >
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                    : null}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>

        </AdminLayout>
    )
}

export default ContactList;