import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
} from "reactstrap";
import { useNavigate } from 'react-router';
import ReactQuill from "react-quill";
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
// import useConditiongetService from '../../services/conditiongetService';
import useGetService from '../../services/getservice';
import usePutService from '../../services/putservice';
import ErrorMessage from '../../components/Validation/ErrorMessage';

const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["clean"],
];

const TermsConditions = () => {
    const navigate = useNavigate();
    let [fromData, setFromData] = useState(
        {
            name: "",
            description: "",
        },
    );
    let [error, setError] = useState({
        name: "",
        description: ""
    });
    const [id, setpageId] = useState(26);
    const [pageTitle, _] = useState("Terms & Conditions");
    const [description, setdescription] = useState(false);
    const [updateInfoButtonState, setUpdateInfoButtonState] = useState(false);
    const [uploadpayload, setuploadpayload] = useState('');

    let { response: updateInfoResponse } = useGetService(`/api/admin/getServiceById/${id}`);

    let updateResponse = usePutService(uploadpayload, `/api/admin/updateTermsService/${id}`, updateInfoButtonState)

    useEffect(() => {
        if (updateInfoResponse?.status === "200-OK") {
            let updateDetails = updateInfoResponse?.data?.data;
            setFromData({
                name: updateDetails?.page_title ? updateDetails?.name : '',
                description: updateDetails?.description ? updateDetails?.description : '',
            });
            setpageId(updateDetails?.id);
            setdescription(updateDetails?.description ? updateDetails?.description : '');
        }
    }, [updateInfoResponse]);

    useEffect(() => {
        if (updateResponse?.status === "200-OK" && updateInfoButtonState) {
            toast.success(updateResponse?.data?.message);
            setUpdateInfoButtonState(false);
        }

    }, [updateResponse]);

    const handleInput = (e) => {
        setFromData({ ...fromData, [e.target.name]: e.target.value });
    }

    const validate = () => {
        let isError = false;
        let temp = { ...error };
        if (fromData?.name === "") {
            temp.name = "Title can't be empty"
            isError = true;
        }
        if (description === "") {
            temp.description = "Description can't be empty"
            isError = true;
        }
        setError(temp);
        return isError;
    }

    const handleSubmit = () => {
        let isError = validate();
        if (!isError) {
            if (id) {
                setuploadpayload({ ...fromData, description: description });
                setUpdateInfoButtonState(true);
            }
        }
    }

    return (
        <AdminLayout>
            <>
                {/* {loading && <Loader></Loader>} */}
                <ListHeader />
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <div className="row">
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">
                                                        Update {pageTitle}
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Form>
                                                <h6 className="heading-small text-muted mb-4">
                                                    {pageTitle} Information
                                                </h6>

                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-title"
                                                                >
                                                                    Title
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-title"
                                                                    placeholder="Title"
                                                                    type="text"
                                                                    name="name"
                                                                    autoComplete="nope"
                                                                    value={fromData?.name}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error?.name ? (
                                                                    <ErrorMessage errorMsg={error.name} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-description"
                                                                >
                                                                    Description
                                                                </label>
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    value={description}
                                                                    onChange={setdescription}
                                                                    placeholder="Start writing..."
                                                                    modules={{
                                                                        toolbar: {
                                                                            container: TOOLBAR_OPTIONS,
                                                                        }
                                                                    }}
                                                                />
                                                                {error?.description ? (
                                                                    <ErrorMessage errorMsg={error.description} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </div>

                                                <div></div>
                                                <hr className="my-4" />
                                                <div className="pl-lg-4">
                                                    <FormGroup className="d-flex justify-content-end">
                                                        <Button
                                                            color="info"
                                                            className="no-transform"
                                                            onClick={() => handleSubmit()}
                                                        >
                                                            Update
                                                        </Button>
                                                        <Button
                                                            color="warning"
                                                            className="no-transform"
                                                            onClick={() => navigate("/content-management")}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </FormGroup>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        </AdminLayout>
    )
}

export default TermsConditions;