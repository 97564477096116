import React from "react";

const ListHeader = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
    </>
  );
};

export default ListHeader;
