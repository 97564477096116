
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import useConditiongetService from "../../services/conditiongetService";
import useGetService from "../../services/getservice";

const CourtRepresentativesManagementList = () => {
  const navigate = useNavigate();
  let [userModal, setUserModal] = useState(false);

  const [editModal, setEditModal] = useState("add");
  const { response } = useGetService(`api/attendee/physicians`);

  const [physicianList, setPhysicianList] = useState([]);

  const [selectedPhysicianList, setSelectedPhysicianList] = useState(null);

  let viewUser = (phycisins) => {
    setSelectedPhysicianList(phycisins);
    setUserModal(true);
  };
  let [createModal, setCreateModal] = useState(false);
  let createPhysicians = (id) => {
    setCreateModal(true);
  };

  useEffect(() => {
    if (response != "") {
      setPhysicianList(response.data.data);
      console.log(response.data.data)
    }
  }, [response]);

  return (
    <AdminLayout name="Court Representatives">
      {/* {loading && <Loader></Loader>} */}
      <ListHeader />
      {/* <Modal
        className="modal-dialog-centered"
        isOpen={userModal}
        toggle={() => setUserModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Physician Information
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setUserModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="align-items-center" responsive>
            <tbody>
              <tr>
                <th scope="col">Id</th>
                <td>
                  {selectedPhysicianList?.id}
                </td>
              </tr>
              <tr>
                <th scope="col">Name</th>
                <td>{selectedPhysicianList?.first_name} {selectedPhysicianList?.last_name}</td>
              </tr>
              <tr>
                <th scope="col">Email ID</th>
                <td> {selectedPhysicianList?.email}</td>
              </tr>
              <tr>
                <th scope="col">Phone No.</th>
                <td> {selectedPhysicianList?.phone}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={createModal}
        toggle={() => setCreateModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editModal === "add" ? "Add" : "Update"} Physicians
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setCreateModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form role="form">
            <InputGroup className="mb-3">
              <Input
                placeholder="Name"
                type="name"
                name="username"
                // value={selectedPhysicianList.user_name}
                autoComplete="new-email"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <Input
                placeholder="Phone Number"
                type="name"
                name="username"
                autoComplete="new-email"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <Input
                placeholder="Email Id"
                type="name"
                name="username"
                autoComplete="new-email"
              />
            </InputGroup>

            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                //    onClick={onSignInClick}
              >
                Create
              </Button>
            </div>
          </Form>
        </div>
      </Modal> */}
      {/* Page content */}
      {/* <Container className="mt--7" fluid> */}
        {/* Table */}
        {/* <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="mb-0">List Physicians</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-4 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "80%",
                        display: "inline-block",
                        marginRight: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                          //   onChange={(e) => onSearchChange(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="mb-0 form-group inline">
                      <button>Sort</button>
                    </div>
                    <div
                      className="mb-0 form-group inline"
                      onClick={() => {
                        setEditModal("add");
                        createPhysicians(1);
                      }}
                    >
                      +Add Physician
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  marginBottom: "25px",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email ID</th>
                    <th scope="col">Phone No.</th>

                    <th scope="col" />
                  </tr>
                </thead>

                {physicianList !== undefined &&
                  physicianList.map((phycisins) => (
                    <tbody>
                      <tr>
                        <td>{phycisins.id}</td>
                        <td>
                          {phycisins.first_name} {phycisins.last_name}
                        </td>
                        <td>{phycisins.email}</td>
                        <td>{phycisins.phone}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              container="body"
                            >
                              <DropdownItem
                                href="javascript:void(0)"
                                onClick={() => viewUser(phycisins)}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setEditModal("edit");
                                  createPhysicians(1);
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                              // href="javascript:void(0)"
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className="disabled"
                      //   className={currentPage === 1 ? "disabled" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          //   onPageChange(currentPage - 2);
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(5)].map((_, i) => {
                      return (
                        <PaginationItem
                          key={i}
                          className="active"
                          //   className={currentPage === i + 1 ? "active" : ""}
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              //   onPageChange(i);
                            }}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                    <PaginationItem
                      className="disabled"
                      //   className={currentPage === pages ? "disabled" : ""}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          //   onPageChange(currentPage);
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container> */}
    </AdminLayout>
  );
};

export default CourtRepresentativesManagementList;
