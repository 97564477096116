import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import lblErr from "../../language/CreateUpdateErr";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
const CreateUpdateSetting = (props) => {
    let { settingId } = useParams();
    let navigate = useNavigate();
    let [fromData, setFromData] = useState({
        id: settingId || '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        is_active: true
    });

    let [mode, setMode] = useState("create");
    const [buttonState, setbuttonState] = useState(false);
    const [updateButtonState, setUpdateButtonState] = useState(false);

    let [error, setError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        // password: "",
        // role_id: "",
        phone: "",
        is_active: "0",
    });

    useEffect(() => {
        if (settingId) {
            setMode('update');
        }
    }, [])

    let { first_name, last_name, email, phone, is_active } = fromData;

    let handleInput = (e) => {
        setFromData((preItem) => ({ ...preItem, [e.target.name]: e.target.value }))
    }

    let response = usePostService(fromData, '/api/user/add_user', buttonState);
    let updateResponse = usePostService(fromData, '/api/user/update_user', updateButtonState);

    useEffect(() => {
        if (response !== "") {
            setbuttonState(false)
            if (response.status !== "200-OK") {
                toast.error(response.data.message);
            }
            else {
                toast.success(response.data.message)
                navigate("/user-management");
            }
        }
    }, [response]);

    useEffect(() => {
        if (updateResponse !== "") {
            setbuttonState(false)
            if (updateResponse.status !== "200-OK") {
                toast.error(updateResponse.data.message);
            }
            else {
                toast.success(updateResponse.data.message)
                navigate("/user-management");
            }
        }
    }, [updateResponse]);

    let handleSubmit = () => {
        if (validateCreateUpdateForm()) {
            if (mode === 'create')
                setbuttonState(true);
            else
                setUpdateButtonState(true);
        }
    }

    let validateCreateUpdateForm = () => {
        let isError = false,
            errFirstName = "",
            errLastName = "",
            errEmail = "",
            errPhone = ""
        // errRoleId = "";

        if (!first_name) {
            errFirstName = lblErr.enterFirstName;
            isError = true;
        }

        if (!last_name) {
            errLastName = lblErr.enterLastName;
            isError = true;
        }

        if (!email) {
            errEmail = lblErr.enterAnEmail;
            isError = true;
        } else {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    email.length - lastDotPos > 2
                )
            ) {
                errEmail = lblErr.invalidEmail;
                isError = true;
            }
        }

        // if (!role_id) {
        //   errRoleId = lblErr.selectARole;
        //   isError = true;
        // }

        if (
            phone &&
            phone.toString().length != 10
        ) {
            errPhone = lblErr.phoneLength;
            isError = true;
        }

        // if (!password && mode === 'create') {
        //   errPassword = lblErr.enterAPassword;
        //   isError = true;
        // }

        setError({
            ...error,
            first_name: errFirstName,
            last_name: errLastName,
            email: errEmail,
            // role_id: errRoleId,
            phone: errPhone,
        });
        return !isError;
    };

    return (
        <AdminLayout>
            <>
                {/* {loading && <Loader></Loader>} */}
                <ListHeader />
                {/* <ToastContainer /> */}
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col" >
                            <div className="row">
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">
                                                        {(mode === 'create') ? 'Create New Setting' : 'Update Setting'}
                                                        {/* <AdminLayout>
                            Hello world
                        </AdminLayout> */}
                                                        {/* {currentURL.includes('create')
                            ? "Create New Admin"
                            : "Update Admin"} */}
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Form>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Setting information
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-first-name"
                                                                >
                                                                    First name
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-first-name"
                                                                    placeholder="First name"
                                                                    type="text"
                                                                    name="first_name"
                                                                    autoComplete="nope"
                                                                    value={first_name}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.first_name ? (
                                                                    <ErrorMessage errorMsg={error.first_name} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-last-name"
                                                                >
                                                                    Last name
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-last-name"
                                                                    placeholder="Last name"
                                                                    type="text"
                                                                    name="last_name"
                                                                    autoComplete="nope"
                                                                    value={last_name}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.last_name ? (
                                                                    <ErrorMessage errorMsg={error.last_name} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-email"
                                                                >
                                                                    Email address
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-email"
                                                                    placeholder="Email"
                                                                    type="email"
                                                                    name="email"
                                                                    autoComplete="nope"
                                                                    value={email}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.email ? (
                                                                    <ErrorMessage errorMsg={error.email} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>

                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-last-name"
                                                                >
                                                                    Phone
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-last-name"
                                                                    placeholder="Phone"
                                                                    type="number"
                                                                    name="phone"
                                                                    autoComplete="nope"
                                                                    value={phone}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error.phone ? (
                                                                    <ErrorMessage errorMsg={error.phone} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                        {/* <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Role
                                </label>
                                <Input
                                  type="select"
                                  name="role_id"
                                  id="selectMulti"
                                  value={userCreateUpdate.role_id}
                                  onChange={(e) => handleInput(e)}
                                >
                                  <option value="">--- Select ---</option>
                                  <option value="1">
                                    Hii
                                  </option>
                                  {roles.map((role) => {
                                    return (
                                      <option value={role.id} key={role.id}>
                                        {role.role}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {error.role_id ? (
                                  <ErrorMessage errorMsg={error.role_id} />
                                ) : null}
                              </FormGroup>
                            </Col> */}

                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-first-name"
                                                                >
                                                                    Status
                                                                </label>
                                                                <Input
                                                                    type="select"
                                                                    name="is_active"
                                                                    id="selectMulti"
                                                                    value={is_active}
                                                                    onChange={(e) => handleInput(e)}
                                                                >
                                                                    <option value="1">Active</option>
                                                                    <option value="0">Inactive</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        {/* <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Enable Multifactor Authenticator
                                </label>
                                <Input
                                  type="select"
                                  name="is_twofa_enabled"
                                  id="selectMulti"
                                // value={userCreateUpdate.is_twofa_enabled}
                                // onChange={(e) => handleInput(e)}
                                >
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Temporary Account
                                </label>
                                <Input
                                  type="select"
                                  name="temporary_account"
                                  id="selectMulti"
                                // value={userCreateUpdate.temporary_account}
                                // onChange={(e) => handleInput(e)}
                                >
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>

                                </Input>
                              </FormGroup>
                            </Col> */}
                                                    </Row>
                                                    {/* {userCreateUpdate.is_twofa_enabled === "1" &&
                          // <Row>
                          //   <Col lg="6"></Col>
                          //   <Col lg="6">
                          //     <a
                          //       className="form-control-label"
                          //       href="javascript:void(0)"
                          //       onClick={() => handleResetSubmit()}
                          //     >
                          //       <small>Reset 2FA</small>
                          //     </a>
                          //   </Col>
                          // </Row>
                          <FormGroup className="d-flex justify-content-end">
                            <a
                              className="form-control-label"
                              href="javascript:void(0)"
                              onClick={() => handleResetSubmit()}
                            >
                              <small>Reset 2FA</small>
                            </a>
                          </FormGroup>

                        } */}
                                                </div>
                                                <hr className="my-4" />
                                                <div className="pl-lg-4">
                                                    <FormGroup className="d-flex justify-content-end">
                                                        {
                                                            (mode === 'update') ? <Button
                                                                color="info"
                                                                className="no-transform"
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                Update
                                                            </Button> : <Button
                                                                color="info"
                                                                className="no-transform"
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                Submit
                                                            </Button>
                                                        }
                                                        <Button
                                                            color="warning"
                                                            className="no-transform"
                                                            onClick={() => navigate("/manage-pricing")}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </FormGroup>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        </AdminLayout>
    );
};

export default CreateUpdateSetting;
