import React from 'react'
import "react-toastify/dist/ReactToastify.css";
// reactstrap components
import { Modal, Button, FormGroup } from "reactstrap";

const ConfirmationModal = ({ title, isOpen, toggle, onClick }) => {
    return (
        <Modal className="modal-dialog-centered w-25" isOpen={isOpen} toggle={toggle}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Confimation
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggle}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <h5>
                    {title}
                </h5>

                <FormGroup className="d-flex justify-content-end">
                    <Button
                        color="warning"
                        className="no-transform"
                        href="javascript:void(0)"
                        size="sm"
                        onClick={onClick}
                    >
                        Yes
                    </Button>
                    <Button
                        color="info"
                        className="no-transform"
                        href="javascript:void(0)"
                        size="sm"
                        onClick={toggle}
                    >
                        No
                    </Button>
                </FormGroup>
            </div>
        </Modal>
    )
}

export default ConfirmationModal;