// CustomRadioButton.js
import React from 'react';

const RadioButton = ({ id, name, checked, onChange, onClick, label, value }) => {
    return (
        <div className="radio-button-container" onClick={onClick}>
            <input
                type="radio"
                id={id}
                name={name}
                className="custom-radio-input"
                checked={checked}
                onChange={onChange}
                value={value}
            />
            <label htmlFor={id} className="custom-radio-label">
                <div className="radio-button">
                    <div className={`outer-circle ${checked ? 'checked' : ''}`}>
                        <div className={`inner-circle ${checked ? 'checked' : ''}`}></div>
                    </div>
                </div>
                <span className="radio-button-text">{label}</span>
            </label>
        </div>
    );
};

export default RadioButton;
