import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/Validation/ErrorMessage";
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Modal,
    FormGroup,
    Input,
    Button,
    Form
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import Loader from '../../components/Loader/Loader';
import useGetService from "../../services/getservice";
import ReactQuill from "react-quill";
import usePutService from "../../services/putservice";
import { toast } from "react-toastify";
import useConditiongetService from "../../services/conditiongetService";
import { capitalizeFirstLetter } from "../../helpers/utils";

const regex = /(<([^>]+)>)/gi;
const BannerList = () => {
    const navigate = useNavigate();
    let [userModal, setUserModal] = useState(false);
    let [contentList, setContentList] = useState([]);
    let [allContentList, setAllContentList] = useState([]);
    const [updatedContent, setUpdatedContent] = useState(false);
    let [editDetails, setEditDetails] = useState({});
    const [getContent, setGetContent] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    let [error, setError] = useState({
        title: "",
        description: ""
    });

    useEffect(() => {
        setGetContent(true);
    }, [])

    const { response2, isFetchItems } = useConditiongetService(`/api/admin/getAllBanners`, getContent);
    let updateContent = {
        "title": editDetails?.title,
        "description": editDetails?.description
    }
    const updateContentManagement = usePutService(updateContent, `/api/cms/updateCms/${editDetails?.id}`, updatedContent);

    let viewUser = (contentDetails) => {
        setEditDetails(contentDetails);
        setUserModal(true);
    }
    console.log("res",response2);
    useEffect(() => {
        if (response2.status === "200-OK") {
            setContentList(response2?.data?.data);
            setAllContentList(response2?.data?.data);
            setGetContent(false);
        }
    }, [response2]);

    useEffect(() => {
        if (updateContentManagement.status !== "200-OK" && updateContentManagement !== "") {
            toast.error(updateContentManagement.data.message);
        } else if (updateContentManagement !== "") {
            toast.success(updateContentManagement.data.message);
            setGetContent(true);
        }
        setUpdatedContent(false);
        setUserModal(false);
    }, [updateContentManagement]);

    const changeHandler = (e) => {
        const temp = { ...editDetails };
        temp[e.target.name] = e.target.value;
        setEditDetails(temp);
    };

    const descriptionChangeHandler = (e) => {
        const temp = { ...editDetails };
        temp["description"] = e;
        setEditDetails(temp);
    };

    const validateCreateUpdateForm = () => {
        const { title, description } = editDetails;
        let isError = false,
            errTitle = "",
            errDescription = "";

        if (!title) {
            errTitle = "Enter the title";
            isError = true;
        }

        if (!description) {
            errDescription = " Enter the Description";
            isError = true;
        }

        setError({
            ...error,
            title: errTitle,
            description: errDescription
        });
        return !isError;
    };

    const onSearchChange = (value) => {
        setSearchInput(value);
        let filterList = []
        if (value !== '') {
            filterList = allContentList.filter(contentItem => (contentItem.title + " " + contentItem.description).toLowerCase().indexOf(value) != -1);
        } else {
            filterList = allContentList;
        }
        setContentList(filterList);
    }

    const navigationHandler = (id) => {
       navigate(`/banner-management/edit/${id}`)
    }

    return (
        <AdminLayout name="Banner Management">
            {isFetchItems && <Loader></Loader>}
            <ListHeader />
            
            <Modal
                className="modal-dialog-centered"
                isOpen={userModal}
                toggle={() => setUserModal(false)}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Update Content
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setUserModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <Form role="form">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-title">
                                Title
                            </label>
                            <Input
                                id="input-title"
                                placeholder="Title"
                                type="text"
                                name="title"
                                autoComplete="nope"
                                value={editDetails?.title}
                                onChange={changeHandler}
                            />
                            {error.title ? <ErrorMessage errorMsg={error.title} /> : null}
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-description">
                                Description
                            </label>
                            <ReactQuill
                                style={{ height: 300 }}
                                className="pb-4"
                                id="description"
                                name="description"
                                theme="snow"
                                value={editDetails.description}
                                onChange={descriptionChangeHandler}
                            />

                            {error.description ? <ErrorMessage errorMsg={error.description} /> : null}
                        </FormGroup>

                        <div className="text-center">
                            <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                onClick={() => {
                                    if (validateCreateUpdateForm()) {
                                        setUpdatedContent(true);
                                    }
                                }}
                            >
                                Update
                            </Button>
                        </div>

                    </Form>
                </div>

            </Modal>
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3 className="mb-0">Banners List</h3>
                                    </div>
                                  
                                </div>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive style={{
                                marginBottom: '25px'
                            }}>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ width: '30%' }}>Banner Type</th>
                                        <th scope="col" style={{ width: '0%' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {response2?.data?.data?.length >0 && 
                                    response2?.data?.data?.map((item,i)=>(
                                        <tr key={i}>
                                        <td style={{ width: '30%',cursor:"pointer" }}
                                         onClick={() => navigationHandler(item?.id)}
                                        >{capitalizeFirstLetter(item?.page_name?.slice(0, -7))} Banner</td>
                                        <td className="text-right">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    className="btn-icon-only text-light"
                                                    href="#pablo"
                                                    role="button"
                                                    size="sm"
                                                    color=""
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <i className="fas fa-ellipsis-v" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" container="body">
                                                    <DropdownItem
                                                      onClick={() => navigationHandler(item?.id)}
                                                    >
                                                        Edit
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                    ))}
                                    {/* {contentList.map(contentItem => {
                                        let item = Object.values(contentItem)[0];
                                        return (
                                       
                                        )
                                    })
                                    } */}
                                </tbody>
                            </Table>
                        </Card>
                    </div>
                </Row>
            </Container>

        </AdminLayout>

    );
};

export default BannerList;
