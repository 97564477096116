import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, Modal } from 'reactstrap';
import Select, { components } from 'react-select';
import ErrorMessage from '../../components/Validation/ErrorMessage';
import usePutService from '../../services/putservice';
import { validate_email } from '../../helpers/utils';
import { MenuListFooter, MenuList } from '../../helpers/selectpaginationhelper';
import useDebounce from '../../components/custom-hooks/useDebounce';
import useConditiongetService from '../../services/conditiongetService';
import { toast } from 'react-toastify';
import useParallelServiceConditionally from '../../services/useParallelServiceConditionally';

const EditModal = ({ userId, isOpen, editModalHandler, roleList, attorneyDetails }) => {
    console.log("attorneyDetails==>>", attorneyDetails);
    const [updatedAttorney, setUpdatedAttorney] = useState(false);
    const [addAttorney, setAddAttorney] = useState({
        first_name: attorneyDetails?.first_name || '',
        last_name: attorneyDetails?.last_name || '',
        // phone: 0,
        email: attorneyDetails?.email,
        phone: attorneyDetails?.phone,
        role: attorneyDetails?.role,
        status: attorneyDetails?.status,
        city: attorneyDetails?.city,
        state: attorneyDetails?.state,
        zipcode: attorneyDetails?.zipcode,
        street_address: attorneyDetails?.street_address
    });

    const [stateDetails, setStateDetails] = useState(true);
    const [statedata, setstatedata] = useState([]);
    const [statetotal, setstatetotal] = useState(0);
    const [searchstate, setsearchstate] = useState('');
    const [statepage, setstatepage] = useState(1);
    const [selectstate, setselectstate] = useState(attorneyDetails?.state?.length == 0 ? '' : attorneyDetails?.state[0]?.value);
    const debouncedSearchstate = useDebounce(searchstate, 500);

    const [isLoadCitySate, setIsLoadCitySate] = useState(false);

    const [cityDetails, setCityDetails] = useState(false);
    const [citydata, setcitydata] = useState([]);
    const [citytotal, setcitytotal] = useState(0);
    const [searchcity, setsearchcity] = useState('');
    const [citypage, setcitypage] = useState(1);
    const [selectcity, setselectcity] = useState(attorneyDetails?.city?.length == 0 ? '' : attorneyDetails?.city[0]?.value);
    const debouncedSearchcity = useDebounce(searchcity, 500);

    const [error, setError] = useState({
        first_name: "",
        last_name: "",
        email: "",
        created_by: 1,
        phone: "",
        city: '',
        state: '',
        zipcode: '',
        street_address: ''
    });

    let updateRequestAttorney = {
        first_name: addAttorney.first_name,
        last_name: addAttorney.last_name,
        phone: addAttorney?.phone?.length == 0 ? null : addAttorney?.phone,
        email: addAttorney.email,
        role_id: 5,
        is_suspend: addAttorney.status + '',
        city: selectcity,
        state: selectstate,
        street_address: addAttorney?.street_address || '',
        zipcode: addAttorney?.zipcode || ''
    }

    const changeHandler = (e) => {
        const temp = { ...addAttorney };
        temp[e.target.name] = e.target.value;
        setAddAttorney(temp);
    };

    const checkboxHandler = (e) => {
        const temp = { ...addAttorney };
        temp[e.target.name] = addAttorney.status == 0 ? 1 : 0;
        setAddAttorney(temp);
    }

    const parallelCitySateResponse = useParallelServiceConditionally([`/api/frontend/getCityList/${selectstate}?search=${debouncedSearchcity}&size=10&page=${citypage}`], isLoadCitySate);

    useEffect(() => {
        if (parallelCitySateResponse.length > 0) {
            if (parallelCitySateResponse[0]?.status === 200 && isLoadCitySate == true) {
                setIsLoadCitySate(false);
                if (citydata?.length === 0 && parallelCitySateResponse[0]?.data?.count !== 0) {
                    let finaldata = parallelCitySateResponse[0]?.data?.map((item) => {
                        return {
                            id: item?.id,
                            value: item.city,
                            label: item.city,
                        };
                    });
                    setcitydata(finaldata);
                    setcitytotal(parallelCitySateResponse[0]?.data?.totalCount);
                }
            }
        }
    }, [parallelCitySateResponse])


    const { response2: getStatedata } = useConditiongetService(
        `/api/frontend/getStateList?search=${debouncedSearchstate}&size=10&page=${statepage}`,
        stateDetails
    );

    //   For State
    useEffect(() => {
        if (getStatedata?.status === "200-OK" && stateDetails) {
            if (statedata?.length === 0 && getStatedata?.data?.data?.count !== 0) {
                setStateDetails(false);
                let finaldata = getStatedata?.data.data?.map((item) => {
                    return {
                        value: item.state_id,
                        label: item.state_name,
                    };
                });
                setstatedata(finaldata);
                setstatetotal(getStatedata?.data?.totalCount);
                if (selectstate !== '') {
                    setIsLoadCitySate(true);
                }
            } else if (statedata?.length !== 0) {
                let myObj = 'rows';
                if (getStatedata?.data?.data.hasOwnProperty(myObj)) {
                    setstatedata([...statedata]);
                    setStateDetails(false);
                } else {
                    let finaldata = getStatedata.data.data?.map((item) => {
                        return {
                            value: item.state_id,
                            label: item.state_name,
                        };
                    });
                    setstatedata([...statedata, ...finaldata]);
                    setStateDetails(false);
                }
            }
        }
    }, [getStatedata]);

    useEffect(() => {
        if (debouncedSearchstate !== "") {
            setStateDetails(true);
            setsearchstate(debouncedSearchstate);
        }
    }, [debouncedSearchstate]);

    const addstateOption = async () => {
        await setstatepage((prev) => prev + 1);
        setStateDetails(true);
    };

    const handlestatechange = (e) => {
        setselectstate(e.label);
        setcitydata([]);
        setcitypage(1);
        setAddAttorney((preData) => ({ ...preData, city: [] }))
        setCityDetails(true);
    };

    const { response2: getcitydata } = useConditiongetService(
        `/api/frontend/getCityList/${selectstate}?search=${debouncedSearchcity}&size=10&page=${citypage}`,
        cityDetails
    );

    // For city
    useEffect(() => {
        if (getcitydata?.status === "200-OK" && cityDetails) {
            console.log("getcitydata==>>", getcitydata);
            if (citydata?.length === 0 && getcitydata?.data?.data?.count !== 0) {
                let finaldata = getcitydata.data.data.map((item) => {
                    return {
                        id: item?.id,
                        value: item.city,
                        label: item.city,
                    };
                });
                setcitydata(finaldata);
                setcitytotal(getcitydata?.data?.totalCount);
                setCityDetails(false);
            } else if (citydata?.length !== 9) {
                let myObj = 'rows';
                if (getStatedata?.data?.data.hasOwnProperty(myObj)) {
                    setcitydata([...citydata]);
                    setCityDetails(false);
                } else {
                    let finaldata = getcitydata.data.data.map((item) => {
                        return {
                            value: item.city,
                            label: item.city,
                        };
                    });
                    setcitydata([...citydata, ...finaldata]);
                    setCityDetails(false);
                }
            }
        }
    }, [getcitydata]);

    useEffect(() => {
        if (debouncedSearchcity !== "") {
            setCityDetails(true);
            setsearchcity(debouncedSearchcity);
        }
    }, [debouncedSearchcity]);

    const addcityOption = async () => {
        await setcitypage((prev) => prev + 1);
        setCityDetails(true);
    }

    const updateAttorney = usePutService(
        updateRequestAttorney,
        `api/attendee/update_court_reporter/${userId}`,
        updatedAttorney
    );

    const validateCreateUpdateForm = () => {
        const { first_name, last_name, email, phone } = addAttorney;
        let isError = false,
            errFirstName = "",
            errLastName = "",
            errEmail = "",
            errPhone = "",
            errcity = "";

        if (first_name?.trim().length < 3 || first_name?.trim()?.length > 10) {
            errFirstName = "Firstname can't be less than 3 characters and more than 10 characters";
            isError = true;
        }

        if (last_name?.trim().length < 3 || last_name?.trim()?.length > 10) {
            errLastName = "Lastname can't be less than 3 characters and more than 10 characters";
            isError = true;
        }

        if (!email || !validate_email(email)) {
            errEmail = "Enter an email";
            isError = true;
        } else {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    email.length - lastDotPos > 2
                )
            ) {
                errEmail = "Enter a valid email";
                isError = true;
            }
        }

        if (phone !== '') {
            if (phone.toString().length != 10) {
                errPhone = "Phone must be 10 digit";
                isError = true;
            }
        }
        if (selectstate !== '') {
            if (selectcity === '') {
                errcity = "Select atleast one city";
                isError = true;
            }
        }

        setError({
            ...error,
            first_name: errFirstName,
            last_name: errLastName,
            email: errEmail,
            phone: errPhone,
            city: errcity
        });
        return !isError;
    };

    useEffect(() => {
        if (updateAttorney?.status !== "200-OK" && updateAttorney !== "") {
            toast.error(updateAttorney.data.message);
            setUpdatedAttorney(false);
        } else if (updateAttorney !== "") {
            setUpdatedAttorney(false);
            toast.success(updateAttorney.data.message);
            editModalHandler(1);
        }
    }, [updateAttorney]);

    const onSubmitHandler = (evt) => {
        evt.preventDefault();
        if (validateCreateUpdateForm()) {
            setUpdatedAttorney(true);
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={() => editModalHandler(0)}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Update Court Reporter
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => editModalHandler(0)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Form role="form" onSubmit={(e) => onSubmitHandler(e)}>
                    <InputGroup className="mb-3">
                        <Input
                            defaultValue=""
                            id="input-first-name"
                            placeholder="First name"
                            required
                            maxLength={10}
                            minLength={3}
                            pattern="[A-Za-z]+"
                            type="text"
                            name="first_name"
                            autoComplete="nope"
                            value={addAttorney.first_name}
                            onChange={(e) => {
                                if (!e.target.value.includes(' '))
                                    changeHandler(e)
                            }}
                        />
                        {error.first_name ? (
                            <ErrorMessage errorMsg={error.first_name} />
                        ) : null}
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Input
                            defaultValue=""
                            id="input-last-name"
                            placeholder="Last name"
                            required
                            maxLength={10}
                            minLength={3}
                            pattern="[A-Za-z]+"
                            type="text"
                            name="last_name"
                            autoComplete="nope"
                            value={addAttorney.last_name}
                            onChange={(e) => {
                                if (!e.target.value.includes(' '))
                                    changeHandler(e)
                            }}
                        />
                        {error.last_name ? (
                            <ErrorMessage errorMsg={error.last_name} />
                        ) : null}
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <Input
                            id="input-email"
                            placeholder="Email"
                            type="email"
                            name="email"
                            autoComplete="nope"
                            value={addAttorney?.email}
                            onChange={changeHandler}
                        />
                        {error.email ? <ErrorMessage errorMsg={error.email} /> : null}
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <Input
                            id="input-phone"
                            placeholder="Phone"
                            type="text"
                            maxLength={10}
                            name="phone"
                            autoComplete="nope"
                            value={addAttorney?.phone || ''}
                            onChange={(e) => changeHandler(e)}
                        />
                        {error.phone ? <ErrorMessage errorMsg={error.phone} /> : null}
                    </InputGroup>

                    {/* < InputGroup className="mb-3">
                        <Input
                            type="select"
                            name="role"
                            id="selectMulti"
                            value={addAttorney.role}
                            onChange={changeHandler}
                        >
                            {roleList.length > 0 && roleList.map(roleItem => {
                                return (<option value={roleItem?.value}>{roleItem?.label}</option>)

                            })}
                        </Input>
                    </InputGroup> */}

                    <InputGroup className="mb-3">
                        <Input
                            id="input-email"
                            placeholder="Street Address"
                            type="textarea"
                            name="street_address"
                            autoComplete="nope"
                            value={addAttorney?.street_address}
                            onChange={changeHandler}
                        />
                        {error.street_address ? <ErrorMessage errorMsg={error.street_address} /> : null}
                    </InputGroup>

                    <Col lg="12" className='pl-0 pr-0'>
                        <FormGroup className="d-flex flex-column">

                            <Select
                                className="s-select"
                                styles={{
                                    singleValue: provided => ({
                                        ...provided,
                                        color: '#8898aa',
                                    })
                                }}
                                components={
                                    {
                                        MenuList,
                                        MenuListFooter: (
                                            <MenuListFooter
                                                showing={statedata?.length}
                                                total={statetotal}
                                                onClick={addstateOption}
                                            />
                                        ),
                                    }}
                                onChange={(e) => {
                                    if (e == null) {
                                        handlestatechange({ value: '', label: '' });
                                        setAddAttorney((preData) => ({ ...preData, state: [] }));
                                        setselectcity('')
                                    } else {
                                        handlestatechange(e);
                                        setAddAttorney((preData) => ({ ...preData, state: e }));
                                    }
                                }
                                }
                                isClearable={true}
                                onInputChange={(e) => setsearchstate(e)}
                                value={addAttorney.state}
                                placeholder="Select State"
                                options={statedata}
                            />

                            {error.state ? (
                                <ErrorMessage errorMsg={error.state} />
                            ) : null}
                        </FormGroup>
                    </Col>

                    <Col lg="12" className='pl-0 pr-0'>
                        <FormGroup className="d-flex flex-column">

                            <Select
                                className="s-select"
                                styles={{
                                    singleValue: provided => ({
                                        ...provided,
                                        color: '#8898aa',
                                    })
                                }}
                                components={{
                                    MenuList,
                                    MenuListFooter: (
                                        <MenuListFooter
                                            showing={citydata?.length}
                                            total={citytotal}
                                            onClick={addcityOption}
                                        />
                                    ),
                                }}
                                onChange={(e) => {
                                    if (e == null) {
                                        setselectcity('')
                                        setAddAttorney((preData) => ({ ...preData, city: [] }))
                                    } else {
                                        setselectcity(e.label)
                                        setAddAttorney((preData) => ({ ...preData, city: e }))
                                    }
                                }}
                                isClearable={true}
                                onInputChange={(e) => setsearchcity(e)}
                                value={addAttorney.city}
                                isDisabled={addAttorney?.state?.length === 0}
                                placeholder="Select City"
                                options={citydata}
                            />

                            {error.city ? (
                                <ErrorMessage errorMsg={error.city} />
                            ) : null}
                        </FormGroup>
                    </Col>

                    <InputGroup className="mb-3">
                        <Input
                            id="input-email"
                            placeholder="Zip Code"
                            type="text"
                            name="zipcode"
                            maxLength={6}
                            autoComplete="nope"
                            value={addAttorney?.zipcode}
                            onChange={changeHandler}
                        />
                        {error.zipcode ? <ErrorMessage errorMsg={error.zipcode} /> : null}
                    </InputGroup>

                    <InputGroup>
                        <p className="ml-4">Suspend this account</p>
                        <Input
                            type="checkbox"
                            name="status"
                            id="selectMulti"
                            style={{ marginLeft: '0', width: '15px', height: '16px' }}
                            checked={addAttorney.status == 1 ? true : false}
                            onChange={checkboxHandler}
                        >
                        </Input>
                    </InputGroup>

                    <div className="text-center">
                        <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                        >
                            Update
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal >
    )
}

export default EditModal;