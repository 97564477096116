// DateOfBirthPicker.jsx

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for datepicker
import { CiCalendarDate } from 'react-icons/ci'; // Import the calendar icon

const DatePickerCustom = ({ 
  dateName,
  title,
  required = false,
  value,
 }) => {
  const [startDate, setStartDate] = useState(null); // State to hold the selected date
  const [open, setOpen] = useState(false); // State to control date picker visibility

  // Toggle date picker visibility
  const toggleDatePicker = () => {
    setOpen(prevOpen => !prevOpen);
  };

  // Handle date change
  const handleDateChange = (date) => {
    setStartDate(date);
    setOpen(false); // Close the date picker after selecting a date
  };

  // Handle click outside to close the date picker
  const handleOutsideClick = (event) => {
    if (!event.target.closest('.dob-picker-container')) {
      setOpen(false);
    }
  };

  // Add and remove event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="dob-picker-container">
      <label className='dob-picker-label w-100'>
          {title}
          {required && <span className="required-asterisk text-danger">*</span>}
        </label>
      <div className="dob-picker-input-wrapper w-100">
        <DatePicker
          name={dateName}
          selected={startDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy" // Format the date
          placeholderText="Choose DOB" // Placeholder text
          className="dob-picker-input w-100" // Class for custom styling
          open={open} // Control the visibility
          onClick={toggleDatePicker} // Open date picker when input is clicked
          value={value}
        />
        <CiCalendarDate
          className="dob-picker-icon"
          onClick={toggleDatePicker} // Open date picker when icon is clicked
        />
      </div>
    </div>
  );
};

export default DatePickerCustom;
