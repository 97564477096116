// Dropdown.js
import React, { useState } from 'react';
import { MdKeyboardArrowDown } from "react-icons/md";


const NumberDropdown = ({ 
    title,
    options, 
    onSelect, 
    defaultOption, 
    type, 
    placeholder, 
    required = false,  
    onChange,
    value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) onSelect(option);
  };

  return (
    <div className="dropdown-numbers">
      <label className="dropdown-title w-100">
        {title}
        {required && <span className='required-astrix text-danger'>*</span>}
      </label>
      <div className="dropdown-container-custom w-100">
        <div className='d-flex'>
            <div className='d-flex' onClick={handleToggle}>
                <div className="dropdown-selected-custom">{selectedOption || defaultOption}</div>
                <div className="dropdown-arrow">
                    <MdKeyboardArrowDown />
                </div>
            </div>
            <div>
                <input
                    type={type}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    className="input-field-input"
                />
            </div>
        </div>
        {isOpen && (
          <div className="dropdown-menu-custom">
            {options.map((option, index) => (
              <div
                key={index}
                className="dropdown-option-custom"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};


export default NumberDropdown;
