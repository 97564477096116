import axios from "axios";
import { getStorage } from "../helpers/apihelper";
import { baseURL } from "../config/filepath";
let axiosInstanceClient = axios.create({
  baseURL,
});
axiosInstanceClient.interceptors.request.use(
  function (config) {
    const token = getStorage("__ct");
    if (token !== null) {
      config.headers["Authorization"] = `Bearer ${atob(token)}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
export default axiosInstanceClient;