import React from 'react'

const ButtonStatus = ({img, content, className, status}) => {
  return (
    <div className={`${className} ${status === 'Successful' ? 'btn-status btn-green' : 'btn-status btn-red'}`}>
        <img src={img} alt='' className='pr-2' />
        <p className='pb-0 mb-0'>{content}</p>
    </div>
  )
}

export default ButtonStatus