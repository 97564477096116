import {React , useEffect, useState} from "react";
import AdminLayout from "../../layouts/AdminLayout";
import ListHeader from "../../components/Headers/ListHeader";
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Badge,
} from "reactstrap";
import useGetService from "../../services/getservice";
import Loader from "../../components/Loader/Loader";

function PermissionList() {
    const roleList ='';
    const [permissionList ,  setPermissionList] = useState([])
    let { response, isFetchItems } = useGetService(`/api/admin/get-permissions`);

    useEffect(() => {
        if (response?.status === "200-OK") {
          let permissionData = response?.data?.data;
          setPermissionList(permissionData)
        }
        console.log(permissionList)
    }, [response]);
    return (
        <AdminLayout>
           {isFetchItems && <Loader></Loader>}
           {/* <Loader></Loader> */}
            <ListHeader></ListHeader>
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3 className="mb-0">Permission List</h3>
                                    </div>
                                </div>
                            </CardHeader>
                            <Table className="align-items-center table-flush table-hover" responsive style={{ marginBottom: "25px" }} >
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Sl No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Permission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {permissionList.length > 0 &&
                                        permissionList?.map((item, index) => {
                                            return (
                                                <tr key={item?.id}>
                                                    <td>{index+1}</td>
                                                    <td>{item?.name}</td>
                                                    {/* <td>{item?.role}</td>
                                                    <td>{item?.description}</td>
                                                     */}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            {/* <CardFooter className="py-4">
                                {totalPage > 1 ? (
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem
                                                // className="disabled"
                                                className={page < 2 ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page - 2);
                                                    }}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {[...Array(totalPage)].map((_, i) => {
                                                return (
                                                    <PaginationItem
                                                        key={i}
                                                        // className="active"
                                                        className={page === i + 1 ? "active" : ""}
                                                    >
                                                        <PaginationLink
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onPageChange(i + 1);
                                                            }}
                                                        >
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                            })}
                                            <PaginationItem
                                                // className="disabled"
                                                className={page + 2 > totalPage ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page + 2);
                                                    }}
                                                >
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                ) : null}
                            </CardFooter> */}
                        </Card>
                    </div>
                </Row>
            </Container>
        </AdminLayout>
    );
}

export default PermissionList;
