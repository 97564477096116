import React, {useState} from 'react'
import { Col, Container, Row } from 'reactstrap'
import InputField from '../../components/Buttons/InputField'
import ButtonComponent from '../../components/Buttons/ButtonSubmit'
import { Link } from "react-router-dom";
import PasswordStrength from '../../components/Buttons/PasswordStrength';


const CreateNewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <Container fluid className='normal-user px-0 mx-0'>
      <Row className='row px-0 mx-0'>
        <Col md={12} xl={7} className='px-0 mx-0 left-column d-flex justify-content-center'>
          <div className='m-0 left-inner w-100'>
            <Row className='row px-0 mx-0'>
              <Col className='col-md-12 mx-0'>
                <h2 className=''>Create New Password
                    <span className='d-block mt-2'>You can set your new password easily.</span></h2>
              </Col>

              <Col md={12} className='form-inputs mt-5'>
                <InputField
                  type="password"
                  label="Set New Password"
                  placeholder="Enter New Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputField
                  type="password"
                  label="Confirm New Password"
                  placeholder="Enter Confirm Password"
                  imageSrc=""
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className='mt-4'
                />
              </Col>

              <Col md={12} className='mt-4'>
                <PasswordStrength password={password} />
              </Col>

              <Col md={12} className='mt-5'>
                <ButtonComponent>Create Password</ButtonComponent>
              </Col>

              <Col md={12} className='mt-4 signup-link'>
                  <p>Return to <Link to='/html/login' className='text-decoration-none'>Login</Link> </p>
              </Col>

            </Row>  
          </div>
        </Col>
    
        <Col md={12} xl={5} className='px-0 mx-0 right-column'>
          <img src='../assets/forgot-login-password-banner.webp' />
        </Col>
      </Row>
    </Container>
  )
}

export default CreateNewPassword