
import React from 'react'
import { getStorage } from "../helpers/apihelper";
import { Navigate} from 'react-router-dom'
const PublicRoute = ({ component: RouteComponent }) => {
  const token = getStorage("token");
  if (token === null) {
    return <RouteComponent />
  }
  else {
    return <Navigate to="admin/dashboard" />

  }

}

export default PublicRoute
