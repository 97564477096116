/* eslint-disable default-case */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
  Label,
  FormText,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router';
import AdminLayout from '../../layouts/AdminLayout';
import ListHeader from '../../components/Headers/ListHeader';
import useConditiongetService from '../../services/conditiongetService';
// import {DatePicker} from 'reactstrap-date-picker'
import { DatePicker, Space, TimePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import usePostService from '../../services/postservice';
import { getdaysbetween, getminutesbetween } from '../../helpers/utils';
import ConfirmationModal from './confirmationModal';

const format = 'H:mm';

const depositionEditManagment = () => {
  const navigate = useNavigate();
  const { depositionId } = useParams();
  const [getapiState, setApiState] = useState(1);
  const [postapiState, setPostapiState] = useState({
    apiState: 0,
    isCall: false,
  });
  const [isDisable, setisDisable] = useState(false);
  const [addRow, setAddRow] = useState([]);
  const [type, setType] = useState({
    type: 'virtual',
    startDate: '',
    startTime: '',
    preStartTime: '',
    endTime: '',
    preEndTime: '',
    case_id: ''
  });
  const [typeError, setTypeError] = useState({
    startDateError: '',
    startTimeError: '',
    endTimeError: '',
  });
  const [attendeesError, setAttendeesError] = useState([]);
  const [depositionState, setDepositionState] = useState(true);
  const [createDeposition, setCreateDeposition] = useState({});
  const [data2, setData2] = useState({
    allUsersList: [],
    allRoleList: [],
    allAttorneyList: [],
  });

  let [statusData, setStatusData] = useState({
    actionStatus: '',
    id: '',
    buttonState: false,
  });

  const [isOpen, setisOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [deletedData, setDeletedData] = useState([]);

  let getApi = {
    1: `/api/depo/getDepoDetails/${depositionId}`,
    2: `api/case/getAllCaseViewList?depo_id=${depositionId}`,
  };
  let postApi = {
    // 1: { url: `api/depo/singleEditApi`, body: createDeposition },
    1: { url: `api/frontend/case/singleEditApiForDepo`, body: createDeposition },
    2: { url: `api/frontend/case/deleteInvitee`, body: { invitee_id: deletedData.map(item => item.id) } },
  };
  let { response2, isFetchItems } = useConditiongetService(
    getApi[getapiState],
    getapiState
  );
  let response = usePostService(
    postApi[postapiState.apiState]?.body,
    postApi[postapiState.apiState]?.url,
    postapiState.isCall
  );

  let updateResponse = usePostService(
    { accept: statusData.actionStatus, id: statusData.id },
    `/api/frontend/case/inviteeRescheduleHandleAttorney`,
    statusData.buttonState
  );

  // Get apis handler
  useEffect(() => {
    if (response2) {
      switch (getapiState) {
        case 1:
          let inviteList = response2?.data?.invitee_list.map((item) => {
            return {
              first_name: item?.first_name,
              last_name: item?.last_name,
              email: item?.email,
              role_name: item?.role_name,
              role_id: item?.role_id,
              user_id: item?.user_id,
              status: parseInt(item?.status),
              id: item?.id,
              action: item?.action ? parseInt(item?.action) : 3,
              start_time: item?.requested_start_time,
              end_time: item?.requested_end_time,
              isExistFlag: item.user_id != null && item.user_id != 0 ? 1 : 0,
              isDisableRow: 1,
            };
          });
          setType({
            ...type,
            type: response2?.data?.type,
            case_id: response2?.data?.case_id,
            startDate: getDate(response2?.data?.start),
            startTime: getDate(response2?.data?.start),
            preStartTime: getDate(response2?.data?.start),
            endTime: getDate(response2?.data?.end),
            preEndTime: getDate(response2?.data?.end),
          });
          setAddRow(inviteList);
          setAttendeesError(
            Array(inviteList.length).fill({
              email: '',
              firstName: '',
              lastName: '',
              roleName: '',
            })
          );
          setApiState((pre) => pre + 1);
          break;
        case 2:
          setData2(response2?.data?.data);
          break;
      }
    }
  }, [response2]);

  // Post apis handlers
  useEffect(() => {
    (async () => {
      await setPostapiState((e) => ({ ...e, isCall: false }));
      if (response?.status == '200-OK') {
        if (postapiState.apiState == 1) {
          toast.success(response?.data?.message);
          navigate('/deposition-management');
        }
        if (postapiState.apiState == 2) {
          await setPostapiState({ apiState: 1, isCall: true });
        }
      }
      if (response?.status == 300) {
        toast.error(response?.data?.message);
      }
      if (response?.status == 400) {
        toast.error(response?.data?.message);
      }
    })()
  }, [response]);

  // Put apis handlers
  useEffect(() => {
    console.log('updateResponse===>>', updateResponse);
    if (updateResponse?.status == '200-OK') {
      setStatusData((preData) => ({
        ...preData,
        buttonState: false,
        actionStatus: '',
        id: '',
      }));
      toast.success(updateResponse?.data?.message);
    }
    if (updateResponse?.status == 300) {
      toast.error(updateResponse?.data?.message);
    }
  }, [updateResponse]);

  function getDate(a = []) {
    if (!a) return new Date();
    return new Date(
      ...a
        ?.split('')
        .slice(9, a.length - 1)
        .join('')
        .split(',')
        .map((e) => Number(e))
    );
  }

  function validateEmail(email) {
    if (email !== '') {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    } else {
      return false;
    }
  }

  // Disable previous date
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < dayjs().subtract(1, 'day').endOf('day');
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setisOpen(true);
  };

  const onDeleteHandler = () => {
    let newarray = addRow?.filter((_, id) => {
      return id !== deleteIndex;
    });
    let deletedArray = addRow?.filter((_, id) => {
      return id == deleteIndex;
    });
    if (deletedArray[0].isExistFlag !== 0) {
      setDeletedData([...deletedData, ...deletedArray]);
    };
    setAddRow(newarray);
    disableHandler(newarray);
    setisOpen(false);
  }

  const handleDataDelete = (index) => {
    let newarray = addRow.filter((item, id) => {
      return id != index;
    });
    setAddRow(newarray);
  };

  let handleAdd = () => {
    let createdRow = [
      ...addRow,
      {
        email: '',
        first_name: '',
        last_name: '',
        role_name: '',
        role_id: 0,
        user_id: 0,
        isExistFlag: 0,
        isDisableRow: 0,
        status: 2,
        id: '',
        action: 3,
        start_time: '',
        end_time: '',
      },
    ];

    setAddRow(createdRow);
    setAttendeesError((preError) => [
      ...preError,
      {
        email: '',
        firstName: '',
        lastName: '',
        roleName: '',
      },
    ]);
    disableHandler(createdRow);
  };

  const handleInput = (event, index) => {
    let email = event.target.value;
    let selectedUser = data2.allUsersList.find((obj) => obj.email == email);
    if (selectedUser) {
      let { role } = data2.allRoleList.find(
        (e) => e.id == selectedUser.role_id
      );
      selectedUser.role_name = role;
      let modifiedData = addRow.map((e, i) => {
        let result = e;
        if (index == i) {
          result = {
            ...e,
            email: event.target.value ? event.target.value : null,
            first_name: selectedUser.first_name
              ? selectedUser.first_name
              : null,
            last_name: selectedUser.last_name ? selectedUser.last_name : null,
            role_name: role ? role : null,
            user_id: selectedUser.id ? selectedUser.id : 0,
            role_id: selectedUser.role_id ? selectedUser.role_id : null,
            isExistFlag: 1,
            isDisableRow: 1,
          };
        }
        return result;
      });
      setAddRow(modifiedData);
      disableHandler(modifiedData);
    } else {
      let modifiedData = addRow.map((e, i) => {
        let result = e;
        if (index == i) {
          if (event.target.name == 'role_name') {
            const idx = event.target.selectedIndex;
            const option = event.target.querySelectorAll('option')[idx];
            const role_id = option.getAttribute('data-rid');
            result = {
              ...e,
              [event.target.name]: event.target.value,
              role_id: role_id,
            };
          } else {
            result = {
              ...e,
              [event.target.name]: event.target.value,
            };
          }
        }
        return result;
      });

      setAddRow(modifiedData);
      disableHandler(modifiedData);
    }
  };

  const handleStartClose = (starttime, endtime) => {
    let error = {
      startTimeError: '',
    }
    if (
      starttime !== null &&
      endtime !== null &&
      // startcalendarvalue === endcalendarvalue &&
      getminutesbetween(moment(starttime.$d).format(format), moment(endtime).format(format)) < 0
    ) {
      // setstarttime(null);
      error.startTimeError = "Start time can't greater than end time";
    }
    if (
      getdaysbetween(type.startDate, moment().format()) === 0 &&
      starttime !== null &&
      getminutesbetween(moment().format('HH:MM'), moment(starttime.$d).format(format)) < 0
    ) {
      error.startTimeError = "start time can't be less than current time";
    }
    setType({ ...type, startTime: starttime.$d });
    setTypeError({ ...typeError, ...error });
  };

  const handleEndClose = (starttime, endtime) => {
    let error = {
      endTimeError: '',
    }
    if (
      starttime !== null &&
      endtime !== null &&
      // startcalendarvalue === endcalendarvalue &&
      getminutesbetween(moment(starttime).format(format), moment(endtime.$d).format(format)) < 0
    ) {
      error.endTimeError = "End time can't be greater than start time";
    }
    setType({ ...type, endTime: endtime.$d });
    setTypeError({ ...typeError, ...error });
  };

  function handleDeposition(e) {
    e.preventDefault();
    nextHandler();
    if (addRow.length === 0) {
      toast.error('Select at least one attendees');
      return 0;
    }
    let courtReporterCount = addRow.filter(item => item.role_id == 5);
    let phyExpCount = addRow.some(item => item.role_id == 8 || item.role_id == 4);
    if (!phyExpCount) {
      toast.error('You have to select atleast one physician or expert');
      return 0;
    }
    if (courtReporterCount.length !== 1) {
      toast.error('You have to select one court reporter');
      return 0;
    }

    if (moment(type.endTime).diff(moment(type.startTime), 'minute') < 0) {
      return 0;
    }

    let prestart = moment(moment(type.preStartTime).format('YYYY-MM-DDTHH:mm'))
    let preend = moment(moment(type.preEndTime).format('YYYY-MM-DDTHH:mm'));
    let poststart = moment(moment(type.startTime).format('YYYY-MM-DDTHH:mm'));
    let postend = moment(moment(type.endTime).format('YYYY-MM-DDTHH:mm'))

    if (postend.diff(poststart, 'minute') !== preend.diff(prestart, 'minute')) {
      toast.error('If you want to change the duration, you need to cancel this deposition and make a new one');
      return 0;
    }

    let startdate = moment(type.startDate ?? type.startDate);

    const Obj = {
      case_id: type.case_id,
      deposition_id: depositionId,
      type: type.type,
      start_time: `new Date(${startdate.year()},${startdate.month()},${startdate.format('DD')},${moment(type.startTime ?? type.startTime).format('HH,mm')}) `,
      end_time: `new Date(${startdate.year()},${startdate.month()},${startdate.format('DD')},${moment(type.endTime ?? type.endTime).format('HH,mm')})`,
      invite_users: addRow,
    };

    setCreateDeposition(Obj);
    if (deletedData.length > 0) {
      setPostapiState({ apiState: 2, isCall: true });
    } else {
      setPostapiState({ apiState: 1, isCall: true });
    }
  }

  const disableHandler = (modifyData) => {
    if (modifyData.length > 0) {
      let isDisable = modifyData.map((e) => {
        if (e.email === '') {
          return false;
        }
        if (e.first_name === '') {
          return false;
        }
        if (e.last_name === '') {
          return false;
        }
        if (e.role_name === '') {
          return false;
        }
        return true;
      });

      setisDisable(isDisable.includes(false));
    } else {
      setisDisable(true);
    }
  };

  const nextHandler = () => {
    if (addRow.length === 0) toast.error('Select at least one attendees');
    let errorList = addRow.map((e, index) => {
      let errorObj = {};
      if (!validateEmail(e.email)) {
        errorObj.email = 'Enter valid email';
      } else {
        errorObj.email = '';
      }

      if (e.first_name === '') {
        errorObj.firstName = "First name can't empty";
      } else errorObj.firstName = '';

      if (e.last_name === '') {
        errorObj.lastName = "Last name can't empty";
      } else errorObj.lastName = '';

      if (e.role_name === '') {
        errorObj.roleName = 'Select role';
      } else errorObj.roleName = '';
      return errorObj;
    });

    let isError = errorList.map((item) => {
      for (const [key, value] of Object.entries(item)) {
        return value === '';
      }
    });

    if (isError.includes(false)) {
      setAttendeesError(errorList);
    } else {
      setAttendeesError(errorList);
      window.scroll(0, 0);
      // setDepositionState(false);
    }
  };

  const handleAction = (evt, index, id) => {
    let statusValue = parseInt(evt.target.value);
    if (statusValue === 0 || statusValue === 1) {
      setStatusData((preData) => ({
        ...preData,
        buttonState: true,
        actionStatus: statusValue === 1 ? true : false,
        id: id,
      }));
      let modifedData = addRow.map((item, itemIndex) => {
        if (itemIndex === index) {
          return { ...item, status: statusValue, action: statusValue };
        } else {
          return item;
        }
      });
      setAddRow(modifedData);
    }
  };

  return (
    <AdminLayout name="Deposition Management">
      <>
        {/* {loading && <Loader></Loader>} */}
        <ListHeader />
        <ToastContainer />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <div className="row">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="alhttps://meet.google.com/kuf-tfxf-mtuign-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Update Deposition</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    {/* {depositionState ? ( */}
                    {/* // First deposition Page */}
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          Deposition information
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <h3>
                                {' '}
                                Type:
                                {type.type == 'virtual' ? " Virtual" : ' Inperson'}
                              </h3>
                            </Col>
                            {/* <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Type
                                  </label>
                                  <Input
                                    type="select"
                                    name="is_active"
                                    id="selectMulti"
                                    onChange={(e) =>
                                      setType({ ...type, type: e.target.value })
                                    }
                                  >
                                    <option value="Virtual">Virtual</option>
                                    <option value="Inperson">Inperson</option>
                                  </Input>
                                </FormGroup>
                              </Col> */}
                            {/* <Col lg="4">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Address
                                    </label>
                                    <textarea
                                      type="select"
                                      name="is_active"
                                      id="selectMulti"
                                      value={textareaData}
                                      onChange={(e) => setTextareaData({ ...type, type: e.target.value })}
                                    >
                                    </textarea>
                                  </FormGroup>
                                </Col> */}
                          </Row>
                          {/* Date section */}
                          {/* <Row>
                              <Col lg="4">
                                <h3>
                                  {' '}
                                  Start :
                                  {moment(type.startDate).format(
                                    'MMMM Do YYYY,h:mm:ss a'
                                  )}
                                </h3>
                              </Col>
                              <Col lg="4">
                                <h3>
                                  {' '}
                                  End :
                                  {moment(type.endDate).format(
                                    'MMMM Do YYYY,h:mm:ss a'
                                  )}
                                </h3>
                              </Col>
                            </Row> */}

                          <Row>
                            <Col lg="2">
                              <FormGroup>
                                <Space direction="vertical" size={12}>
                                  <Label>Start date</Label>
                                  <DatePicker
                                    allowClear={false}
                                    disabledDate={disabledDate}
                                    value={dayjs(type.startDate)}
                                    format={'MM/DD/YYYY'}
                                    onChange={(e, v) =>
                                      setType({ ...type, startDate: v })
                                    }
                                    onOk={(e) =>
                                      setType({ ...type, startDate: e.$d })
                                    }
                                  />
                                </Space>
                                {typeError.startDateError !== '' && typeError.startDateError}
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Space direction="vertical" size={12}>
                                  <Label>Start time</Label>
                                  <TimePicker
                                    allowClear={false}
                                    value={dayjs(type.startTime)}
                                    format={format}
                                    onChange={(e, v) =>
                                      setType({ ...type, startTime: v })
                                    }
                                    onOk={(e) => {
                                      handleStartClose(e, type.endTime)
                                    }
                                    }
                                  />
                                </Space>
                                {typeError.startTimeError !== '' && <span style={{ color: 'red' }}>{typeError.startTimeError}</span>}
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <Space direction="vertical" size={12}>
                                  <Label>End time</Label>
                                  <TimePicker
                                    allowClear={false}
                                    value={dayjs(type.endTime)}
                                    format={format}
                                    onChange={(e, v) =>
                                      setType({ ...type, endTime: v })
                                    }
                                    onOk={(e) =>
                                      handleEndClose(type.startTime, e)
                                    }
                                  />
                                </Space>
                                {typeError.endTimeError !== '' && <span style={{ color: 'red' }}>{typeError.endTimeError}</span>}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <h6 className="heading-small text-muted mb-4">
                              Attendees
                            </h6>
                          </Row>
                          {/* First Deposition list */}

                          {/* Second Deposition list */}
                          {addRow.map((value, index) => {
                            return (
                              <Row key={index}>
                                <Col lg="3">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Email Id
                                    </label>
                                    <Input
                                      // type="select"
                                      name="email"
                                      id="selectMulti"
                                      placeholder="Email"
                                      disabled={
                                        value?.isDisableRow ? true : false
                                      }
                                      list="browsers"
                                      value={value.email}
                                      onChange={(e) => handleInput(e, index)}
                                    ></Input>
                                    <label
                                      style={
                                        attendeesError[index].email === ''
                                          ? { display: 'none' }
                                          : { color: 'red' }
                                      }
                                    >
                                      Email Not valid
                                    </label>
                                    <datalist id="browsers">
                                      {data2.allUsersList.length > 1 &&
                                        data2?.allUsersList.map(
                                          ({ email }, index) => (
                                            <option key={index} value={email}>
                                              {email}
                                            </option>
                                          )
                                        )}
                                    </datalist>
                                  </FormGroup>
                                </Col>
                                <Col lg="2">
                                  <FormGroup>
                                    <label className="form-control-label">
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      name="first_name"
                                      className="form-control"
                                      placeholder="First Name"
                                      value={value.first_name}
                                      disabled={
                                        value?.isDisableRow ? true : false
                                      }
                                      onChange={(e) => handleInput(e, index)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="2">
                                  <FormGroup>
                                    <label className="form-control-label">
                                      Last Name
                                    </label>
                                    <input
                                      type="text"
                                      name="last_name"
                                      className="form-control"
                                      placeholder="Last Name"
                                      disabled={
                                        value?.isDisableRow ? true : false
                                      }
                                      value={value.last_name}
                                      onChange={(e) => handleInput(e, index)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="2">
                                  <FormGroup>
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <input
                                      type="text"
                                      name="status"
                                      className="form-control"
                                      placeholder="status"
                                      disabled={true}
                                      value={
                                        value?.status === 0
                                          ? 'Rejected'
                                          : value?.status === 1
                                            ? 'Accepted'
                                            : value?.status === 2
                                              ? 'Pending'
                                              : value?.status === 3
                                                ? 'Reschedule request'
                                                : 'Waiting for payment'
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                {value?.status === 3 && (
                                  <Col lg="2">
                                    <FormGroup>
                                      <label className="form-control-label">
                                        Proposed Time
                                      </label>
                                      <input
                                        type="text"
                                        name="proposed_time"
                                        className="form-control"
                                        placeholder="Proposed Time"
                                        disabled={true}
                                        value={
                                          value?.start_time &&
                                          moment(value?.start_time).format(
                                            'YYYY-MM-DD hh:mm'
                                          ) +
                                          '-' +
                                          moment(value?.end_time).format(
                                            'YYYY-MM-DD hh:mm'
                                          )
                                        }
                                        title={
                                          value?.start_time &&
                                          moment(value?.start_time).format(
                                            'YYYY-MM-DD hh:mm'
                                          ) +
                                          '-' +
                                          moment(value?.end_time).format(
                                            'YYYY-MM-DD hh:mm'
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                )}
                                {[0, 1, 2].includes(value.action) && (
                                  <Col lg="2">
                                    <FormGroup>
                                      <label className="form-control-label">
                                        Actioned
                                      </label>
                                      <Input
                                        type="select"
                                        name="action"
                                        id="selectMulti"
                                        disabled={[1, 0].includes(
                                          value.status
                                        )}
                                        value={value.status}
                                        onChange={(e) =>
                                          handleAction(e, index, value.id)
                                        }
                                      >
                                        <option value={2}>Select</option>
                                        <option value={1}>Accept</option>
                                        <option value={0}>Reject</option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                )}
                                <Col lg="2">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Role
                                    </label>
                                    <Input
                                      type="select"
                                      name="role_name"
                                      id="selectMulti"
                                      disabled={
                                        value?.isDisableRow ? true : false
                                      }
                                      value={value.role_name}
                                      onChange={(e) => handleInput(e, index)}
                                    >
                                      {data2.allRoleList.length > 1 &&
                                        data2?.allRoleList.map(
                                          ({ role, id }) => (
                                            <option
                                              data-rid={id}
                                              value={role}
                                            >
                                              {role}
                                            </option>
                                          )
                                        )}
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col lg="1">
                                  <div
                                    className="btn btn-lg btn-icon"
                                    onClick={() => handleDelete(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      className="bi bi-trash"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                      />
                                    </svg>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}

                          <Row>
                            <Col lg="12" className="">
                              <Button
                                className="add-more-icon"
                                onClick={() => handleAdd()}
                              // disabled={active !== item.categoryId}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="32"
                                  fill="currentColor"
                                  className="bi bi-plus"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                </svg>
                                <span>Add Attendees</span>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />

                        <div className="pl-lg-4">
                          <FormGroup className="d-flex justify-content-end">
                            <Button
                              color="info"
                              className="no-transform"
                              disabled={isDisable}
                              onClick={(e) => handleDeposition(e)}
                            // onClick={() => nextHandler()}
                            >
                              {/* Next */}
                              Submit
                            </Button>

                            <Button
                              color="warning"
                              className="no-transform"
                              onClick={() => navigate("/deposition-management")}
                            >
                              Cancel
                            </Button>
                          </FormGroup>
                        </div>
                      </Form>
                    </CardBody>
                    {/* ) : ( */}
                    {/* // Second deposition Page */}

                    {/* )} */}
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
        </Container>

        {
          <ConfirmationModal
            title={'Are you sure you want to delete attendee ?'}
            isOpen={isOpen}
            toggle={() => setisOpen(false)}
            onClick={() => onDeleteHandler()} />
        }

      </>
    </AdminLayout>
  );
};

export default depositionEditManagment;
