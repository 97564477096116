import React, { useEffect, useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

function MultiSelect(props) {
    return (<CreatableSelect
        defaultValue={props.selectedValue}
        value={props.selectedValue}
        isMulti
        name="colors"
        components={makeAnimated}
        options={props.value}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(e, context) => props.onChangeHandler(e, context, props.type)}
    />)
};

export default MultiSelect;