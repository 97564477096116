import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { LuLayoutDashboard, LuBadgeDollarSign,  LuWallet2, LuHelpingHand, LuMapPin, LuHeartHandshake } from "react-icons/lu";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TbAddressBook } from "react-icons/tb";
// import { components } from 'react-select';
import DashboardHome from './DashboardHome';
import ReachargeAccount from './Recharge/RechargeAccount';


const diList = [
    {icon: <LuLayoutDashboard />, text: 'Dashboard', dropdown: false, component: <DashboardHome />},
    {icon: <LuBadgeDollarSign />, text: 'Recharge Account', dropdown: false, component: <ReachargeAccount />},
    {icon: <TbAddressBook />, text: 'Profile Management', dropdown: false},
    {icon: <LuWallet2 />, text: 'Transaction', dropdown: true},
    {icon: <LuHelpingHand />, text: 'My Funds', dropdown: true},
    {icon: <LuMapPin />, text: 'Map View', dropdown: false},
    {icon: <LuHeartHandshake />, text: 'Invite User', dropdown: false},
]

const Sidebar = ({ onComponentChange, onTitleChange }) => {
    const [activeLink, setActiveLink] = useState(0);
    const handleItemClick = (component, index, title) => {
        onComponentChange(component);
        setActiveLink(index);
        onTitleChange(title);
    };

  return (
    <>
      <div className='logo d-flex justify-content-center'>
          <Link to='/html/dashboard' className='p-0 m-0'>
              <img src='../assets/logo-dashboard.webp' />
          </Link>
      </div>
      <div className='dashboard-items pt-4'>
          <ul className='m-0 p-0'>
          {
              diList.map((item, ind) => {
                  return(
                      <li 
                        key={ind} 
                        className={`${activeLink === ind ? 'active' : ''} d-flex m-0 p-0 d-flex justify-content-between align-items-center px-3`}
                        // className='d-flex m-0 p-0 d-flex justify-content-between align-items-center px-3'
                        onClick={() => handleItemClick(item.component, ind, item.text)}
                      >
                          <div className='d-flex align-items-center'>
                              {item.icon}
                              <p className='m-0 p-0 pl-3'>{item.text}</p>
                          </div>
                          {item.dropdown ? <MdKeyboardArrowDown className='drop-icon' /> : ''}
                      </li>
                  )
              })
          }
          </ul>
      </div>
    </>
  )
}

export default Sidebar