import React, {useState} from 'react'
import { Col, Container, Row } from 'reactstrap'
import InputField from '../../components/Buttons/InputField'
import ButtonComponent from '../../components/Buttons/ButtonSubmit'
import { Link } from "react-router-dom";


const ForgotLoginPassword = () => {
  const [email, setEmail] = useState('');

  return (
    <Container fluid className='normal-user px-0 mx-0'>
      <Row className='row px-0 mx-0'>
        <Col md={12} xl={7} className='px-0 mx-0 left-column d-flex justify-content-center'>
          <div className='m-0 left-inner w-100'>
            <Row className='row px-0 mx-0'>
              <Col className='col-md-12 mx-0'>
                <h2 className=''>Forgot password
                    <span className='d-block mt-2'>Seems you forgot your password, we’ll send a recovery link to your email.</span></h2>
              </Col>

              <Col md={12} className='form-inputs mt-5'>
                <InputField
                  type="email"
                  label="Email Address"
                  placeholder="Enter Email Address"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>

              <Col md={12} className='mt-5'>
                <ButtonComponent>Send Recovery Email<i class="ml-3 fa fa-regular fa-angle-right"></i></ButtonComponent>
              </Col>

              <Col md={12} className='mt-4 signup-link'>
                  <p>Return to <Link to='/html/login' className='text-decoration-none'>Login</Link> </p>
              </Col>

            </Row>  
          </div>
        </Col>
    
        <Col md={12} xl={5} className='px-0 mx-0 right-column'>
          <img src='../assets/forgot-login-password-banner.webp' />
        </Col>
      </Row>
    </Container>
  )
}

export default ForgotLoginPassword