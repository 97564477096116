import moment from "moment";

export const allRoles = [];

export const getUserRole = (roleId) => {
  return allRoles.find((rId) => rId.id === roleId);
};

export const getFormattedDate = (dt) => {
  if (dt && dt !== undefined && dt !== "") {
    let date = new Date(dt);
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return day + "/" + month + "/" + year;
  } else {
    return "--";
  }
};

export const hoursbetween = (hours1, hours2) => {
  const timestamp1 = new Date(hours1).getTime();
  const timestamp2 = new Date(hours2).getTime();

  const durationMs = Math.abs(timestamp2 - timestamp1);
  const durationHours = durationMs / (1000 * 60 * 60);

  return durationHours
};

export const removeTags = (str) => {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();
  return str.replace(/(<([^>]+)>)/ig, '');
}

export function validate_email(email) {
  const pattern = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
  if (pattern.test(email)) {
    return true
  } else {
    return false
  }
}
export const DateFormat = (dateData) => {
  let startdate = new Date(dateData);
  startdate.setUTCHours(startdate.getUTCHours() - 5);
  startdate.setUTCMinutes(startdate.getUTCMinutes() - 30);
  return startdate
};
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getdaysbetween = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000;
  let start = moment(date1).format("YYYY/MM/DD");
  let end = moment(date2).format("YYYY/MM/DD");
  const firstDate = new Date(start?.split('/')[0], start?.split('/')[1] - 1, start?.split('/')[2]);
  const secondDate = new Date(end?.split('/')[0], end?.split('/')[1] - 1, end?.split('/')[2]);
  const diffDays = (firstDate - secondDate) / oneDay;
  return diffDays
}
export const getminutesbetween = (hour1, hour2) => {
  const old_hour = hour1?.toString().split(':')[0];
  const old_minute = hour1?.toString().split(':')[1];
  const new_hour = hour2?.toString().split(':')[0];
  const new_minute = hour2?.toString().split(':')[1];
  const min_diff = ((new_hour * 60) + Number(new_minute)) - ((old_hour * 60) + Number(old_minute))
  return min_diff
}