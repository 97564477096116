import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import AdminFooter from "../components/Footers/AdminFooter";
import { Container } from 'reactstrap';

const AdminLayout = (props) => {
  return (
    <>
      <Sidebar
      // checkPermission={checkPermission}
      // {...props}
      // routes={routes}
      // logo={{
      //   innerLink: "/admin/dashboard",
      //   imgSrc: require("../assets/img/brand/argon-react.png").default,
      //   imgAlt: "...",
      // }}
      />
      <div className="main-content" >
        <AdminNavbar name={props.name} />
        {props.children}
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  )
}

export default AdminLayout