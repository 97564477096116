import React, { useEffect, useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import ListHeader from "../../components/Headers/ListHeader";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
    Table,
} from "reactstrap";
import useGetService from "../../services/getservice";
import { useNavigate, useParams } from "react-router-dom";

function CaseView() {
    let { caseId } = useParams();
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({});
    const { response: getUserDetails } = useGetService(`/api/case/getCaseDetails/${caseId}`);
    useEffect(() => {
        if (getUserDetails?.status === "200-OK") {
            let userDetails = getUserDetails?.data?.data;
            // let defendantLawyer = [];
            // let plaintiffLawyer = [];

            // userDetails.case_users.map(item => {
            //     if (item?.type === 'defendant_attorney')
            //         defendantLawyer.push(item?.name);
            //     else
            //         plaintiffLawyer.push(item?.name)
            // })


            let userDetail = {
                // meet_option: parseInt(userDetails?.meet_option),
                requestBy: userDetails?.requested_by_name,
                defendantLawyer: userDetails?.defendant_lawyer?.map((item) => item?.label),
                plaintiffLawyer: userDetails?.plaintiff_lawyer?.map((item) => item?.label),
                defendant_client: userDetails?.defendant?.map(item => ({ name: item?.name, email: item?.email })),
                plaintiff_client: userDetails?.plaintiff?.map(item => ({ name: item?.name, email: item?.email })),
                // witness: userDetails?.case_witnesses?.map(item => ({ name: item?.name, email: item?.email })),
                casename: userDetails?.case_number,
                court: userDetails?.court_number,
                claim: userDetails?.claim_number,
                file: userDetails?.file_number,
                details: userDetails?.details,
                county: userDetails?.county?.label,
                state: userDetails?.state?.label,
                city: userDetails?.city?.label,
                // zip: userDetails?.zip,
                uploadFiles: userDetails?.files,
                case_name: userDetails?.case_name,
                // inviteUsers: userDetails?.case_invitees
            }
            console.log("userDetail==>>", userDetail);
            setUserDetails(userDetail);
        }
    }, [getUserDetails]);

    return (
        <>
            <AdminLayout>
                {/* {loading && <Loader></Loader>} */}
                <ListHeader />
                {/* <ToastContainer /> */}
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col" >
                            <div className="row">
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">
                                                        Case Details
                                                        {/* <AdminLayout>
                            Hello world
                        </AdminLayout> */}
                                                        {/* {currentURL.includes('create')
                            ? "Create New Admin"
                            : "Update Admin"} */}
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Form>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Case information
                                                </h6>
                                                <div className="pl-lg-4">
                                                    {/* <div className="modal-body"> */}

                                                    <div className="scroll-table-area">
                                                        <Table className="table-views mb-4">

                                                            <tr>
                                                                <td className="table-views--body" style={{ width: '20%' }}>
                                                                    <h4 className="table-views--title">Request By</h4>
                                                                    {userDetails?.requestBy || ''}
                                                                </td>
                                                                <td style={{ width: '20%' }}>
                                                                    <h4 className="table-views--title">Case Name</h4>
                                                                    {userDetails?.case_name || ''}
                                                                </td>
                                                                {/* <td className="table-views--body" style={{ width: '20%' }}>
                                                                    <h4 className="table-views--title">Meet</h4>
                                                                    {(userDetails?.meet_option === 1) ? 'Virtual Depo Meet' : 'In Person Depo Meet'}
                                                                </td> */}

                                                                <td className="text-right">
                                                                    <button onClick={() => navigate(`/case-management/edit/${caseId}`)} className="btn btn-lg btn-icon-edit">
                                                                        <svg
                                                                            version="1.0"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 100.000000 100.000000"
                                                                            preserveAspectRatio="xMidYMid meet"
                                                                        >
                                                                            <g
                                                                                transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                                                                                fill="#000000"
                                                                                stroke="none"
                                                                            >
                                                                                <path
                                                                                    d="M807 920 c-14 -11 -29 -20 -34 -20 -4 0 -153 -145 -329 -322 l-321
                                                                             -323 -33 -88 c-22 -60 -29 -92 -22 -99 7 -7 39 0 99 22 l88 33 323 321 c177
                                                                             176 322 325 322 329 0 5 9 20 20 34 27 35 26 78 -5 108 -30 31 -73 32 -108 5z
                                                                             m81 -32 c17 -17 15 -43 -4 -58 -13 -11 -19 -9 -41 12 -16 16 -23 32 -20 42 7
                                                                             19 48 21 65 4z m-75 -74 c20 -19 37 -38 37 -41 0 -4 -10 -17 -22 -30 l-22 -23
                                                                               -43 42 -43 42 22 23 c12 13 24 23 27 23 3 0 23 -16 44 -36z m-78 -84 l39 -40
                                                                              -254 -255 c-140 -140 -260 -255 -267 -255 -6 0 -13 12 -15 27 -2 22 -9 29 -31
                                                                             31 -15 2 -27 9 -27 15 0 11 499 517 510 517 3 0 24 -18 45 -40z m-539 -534 c1
                                                                          0 5 -11 8 -23 6 -19 2 -25 -19 -33 -54 -21 -66 -9 -45 45 8 21 14 25 33 19 12 -3 23 -7 23 -8z"/>
                                                                            </g>
                                                                        </svg>
                                                                    </button>
                                                                </td>

                                                            </tr>

                                                            <tr>

                                                                <td className="table-views--body" style={{ width: '20%' }}>
                                                                    <h4 className="table-views--title">Defendant Lawyer/ Law Firm’s Name</h4>
                                                                    {userDetails?.defendantLawyer?.join(',')}
                                                                </td>

                                                                <td className="table-views--body" style={{ width: '20%' }}>
                                                                    <h4 className="table-views--title">Plaintiff Lawyer/ Law Firm’s Name</h4>
                                                                    {userDetails?.plaintiffLawyer?.join(',')}
                                                                </td>

                                                            </tr>

                                                            <tr>
                                                                <td className="table-views--body pb-0">
                                                                    <h4 className="table-views--title mb-0">Defendant</h4>
                                                                </td>
                                                            </tr>
                                                            {
                                                                userDetails?.defendant_client?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="table-views--body">
                                                                                {item.name}
                                                                            </td>
                                                                            <td className="table-views--body">
                                                                                {item.email}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                            <tr>
                                                                <td className="table-views--body pb-0">
                                                                    <h4 className="table-views--title mb-0">Plaintiff</h4>
                                                                </td>
                                                            </tr>
                                                            {
                                                                userDetails?.plaintiff_client?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="table-views--body">
                                                                                {item.name}
                                                                            </td>
                                                                            <td className="table-views--body">
                                                                                {item.email}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                            {/* <tr>
                                                                <td className="table-views--body pb-0">
                                                                    <h4 className="table-views--title mb-0">Witness</h4>
                                                                </td>
                                                            </tr> */}
                                                            {/* {
                                                                userDetails?.witness?.map(item => {
                                                                    return (
                                                                        <tr>
                                                                            <td className="table-views--body">
                                                                                {item.name}
                                                                            </td>
                                                                            <td className="table-views--body">
                                                                                {item.email}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            } */}

                                                            <tr>
                                                                <td className="table-views--body">
                                                                    <h4 className="table-views--title">Case No</h4>
                                                                    {userDetails?.casename}
                                                                </td>
                                                                <td className="table-views--body">
                                                                    <h4 className="table-views--title">Claim No</h4>
                                                                    {userDetails?.claim}
                                                                </td>
                                                                <td className="table-views--body">
                                                                    <h4 className="table-views--title">Court No</h4>
                                                                    {userDetails?.court}
                                                                </td>
                                                                <td className="table-views--body">
                                                                    <h4 className="table-views--title">File No</h4>
                                                                    {userDetails?.file}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                {/* <td className="table-views--body">
                                                                    <h4 className="table-views--title">Country</h4>
                                                                    {userDetails?.country}
                                                                </td> */}
                                                                <td className="table-views--body">
                                                                    <h4 className="table-views--title">State</h4>
                                                                    {userDetails?.state}
                                                                </td>
                                                                <td className="table-views--body">
                                                                    <h4 className="table-views--title">City</h4>
                                                                    {userDetails?.city}
                                                                </td>
                                                                <td className="table-views--body">
                                                                    <h4 className="table-views--title">County</h4>
                                                                    {userDetails?.county}
                                                                </td>
                                                            </tr>

                                                        </Table>
                                                    </div>


                                                    <div className="uploadfiles">
                                                        <h4>Upload Files</h4>
                                                        <div className="d-flex flex-column justify-content-around">
                                                            {
                                                                userDetails?.uploadFiles && userDetails.uploadFiles.map(imageItem => {
                                                                    return (
                                                                        <div key={imageItem?.id} className="uploadfiles--box d-inline-flex align-items-center mt-1 w-50">
                                                                            {/* <div className="uploadfiles--icon">Files</div> */}
                                                                            <a href={`http://dev81.developer24x7.com:4020/public/caseUploadFile/${imageItem?.file_name}`} download target="_blank" className="text-decoration-none">
                                                                                <span>{imageItem?.file_name}</span>
                                                                            </a>
                                                                            {/* <div>
                                                                            <a href="#">
                                                                                View Attachment
                                                                            </a>
                                                                            <a href="#">
                                                                                View Description
                                                                            </a>
                                                                        </div> */}
                                                                            {/* <a href="#" className="btn-icon-close">X</a> */}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>


                                                    <h5>Description</h5>
                                                    <p>{userDetails?.details}</p>



                                                    {/* <h3>Attendies</h3>

                                                    <div className="scroll-table-area">
                                                        <Table className="table-lists">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <h5>Name</h5>
                                                                    </th>
                                                                    <th>
                                                                        <h5>Role</h5>
                                                                    </th>
                                                                    <th colSpan="2">
                                                                        <h5>Attendies Email</h5>
                                                                    </th>
                                                                    <th colSpan="2">
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    userDetails?.inviteUsers?.map(item => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    {item.name}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.role_name || ''}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.email}
                                                                                </td>
                                                                                <td>
                                                                                    <button className="btn btn-info">Check Availability</button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div> */}



                                                    {/* </div> */}
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
            </AdminLayout>
        </>
    )
}

export default CaseView;