import React, { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

const DatePicker = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleDateChange = (e) => {
        const newDate = new Date(e.target.value);
        setSelectedDate(newDate);
        setIsOpen(false); // Close the dropdown after selecting a date
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long' };
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <div className='date-dropdown d-flex justify-content-end'>
            <div className='date-btn'>
                <div className='date-output d-flex w-100 align-items-center' onClick={handleToggleDropdown}>
                    <p className='mb-0 mr-2'>{formatDate(selectedDate)}</p>
                    <MdKeyboardArrowDown />
                </div>
                {isOpen && (
                    <input
                        type='date'
                        value={selectedDate.toISOString().substr(0, 10)}
                        onChange={handleDateChange}
                        style={{ position: 'absolute', zIndex: 10 }}
                    />
                )}
            </div>
        </div>
    );
};

export default DatePicker;
