import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
    { name: 'Jan', uv: 40, },
    { name: 'Feb', uv: 60, },
    { name: 'Mar', uv: 20, },
    { name: 'Apr', uv: 80, },
    { name: 'May', uv: 60, },
    { name: 'Jun', uv: 80, },
    { name: 'Jul', uv: 60,  },
    { name: 'Aug', uv: 20,  },
    { name: 'Sept', uv: 80,  },
    { name: 'Oct', uv: 60,  },
    { name: 'Nov', uv: 80,  },
    { name: 'Dec', uv: 60,  },
];

const Custombar = (props) => {
  const { x, y, width, height, fill, className} = props;
  const borderRadius = 4;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        rx={borderRadius}
        ry={borderRadius}
        className={className}
      />
    </g>
  );
};

const CustomYAxisTitle = () => (
  <text
    x={323}
    y={220}
    textAnchor="middle"
    fill="#393939"
    fontSize={13}
    dy={0}
  >
    Months
  </text>
);


const LineGraph = () => {
    return (
        <ResponsiveContainer width="100%" height={196} className='bar-chart'>
            <BarChart 
                data={data} 
                barGap={100}
            >
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" vertical={false} />
                <XAxis
                    dataKey="name"
                    label={<CustomYAxisTitle />}
                    // label={{ value: 'Month', position: 'insideBottomCenter', offset: 0, className: 'xAxisLabel'}}
                />
                <YAxis 
                    domain={[0, 'dataMax']}
                    ticks={[0, 20, 40, 60, 80, 100]}
                    tickFormatter={(tick) => (tick >= 100 ? '100+' : tick)}
                    tickCount={6}
                    scale="linear"
                    label={{ value: 'Amount($)', position: 'outsideLeft', offset: 0, className: 'yAxisLabel'}}
                />
                <Tooltip />
                <Bar 
                  dataKey="uv" 
                  fill="#42826C" 
                  barSize={60} 
                  shape={<Custombar width='20px' /> } 
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default LineGraph;
