import "./App.css";
import { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// ROUTES
import AdminRoute from "./routes/AdminRoutes";
import PublicRoute from "./routes/publicRoutes";

// Normal User UI Components
import Login from "./html/Login/Login";
import ForgotLoginPassword from "./html/Login/ForgotLoginPassword";
import CreateNewPassword from "./html/Login/CreateNewPassword";
import Signup from "./html/Signup/Signup";
import Dashboard from "./html/Dashboard/Dashboard";
import ClientRoute from "./routes/ClientRoutes";
import { getStorage } from "./helpers/apihelper";


// ADMIN PUBLIC
const Signin  = lazy(() => import('./features/admin/authentication/Signin'))
const ForgotPassword = lazy(() => import('./features/admin/authentication/ForgotPassword'))
const ResetPassword = lazy(() => import('./features/admin/authentication/ResetPassword'))
// ADMIN PRIVATE
const DashBoard = lazy(() => import('./components/dashboard/dashBoard'))
const Profile = lazy(() => import('./features/admin/authentication/profile'))
const ClientCreate = lazy(() => import('./features/admin/client-management/Client-create-update'))
const ClientList = lazy(() => import('./features/admin/client-management/Client-List'))
const PermissionList = lazy(() => import('./features/admin/permission-management/permission-list'))
const RoleList = lazy(() => import('./features/admin/role_management/RoleList'))
const AdminList = lazy(() => import('./features/admin/admin-management/Admin-List'))
const AdminCreate = lazy(() => import('./features/admin/admin-management/Admin-create-update'))
const Commission = lazy(() => import('./features/admin/commission-management/default-commissionManagement'))
const AgencyCommission = lazy(() => import('./features/admin/commission-management/agency-commissionManagement'))
const RechargeList = lazy(() => import('./features/admin/recharge-management/Recharge-List'))
const GeoLocation = lazy(()=> import('./features/admin/geolocation-management/MapView'))



const ClientLogin  = lazy(() => import('./features/client/authentication/Login/Login'))
const ClientSignUp  = lazy(() => import('./features/client/authentication/Signup/Signup'))
const ClientForgetPassword = lazy(() => import('./features/client/authentication/Password/ForgotLoginPassword'))
const ClientRestPassword = lazy(() => import('./features/client/authentication/Password/CreateNewPassword'))

const ClientDashboard = lazy(() => import('./features/client/Dashboard/Dashboard'))
const ClientReacharge = lazy(() => import('./features/client/Recharge/RechargeAccount'))
const ClientProfile = lazy(() => import('./features/client/Profile/Profile'))
const ClientGeoLocation = lazy(() => import('./features/client/GeoLocation'))
const ClientInvite = lazy(() => import('./features/client/Invite/InviteUser'))
const ClientTrasaction = lazy(() => import('./features/client/Transaction/Transaction'))
export default function App() {
  
  const isLoggedInAdmin = getStorage('token') !== undefined ? true : false;
  const isLoggedInClient = getStorage('__ct') !== undefined ? true : false;


  return (
    <>
      <BrowserRouter>
        <Routes>
        <Route element={<PublicRoute />}>
           {/* ADMIN PUBLIC ROUTES */}
          <Route path="/" element={<Navigate to="/admin/signin" replace />} />
          <Route path="/admin/signin" key="signin" element={<Signin/>} />
          <Route path="/admin/forgotpassword" key={"forgetpassword"} element={<ForgotPassword/>} />
          <Route path="/admin/resetpassword/:token" key="restpassword" element={<ResetPassword/>} />
          {/* CLIENT PUBLIC ROUTES */}
          <Route path="/client/signin" key="clientsignin" element={<ClientLogin/>} />
          <Route path="/client/signup" key="clientsignin1" element={<ClientSignUp/>} />
          <Route path="/client/forget-password" key="client-forget-password" element={<ClientForgetPassword/>} />
          <Route path="/client/reset-password/:token" key="client-reset-password" element={<ClientRestPassword/>} />
        </Route>

          
        <Route element={<AdminRoute isLoggedInAdmin={isLoggedInAdmin} />}>
          <Route path="admin">
            <Route index path="dashboard" key="dashboard" element={<DashBoard/>} />
            <Route path="client">
              <Route index path="create" key="client-create" element={<ClientCreate/>}/>
              <Route index path="list" key="client-list" element={<ClientList/>} />
              <Route index path="edit/:userId" key="client-id" element={<ClientCreate/>} />
            </Route>
            <Route path="admins">
              <Route index path="create" key="client-create" element={<AdminCreate/>}/>
              <Route index path="list" key="client-list" element={<AdminList/>} />
              {/* <Route index path="edit/:userId" key="client-id" element={<ClientCreate/>} /> */}
            </Route>
            <Route index path="permission-management" key="permission-list" element={<PermissionList/>} />
            <Route index path="role-management" key="role-list" element={<RoleList/>} />
            <Route path="profile" element={<Profile/>} />
            <Route path="commission">
              <Route path="default-management" element={<Commission/>}></Route>
              <Route path="agency-management" element={<AgencyCommission/>}></Route>
            </Route>

            
            <Route path="recharge-management" element={<RechargeList/>}></Route>

            <Route path="geolocation-management" element={<GeoLocation/>}></Route>
          </Route>
        </Route>



        <Route element={<ClientRoute isLoggedInClient={isLoggedInClient}/>}>
          <Route path="client">
            <Route index path="dashboard" key="client-Dashbaord" element={<ClientDashboard/>} />
            <Route index path="recharge" key="client-Recharge" element={<ClientReacharge/>} />
            <Route index path="profile" key="client-Recharge" element={<ClientProfile/>} />
            <Route index path="transaction" key="client-Transaction" element={<ClientTrasaction/>} />
            <Route index path="geolocation" key="client-Geolocation" element={<ClientGeoLocation/>} />
            <Route index path="invite" key="client-Invite" element={<ClientInvite/>} />

          </Route>
        </Route>

        <Route path="*" element={<h1 className="text-center mt-5">404 Not Found</h1>} />




          {/* <Route path="/example/:id" element={<PublicRoute component={ExampleProductComparison} />} />
          <Route path="/profile" element={<PrivateRoute component={Profile} />} />
          <Route path="/categorystep" element={<PublicRoute component={CreateUpdateCategory} />} />
          <Route path="/category">
            <Route index path="" element={<PublicRoute component={CategoryList} />} />
            <Route path=":parentcategoryid">
              <Route index path="" element={<PublicRoute component={ChildCategoryList} />} />
              <Route path=":childcategoryid">
                <Route index path="" element={<PublicRoute component={SubChildCategoryList} />} />
                <Route path=":subchildcategoryid" element={<PublicRoute component={ProductComparisons} />} />
              </Route>
              <Route path=":childcategoryid" element={
                <PrivateRoute
                component={ProductComparisons}
              />}  />
            </Route>
          </Route> */}
          {/* <Route path="/permission-management" element={<PrivateRoute component={PermissionList} />} />
         
          <Route path="/deposition-management">
            <Route
              index
              path=""
              element={<PrivateRoute component={DepositionManagmentList} />}
            />
            <Route
              index
              path=":depositionId"
              element={<PrivateRoute component={depositionEditManagment} />}
            />
            <Route
              path="set-time"
              element={<PrivateRoute component={depositionSetTimeManagment} />}
            />
          </Route>

          <Route
            path="/create-role-management"
            element={<PrivateRoute component={CreateUpdateRole} />}
          />
          <Route
            path="/role-management"
            element={<PrivateRoute component={RoleList} />}
          />

          <Route path="/waiting-approval">
            <Route
              index
              path=""
              element={<PrivateRoute component={WaitingApprovalList} />}
            />
            <Route
              index
              path="edit/:approvalId"
              element={<PrivateRoute component={CreateUpdateWaitingApproval} />}
            />
          </Route>
          <Route
            index
            path="/lawfirm-management"
            element={<PrivateRoute component={LawFirmManagementList} />}
          />
          <Route
            index
            path="/subscriptions-management"
            element={<PrivateRoute component={SubscriptionManagementList} />}
          />
          <Route
            index
            path="/singledepocalls-management"
            element={<PrivateRoute component={SingleDepoCalls} />}
          />

          <Route
            index
            path="/invitation-management"
            element={<PrivateRoute component={InvitationManagementList} />}
          />
          <Route
            index
            path="/create-case-management"
            element={<PrivateRoute component={CreateUpdateCase} />}
          />
          <Route path="/case-management">
            <Route
              index
              path=""
              element={<PrivateRoute component={CaseList} />}
            />
            <Route
              index
              path="view/:caseId"
              element={<PrivateRoute component={CaseView} />}
            />
            <Route
              index
              path="edit/:caseId"
              element={<PrivateRoute component={CreateUpdateCase} />}
            />
          </Route>
          <Route
            index
            path="/physicians-management"
            element={<PrivateRoute component={PhysiciansList} />}
          />
          <Route
            index
            path="/expert-management"
            element={<PrivateRoute component={ExpertsList} />}
          />
          
          <Route
            index
            path="/courtreporter-management"
            element={<PrivateRoute component={CourtReporterManagementList} />}
          />

          <Route path="/manage-setting">
            <Route index path="" element={<PrivateRoute component={SettingList} />}
            />
            <Route path=":settingId" element={<PrivateRoute component={CreateUpdateSetting} />}
            />
          </Route> */}

          {/* <Route index path="/courtrepresentatives-management" element={<PrivateRoute component={CourtRepresentativesManagementList} />} /> */}

          {/* <Route
            index
            path="/create-subscriptions-management"
            element={<PrivateRoute component={CreateUpdateSubscription} />}
          />
          <Route
            index
            path="/subscriptions-management"
            element={<PrivateRoute component={SubcriptionList} />}
          /> */}
          {/* <Route index path="/commission-management" element={<PrivateRoute component={CommissionManagementList} />} /> */}
          {/* <Route
            index
            path="/create-content-management"
            element={<PrivateRoute component={CreateUpdateContant} />}
          /> */}
          {/* <Route path="/content-management">
            <Route index path="" element={<PrivateRoute component={ContantList} />} />
            <Route path="edit/:contantId" element={<PrivateRoute component={CreateUpdateContant} />} />
          </Route>
          <Route path="/banner-management">
            <Route index path="" element={<PrivateRoute component={Bannerlist} />} />
            <Route path="edit/:bannerId" element={<PrivateRoute component={Updatebanner} />} />
          </Route>
          <Route index path="/analytics-reports" element={<PrivateRoute component={AnalyticsReportsList} />} />
          <Route index path="/contacts" element={<PrivateRoute component={ContactList} />} />
          <Route index path="/contact-details" element={<PrivateRoute component={AddContactDetails} />} />
          <Route path="/services">
            <Route index path="" element={<PrivateRoute component={ServicesList} />} />
            <Route index path=":serviceId" element={<PrivateRoute component={CreateService} />} />
          </Route>
          <Route index path="/createservice" element={<PrivateRoute component={CreateService} />} />
          <Route path="/member-details">
            <Route index path="" element={<PrivateRoute component={MemberList} />} />
            <Route index path=":memberId" element={<PrivateRoute component={CreateMember} />} />
          </Route>
          <Route index path="/createmember" element={<PrivateRoute component={CreateMember} />} />
          <Route index path="/contact-details/:id" element={<PrivateRoute component={AboutUs} />} />
          <Route index path="/header-description/:id" element={<PrivateRoute component={HeaderDescription} />} />
          <Route index path="/right-description/:id" element={<PrivateRoute component={RightDetails} />} />
          <Route index path="/terms-conditions" element={<PrivateRoute component={TermsConditions} />} />

          <Route path="/payment-management">
            <Route
              index
              path=""
              element={<PrivateRoute component={PaymentManagement} />}
            />
            <Route index path="payment-details/:paymentId" element={<PrivateRoute component={ChangePaymentStatus} />} />
          </Route> */}

          {/* These are HTML routes */}
          <Route index path="/html/login/" element={<Login />} />
          <Route index path="/html/forgot-password" element={<ForgotLoginPassword />} />
          <Route index path="/html/create-new-password" element={<CreateNewPassword />} />
          <Route index path="/html/signup" element={<Signup />} />
          <Route index path="/html/dashboard" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}


