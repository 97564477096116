import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import AuthNavbar from "../components/Navbars/AuthNavbar";
import AuthFooter from "../components/Footers/AuthFooter";

const AuthLayout = (props) => {
  return (
    <>
       <div className="main-content" >
    <AuthNavbar />
    <div className="header bg-gradient-info py-7 py-lg-8">
      <Container>
        <div className="header-body text-center mb-7">
          <Row className="justify-content-center">
            <Col lg="5" md="6">
              <h1 className="text-white">
               {props.data}
              </h1>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="separator separator-bottom separator-skew zindex-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="fill-yellow_cus"
            fill="#fe7650"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </div>
    {/* Page content */}
    {props.children}
  </div>
  <AuthFooter />
    </>
  );
};

export default AuthLayout;
