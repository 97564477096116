import React, { useEffect  ,useState} from 'react'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import { Container, Row, Col } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

const Userbar = (userData) => {

    const navigate = useNavigate()
    const handleClick = (type) => {
        if(type === "recharge") {
            navigate('/client/recharge')
        }
        /// handle click
    }
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const type = localStorage.getItem('__clientType');
        setUserType(type);
    }, []);

  return (
    <Container className='user-bar p-3'>
        <Row className=''>
            <Col md={12} className=''>
                <p className='title mb-0'>Hello <span>{userData?.userData?.firstName} !!!</span> </p>
            </Col>
        </Row>
        <Row className='d-flex justiry-content-between'>
            <Col md={7} className='d-flex mt-2 justify-content-between'>
                <div className='item'>
                    <p className='title mb-0'>Phone</p>
                    <p className='text mb-0'>{userData?.userData?.phone}</p>
                </div>
                <div className='item'>
                    <p className='title mb-0'>Email</p>
                    <p className='text mb-0 email'>{userData?.userData?.email}</p>
                </div>
                <div className='item'>
                    <p className='title mb-0'>Account type</p>
                    <p className='text mb-0'>{userData?.userData?.role?.roleTitle}</p>
                </div>
                {
                    userType === 'option2'  && (
                        <div className='item'>
                            <p className='title mb-0'>My Availability</p>
                            <p className='text mb-0'>10am to 6pm</p>
                        </div>
                    )
                }
            </Col>
            <Col md={5} className='buttons d-flex justify-content-end'>
                <ButtonComponent
                    onClick={() => handleClick('recharge')}
                    disabled={false}
                    className = 'orange-border thick-border'
                    btnType = 'border'
                >
                    Recharge
                    <i className="ml-3 fa fa-regular fa-angle-right"></i>
                </ButtonComponent>

                <ButtonComponent
                    onClick={() => handleClick('money')}
                    disabled={false}
                    className = 'orange-border thick-border'
                    btnType = 'no-border'
                >
                    Send Money
                    <i className="ml-3 fa fa-regular fa-angle-right ml-2"></i>
                </ButtonComponent>
            </Col>
        </Row>
    </Container>
  )
}

export default Userbar