import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Collapse, NavbarBrand, Navbar, Nav, Container } from "reactstrap";
import logo from "../../assets/images/logo.jpg";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userManagement, setUserManagement] = useState(false);
  const [subcriptionManagement, setSubcriptionManagement] = useState(false);
  const [caseManagement, setcaseManagement] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState();

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // useEffect(() => {
  //   if (
  //     location.pathname === "/case-management" ||
  //     location.pathname === "/deposition-management"
  //   ) {
  //     setcaseManagement(true);
  //   }
  // }, [location]);

  // const frontUser = (path) => {
  //   let modifyPath = path.split("/")[1];
  //   console.log("modifyPath==>>", modifyPath);
  //   let frontPath = [
  //     "/invitation-management",
  //     "/waiting-approval",
  //     "/lawfirm-management",
  //     "/physicians-management",
  //     "/courtreporter-management",
  //     "/expert-management",
  //   ];
  //   return frontPath.includes("/" + modifyPath);
  // };

  // const frontSubscription = (path) => {
  //   let frontPath = [
  //     "/subscriptions-management",
  //     "/singledepocalls-management",
  //   ];
  //   return frontPath.includes(path);
  // };

  // const frontCase = (path) => {
  //   let frontPath = ["/case-management", "/deposition-management"];
  //   return frontPath.includes(path);
  // };



  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button   className="navbar-toggler"  type="button"  onClick={toggleCollapse}  >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        <NavbarBrand className="p-0">
          <img alt="logo" className="navbar-brand-img" style={{minHeight:70}} height={80}  width={80} src={logo} />
        </NavbarBrand>

        {/* Collapse */}
        <Collapse navbar={true} isOpen={collapseOpen}>
          <hr className="my-3" />

          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/dashboard") && ("bg-green-color")}`} role="button" onClick={() => navigate("/admin/dashboard")} >
            <i className="fas fa-tachometer-alt"></i>
             Dashboard 
          </Nav>

          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/client/list") && ("bg-green-color")}`} role="button"  onClick={() => navigate("/admin/client/list")}>
            <i className="fas fa-users"></i>
            Client Management
          </Nav>

          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/admins/list") && ("bg-green-color")}`} role="button"  onClick={() => navigate("/admin/admins/list")}>
            <i className="fas fa-users"></i>
            Admin Management
          </Nav>

          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/permission-management") && ("bg-green-color") }`} role="button" onClick={() => navigate("/admin/permission-management")} >
            <i className="fas fa-cog"></i>
            Permission Management
          </Nav>
          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/role-management") && ("bg-green-color") }`} role="button" onClick={() => navigate("/admin/role-management")} >
            <i className="fas fa-user"></i>
            Role Management
          </Nav>
          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/commission/default-management") && ("bg-green-color") }`} role="button" onClick={() => navigate("/admin/commission/default-management")} >
            <i className="fas fa-user"></i>
            Commission Management
          </Nav>
          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/commission/agency-management") && ("bg-green-color") }`} role="button" onClick={() => navigate("/admin/commission/agency-management")} >
            <i className="fas fa-user"></i>
            Agency Management
          </Nav>
          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/recharge-management") && ("bg-green-color") }`} role="button" onClick={() => navigate("/admin/recharge-management")} >
            <i className="fas fa-user"></i>
            Recharge Management
          </Nav>
          <Nav navbar={true} className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes("/admin/geolocation-management") && ("bg-green-color") }`} role="button" onClick={() => navigate("/admin/geolocation-management")} >
            <i className="fas fa-map"></i>
            Geolocation Management
          </Nav>
          {/* <Nav
            navbar
            className={`d-flex fontsize-navbar ${frontUser(location.pathname) ? "bg-green-color" : ""
              }`}
            role="button"
            onClick={() => setUserManagement(!userManagement)}
          >
            <i className="fas fa-briefcase"></i>
            Front User Management{" "}
            {userManagement ? (
              <BsChevronCompactUp
                width={"1em"}
                height={"1.5em"}
                style={{ marginTop: "3px", paddingLeft: "2px" }}
              />
            ) : (
              <BsChevronCompactDown
                width={"1em"}
                height={"1.5em"}
                style={{ marginTop: "3px", paddingLeft: "2px" }}
              />
            )}
          </Nav>
          {userManagement && (
            <div className="pl-2 pr-2">
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/invitation-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/invitation-management")}
              >
                <i className="fas fa-briefcase"></i>
                Invitations
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${"/" + location.pathname.split("/")[1] === "/waiting-approval"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/waiting-approval")}
              >
                <i className="fas fa-briefcase"></i>
                Waiting For Approval
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/lawfirm-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/lawfirm-management")}
              >
                <i className="fas fa-briefcase"></i>
                Attorneys
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/physicians-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/physicians-management")}
              >
                <i className="fas fa-briefcase"></i>
                Physicians
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/courtreporter-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/courtreporter-management")}
              >
                <i className="fas fa-briefcase"></i>
                Court Reporters
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/expert-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/expert-management")}
              >
                <i className="fas fa-briefcase"></i>
                Experts
              </Nav>
            </div>
          )}
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${frontSubscription(location.pathname) ? "bg-green-color" : ""
              }`}
            role="button"
            onClick={() => setSubcriptionManagement(!subcriptionManagement)}
          >
            <i className="fas fa-briefcase"></i>
            Subscription Management{" "}
            {subcriptionManagement ? (
              <BsChevronCompactUp
                width={"1em"}
                height={"1.5em"}
                style={{ marginTop: "3px", paddingLeft: "2px" }}
              />
            ) : (
              <BsChevronCompactDown
                width={"1em"}
                height={"1.5em"}
                style={{ marginTop: "3px", paddingLeft: "2px" }}
              />
            )}
          </Nav>
          {subcriptionManagement && (
            <div className="pl-2 pr-2">
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/subscriptions-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/subscriptions-management")}
              >
                <i className="fas fa-briefcase"></i>
                Membership
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/singledepocalls-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/singledepocalls-management")}
              >
                <i className="fas fa-briefcase"></i>
                Single Call
              </Nav>
            </div>
          )}
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${frontCase(location.pathname) ? "bg-green-color" : ""
              }`}
            role="button"
            onClick={() => setcaseManagement(!caseManagement)}
          >
            <i className="fas fa-briefcase"></i>
            Case Managment{" "}
            {caseManagement ? (
              <BsChevronCompactUp
                width={"1em"}
                height={"1.5em"}
                style={{ marginTop: "3px", paddingLeft: "2px" }}
              />
            ) : (
              <BsChevronCompactDown
                width={"1em"}
                height={"1.5em"}
                style={{ marginTop: "3px", paddingLeft: "2px" }}
              />
            )}
          </Nav>
          {caseManagement && (
            <div className="pl-2 pr-2">
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/case-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/case-management")}
              >
                <i className="fas fa-briefcase"></i>
                Cases
              </Nav>
              <Nav
                navbar
                className={`d-flex fontsize-navbar ${location.pathname === "/deposition-management"
                    ? "bg-green-color"
                    : null
                  }`}
                role="button"
                onClick={() => navigate("/deposition-management")}
              >
                <i className="fas fa-briefcase"></i>
                Depositions
              </Nav>
            </div>
          )} */}

          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/physicians-management")}
          >
            <i className="fas fa-briefcase"></i>
            Physicians Management
          </Nav> */}
          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/courtrepresentatives-management")}
          >
            <i className="fas fa-briefcase"></i>
            Court Representatives
          </Nav> */}
          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/manage-pricing")}
          >
            <i className="fas fa-briefcase"></i>
            Setting
          </Nav> */}

          {/* <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/commission-management")}
          >
            <i className="fas fa-briefcase"></i>
            Commission Management
          </Nav> */}
          {/* <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes("/content-management")
                ? "bg-green-color"
                : ""
              }`}
            role="button"
            onClick={() => navigate("/content-management")}
          >
            <i className="fas fa-briefcase"></i>
            Content Management
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes("/banner-management")
                ? "bg-green-color"
                : ""
              }`}
            role="button"
            onClick={() => navigate("/banner-management")}
          >
            <i className="fas fa-briefcase"></i>
            Banner
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes("/contacts") ? "bg-green-color" : ""
              }`}
            role="button"
            onClick={() => navigate("/contacts")}
          >
            <i className="fas fa-briefcase"></i>
            Request Contact List
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes("/services") ? "bg-green-color" : ""
              }`}
            role="button"
            onClick={() => navigate("/services")}
          >
            <i className="fas fa-briefcase"></i>
            Services
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes("/contact-details")
                ? "bg-green-color"
                : ""
              }`}
            role="button"
            onClick={() => navigate("/contact-details")}
          >
            <i className="fas fa-briefcase"></i>
            Company Contact Details
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes("/member-details")
                ? "bg-green-color"
                : ""
              }`}
            role="button"
            onClick={() => navigate("/member-details")}
          >
            <i className="fas fa-briefcase"></i>
            Website Support's Details
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes("/terms-conditions")
                ? "bg-green-color"
                : ""
              }`}
            role="button"
            onClick={() => navigate("/terms-conditions")}
          >
            <i className="fas fa-briefcase"></i>
            Terms & Conditions
          </Nav>
          <Nav
            navbar
            className={`d-flex fontsize-navbar ${location.pathname.includes("/payment-management")
                ? "bg-green-color"
                : ""
              }`}
            role="button"
            onClick={() => navigate("/payment-management")}
          >
            <i className="fas fa-briefcase"></i>
            Payment Management
          </Nav>
          <Nav
            navbar
            className="d-flex fontsize-navbar"
            role="button"
            onClick={() => navigate("/analytics-reports")}
          >
            <i className="fas fa-briefcase"></i>
            Analytics & Reports
          </Nav> */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
