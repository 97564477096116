import React from 'react';
import PropTypes from 'prop-types';

const ButtonComponent = ({
  onClick,
  disabled = false,
  children,
  className = '',
  btnType = 'no-border',
}) => {

  const buttonClass = `btn custom-btn-primary ${className} ${btnType == 'no-border' ? 'no-bordered': 'bordered'}`;
  
  return (
    <button
      onClick={onClick}
      className={buttonClass}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  btnType: PropTypes.string,
};

export default ButtonComponent;
