import React from 'react'
import { useSearchParams } from 'react-router-dom';
import CategoryStep1 from './CategoryStep/CategoryStep1';
import CategoryStep2 from './CategoryStep/CategoryStep2';
import CategoryStep3 from './CategoryStep/CategoryStep3';
import CategoryStep4 from './CategoryStep/CategoryStep4';
import CategoryStep5 from './CategoryStep/CategoryStep5';
import CategoryStep6 from './CategoryStep/CategoryStep6';
import CategoryStep7 from './CategoryStep/CategoryStep7';

const CreateUpdateCategory = () => {
  const [searchParams] = useSearchParams();
  return (
    searchParams?.get('step') ==="1"?<CategoryStep1/>:
    searchParams?.get('step') ==="2"?<CategoryStep2/>:
    searchParams?.get('step') ==="3"?<CategoryStep3/>:
    searchParams?.get('step') ==="4"?<CategoryStep4/>:
    searchParams?.get('step') ==="5"?<CategoryStep5/>:
    searchParams?.get('step') ==="6"?<CategoryStep6/>:
    searchParams?.get('step') ==="7"?<CategoryStep7/>:
    null
  )
}

export default CreateUpdateCategory