import React from 'react';
import ButtonStatus from './ButtonStatus';
import { camelCaseToWords, getFormattedDate } from '../../../helpers/utils';


const TransactionTable = (data) => {
  return (
    <div className="container mt-4 px-0">
      <table className="table table-bordered-bottom">
        <thead>
          <tr>
            <th>Transaction Id</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th className='text-center'>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-data'>
          {
            data?.data?.map( (Item) => {
              const {id, createdAt, amount, type, isDeducted,status, action} = Item;
              return(
                <tr key={id}>
                    <td className='pl-5'>{id}</td>
                    <td className='pr-3'>{getFormattedDate(createdAt)}</td>
                    <td className={isDeducted ? 'red-color pr-3' : 'green-color pr-3'}>{isDeducted ? '-' : '+'}${amount}</td>
                    <td className='pr-5'><span>{camelCaseToWords(type)}</span> <span>{status !== 'completed' ? <img src='../assets/triangle-alert.svg' alt="status-img" /> : ''}</span></td>
                    <td className='pr-3 text-center' align='center'>
                      {
                        status === 'completed' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Successful'
                            className=''
                            status='Successful'
                          />
                        ) : (
                          <ButtonStatus
                            img='../assets/circle-red.svg'
                            content='Rejected'
                            className=''
                            status='Rejected'
                          />
                        )
                      }
                    </td>
                    <td className='action'>
                      {
                        status === 'completed' ? ( 
                          <>
                          <button className="">
                            <img src='../assets/eye-orange.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-orange.svg' />
                          </button>
                          </>
                        ) : (
                          <>
                          <button className="">
                            <img src='../assets/eye-grey.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-grey.svg' />
                          </button>
                          </>
                        )
                      }
                    </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
