import React from 'react'
import { RiUserSearchLine } from "react-icons/ri";
import { LuFileCheck2 } from "react-icons/lu";
import { IoCheckmark } from "react-icons/io5";


const ProgressBar = ({currentStep, startText, endText, stepOne, stepTwo}) => {
    console.log(stepOne);
  return (
    <div className='progress-container d-flex align-items-center justify-content-center gap-3 g-3'>
        <div className={`step step1 one ${currentStep === 1 ? 'active' : ''} d-flex flex-column align-items-center position-relative`}>
            <div className='icon-progress d-flex align-items-center justify-content-center'>
                {stepOne ? <IoCheckmark /> : <RiUserSearchLine />}
            </div>
            <div className='text mt-3'>{startText}</div>
        </div>

        <div className={`line ${currentStep === 1 || currentStep >= 2 ? 'active' : ''}`}></div>

        <div className={`step step2 two ${currentStep >= 2 ? 'active' : ''} d-flex flex-column align-items-center position-relative`}>
            <div className='icon-progress d-flex align-items-center justify-content-center'>
                {stepTwo ? <IoCheckmark /> : <LuFileCheck2 />}
            </div>
            <div className='text mt-3'>{endText}</div>
        </div>

    </div>
  )
}

export default ProgressBar