import React, { createContext, useContext, useState, useEffect } from 'react';

const ClientTypeContext = createContext();

export const ClientTypeProvider = ({ children }) => {
    const [clientType, setClientType] = useState('');

    useEffect(() => {
        const storedClientType = localStorage.getItem('__clientType');
        console.log('Stored client type from localStorage:', storedClientType);
        if (storedClientType) {
            setClientType(storedClientType);
            console.log('Setting client type to:', storedClientType);
        }
    }, []);

    return (
        <ClientTypeContext.Provider value={{ clientType }}>
            {children}
        </ClientTypeContext.Provider>
    );
};

export const useClientType = () => {
    return useContext(ClientTypeContext);
};
