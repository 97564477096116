import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
    Modal,
    ModalFooter
} from "reactstrap";
import ErrorMessage from '../../components/Validation/ErrorMessage';

const AddExternalLawyer = ({ isOpen, handleClose, submitModal, context, event, type }) => {
    const [formdata, setFormdata] = useState({
        first_name: '',
        last_name: ''
    })
    const [error, setError] = useState({
        first_name: '',
        last_name: ''
    })

    const validate = () => {
        let isError = false;
        let error = {
            first_name: '',
            last_name: ''
        }
        let { first_name, last_name } = formdata;
        if (first_name === '') {
            error.first_name = "First name can't empty";
            isError = true;
        }
        if (!isError && last_name === '') {
            error.last_name = "Last name can't empty";
            isError = true;
        }
        setError(error);
        return isError;
    }

    const submitModalHandler = () => {
        let errorStatus = validate();
        if (!errorStatus) {
            let events = event.map((item, index) => {
                if (index === (event.length - 1)) {
                    return { ...item, value: Math.random().toString(36).slice(2), role: null, email: `${context?.option?.value}`, label: `${formdata.first_name} ${formdata.last_name}` }
                } else
                    return item;
            });
            submitModal(events, type);
        }
    }

    return (
        <Modal
            className="modal-dialog-centered w-50" isOpen={isOpen} toggle={handleClose}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    Add External Lawyer
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={handleClose}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <Row>
                    <Col lg="12">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-case"
                            >
                                Email
                            </label>
                            <div className="d-flex justify-content-between">
                                <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-case"
                                    placeholder="Case"
                                    type="text"
                                    name="casename"
                                    autoComplete="nope"
                                    disabled={true}
                                    value={context.option.value}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                            >
                                First Name
                            </label>
                            <div className="d-flex justify-content-between">
                                <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-first-name"
                                    placeholder="First Name"
                                    type="text"
                                    maxLength={10}
                                    name="first_name"
                                    autoComplete="nope"
                                    value={formdata?.first_name}
                                    onChange={(e) => setFormdata({ ...formdata, first_name: e.target.value })}
                                />
                            </div>
                            {error.first_name ? (
                                <ErrorMessage
                                    errorMsg={error.first_name}
                                />
                            ) : null}
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                            >
                                Last Name
                            </label>
                            <div className="d-flex justify-content-between">
                                <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-last-name"
                                    placeholder="Last Name"
                                    type="text"
                                    maxLength={10}
                                    name="last_name"
                                    autoComplete="nope"
                                    value={formdata?.last_name}
                                    onChange={(e) => setFormdata({ ...formdata, last_name: e.target.value })}
                                />
                            </div>
                            {error.last_name ? (
                                <ErrorMessage errorMsg={error.last_name} />
                            ) : null}
                        </FormGroup>
                    </Col>

                </Row>

            </div>

            <ModalFooter>
                <Button
                    color="info"
                    className="no-transform "
                    onClick={() => submitModalHandler()}
                >
                    Submit
                </Button>
                <Button
                    color="warning"
                    className="no-transform "
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AddExternalLawyer;