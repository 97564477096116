import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Signin from "./features/authentication/Signin";
import ResetPassword from "./features/authentication/ResetPassword";
import CategoryList from "./features/category/Categorylist";
import ChildCategoryList from "./features/category/ChildCategoryList";
import ProductComparisons from "./features/category/ProductComparisons";
import PrivateRoute from "./routes/privateRoutes";
import PublicRoute from "./routes/publicRoutes";
import SubChildCategoryList from "./features/category/SubChildCategoryList";
import CreateUpdateCategory from "./features/category/Createupdatecategory";
import ExampleProductComparison from "./features/category/ExampleProductComparison";
import LawFirmManagementList from "./features/lawfirm_management/lawFirmManagement";
import CommissionManagementList from "./features/commission_management/commissionManagement";
// import PhysiciansManagementList from "./features/physicians_management/physiciansManagement";
import CourtReporterManagementList from "./features/courtreporter_management/courtReporterManagement";

// Forgot password
import ForgotPassword from "./features/authentication/ForgotPassword";
// Profile
import Profile from "./features/authentication/profile";

// User Management
import CreateUserUpdate from "./features/user_management/CreateUserUpdate";
import UserList from "./features/user_management/UserList";

// Role Management
import RoleList from "./features/role_management/RoleList";
import CreateUpdateRole from "./features/role_management/CreateUpdateRole";

// Subscription Management
// import SubcriptionList from "./features/subscription_management/SubscriptionList";
// import CreateUpdateSubscription from './features/subscription_management/CreateUpdateSubscription';

// Contant Management
import ContantList from "./features/cms/ContantList";
import CreateUpdateContant from "./features/cms/CreateUpdateContant";

// Case Management
import CaseList from "./features/case_management/CaseList";
import CreateUpdateCase from "./features/case_management/CreateUpdateCase";

// Report
import AnalyticsReportsList from "./features/analytics_reports/analyticsReports";

// Setting Management
import SettingList from "./features/manage_setting/SettingList";
import CreateUpdateSetting from "./features/manage_setting/CreateUpdateSetting";

import PhysiciansList from "./features/physicians_management/physiciansList";
import CourtRepresentativesManagementList from "./features/courtrepresentatives-management/courtRepresentativesManagement";
import DashBoard from "./components/dashboard/dashBoard";
import CaseView from "./features/case_management/CaseView";
import SubscriptionManagementList from "./features/subscription_management/subscriptionManagement";
import SingleDepoCalls from "./features/singledepo_calls/singleDepoCalls";
import InvitationManagementList from "./features/invitation_management/invitationManagement";
import WaitingApprovalList from "./features/waitingForApproval/WaitingApprovalList";
import CreateUpdateWaitingApproval from "./features/waitingForApproval/CreateUpdateWaitingApproval";
import DepositionManagmentList from "./features/deposition_managment/depositionManagment";
import depositionEditManagment from "./features/deposition_managment/depositionEditManagment";
import depositionSetTimeManagment from "./features/deposition_managment/depositionSetTimeManagment";
import ContactList from "./features/contacts/ContactList";
import ExpertsList from "./features/experts_management/ExpertsList";
import ServicesList from "./features/services/ServicesList";
import CreateService from "./features/services/CreateService";
import AddContactDetails from "./features/contact_details/AddContactDetails";
import MemberList from "./features/member-details/MemberList";
import CreateMember from "./features/member-details/CreateMember";
import AboutUs from "./features/cms/AboutUs";
import HeaderDescription from "./features/cms/HeaderDescription";
import RightDetails from "./features/cms/RightDetails";
import Bannerlist from "./components/banner/bannerlist";
import Updatebanner from "./components/banner/updatebanner";
import TermsConditions from "./features/terms-conditions/TermsConditions";
import PaymentManagement from "./features/payment_management/PaymentManagement";
import ChangePaymentStatus from "./features/payment_management/ChangePaymentStatus";

// Normal User UI Components
import Login from "./html/Login/Login";
import ForgotLoginPassword from "./html/Login/ForgotLoginPassword";
import CreateNewPassword from "./html/Login/CreateNewPassword";
import Signup from "./html/Signup/Signup";
import Dashboard from "./html/Dashboard/Dashboard";
import PermissionList from "./features/permission-management/permission-list";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/admin/signin" replace />} />
          <Route path="/admin/signin" element={<PublicRoute component={Signin} />} />
          <Route path="/resetpassword/:token" element={<PublicRoute component={ResetPassword} />} />
          <Route path="/example/:id" element={<PublicRoute component={ExampleProductComparison} />} />
          <Route path="/forgotpassword" element={<PublicRoute component={ForgotPassword} />} />
          {/* <Route path="/dashboard" element={<PublicRoute component={UserList} />} /> */}
          <Route path="/profile" element={<PrivateRoute component={Profile} />} />
          <Route path="/categorystep" element={<PublicRoute component={CreateUpdateCategory} />} />
          <Route path="/category">
            <Route index path="" element={<PublicRoute component={CategoryList} />} />
            <Route path=":parentcategoryid">
              <Route index path="" element={<PublicRoute component={ChildCategoryList} />} />
              <Route path=":childcategoryid">
                <Route index path="" element={<PublicRoute component={SubChildCategoryList} />} />
                <Route path=":subchildcategoryid" element={<PublicRoute component={ProductComparisons} />} />
              </Route>
              {/* <Route path=":childcategoryid" element={
                <PrivateRoute
                component={ProductComparisons}
              />}  /> */}
            </Route>
          </Route>
          <Route path="/permission-management" element={<PrivateRoute component={PermissionList} />} />
          <Route
            path="/createuser"
            element={<PrivateRoute component={CreateUserUpdate} />}
          />
          <Route path="/user-management">
            <Route
              index
              path=""
              element={<PrivateRoute component={UserList} />}
            />
            <Route
              index
              path=":userId"
              element={<PrivateRoute component={CreateUserUpdate} />}
            />
          </Route>
          <Route path="/deposition-management">
            <Route
              index
              path=""
              element={<PrivateRoute component={DepositionManagmentList} />}
            />
            <Route
              index
              path=":depositionId"
              element={<PrivateRoute component={depositionEditManagment} />}
            />
            <Route
              path="set-time"
              element={<PrivateRoute component={depositionSetTimeManagment} />}
            />
          </Route>

          <Route
            path="/create-role-management"
            element={<PrivateRoute component={CreateUpdateRole} />}
          />
          <Route
            path="/role-management"
            element={<PrivateRoute component={RoleList} />}
          />

          <Route path="/waiting-approval">
            <Route
              index
              path=""
              element={<PrivateRoute component={WaitingApprovalList} />}
            />
            <Route
              index
              path="edit/:approvalId"
              element={<PrivateRoute component={CreateUpdateWaitingApproval} />}
            />
          </Route>
          <Route
            index
            path="/lawfirm-management"
            element={<PrivateRoute component={LawFirmManagementList} />}
          />
          <Route
            index
            path="/subscriptions-management"
            element={<PrivateRoute component={SubscriptionManagementList} />}
          />
          <Route
            index
            path="/singledepocalls-management"
            element={<PrivateRoute component={SingleDepoCalls} />}
          />

          <Route
            index
            path="/invitation-management"
            element={<PrivateRoute component={InvitationManagementList} />}
          />
          <Route
            index
            path="/create-case-management"
            element={<PrivateRoute component={CreateUpdateCase} />}
          />
          <Route path="/case-management">
            <Route
              index
              path=""
              element={<PrivateRoute component={CaseList} />}
            />
            <Route
              index
              path="view/:caseId"
              element={<PrivateRoute component={CaseView} />}
            />
            <Route
              index
              path="edit/:caseId"
              element={<PrivateRoute component={CreateUpdateCase} />}
            />
          </Route>
          <Route
            index
            path="/physicians-management"
            element={<PrivateRoute component={PhysiciansList} />}
          />
          <Route
            index
            path="/expert-management"
            element={<PrivateRoute component={ExpertsList} />}
          />
          <Route
            index
            path="admin/dashboard"
            element={<PrivateRoute component={DashBoard} />}
          />
          <Route
            index
            path="/courtreporter-management"
            element={<PrivateRoute component={CourtReporterManagementList} />}
          />

          <Route path="/manage-setting">
            <Route index path="" element={<PrivateRoute component={SettingList} />}
            />
            <Route path=":settingId" element={<PrivateRoute component={CreateUpdateSetting} />}
            />
          </Route>

          <Route index path="/courtrepresentatives-management" element={<PrivateRoute component={CourtRepresentativesManagementList} />} />

          {/* <Route
            index
            path="/create-subscriptions-management"
            element={<PrivateRoute component={CreateUpdateSubscription} />}
          />
          <Route
            index
            path="/subscriptions-management"
            element={<PrivateRoute component={SubcriptionList} />}
          /> */}
          <Route index path="/commission-management" element={<PrivateRoute component={CommissionManagementList} />} />
          {/* <Route
            index
            path="/create-content-management"
            element={<PrivateRoute component={CreateUpdateContant} />}
          /> */}
          <Route path="/content-management">
            <Route index path="" element={<PrivateRoute component={ContantList} />} />
            <Route path="edit/:contantId" element={<PrivateRoute component={CreateUpdateContant} />} />
          </Route>
          <Route path="/banner-management">
            <Route index path="" element={<PrivateRoute component={Bannerlist} />} />
            <Route path="edit/:bannerId" element={<PrivateRoute component={Updatebanner} />} />
          </Route>
          <Route index path="/analytics-reports" element={<PrivateRoute component={AnalyticsReportsList} />} />
          <Route index path="/contacts" element={<PrivateRoute component={ContactList} />} />
          <Route index path="/contact-details" element={<PrivateRoute component={AddContactDetails} />} />
          <Route path="/services">
            <Route index path="" element={<PrivateRoute component={ServicesList} />} />
            <Route index path=":serviceId" element={<PrivateRoute component={CreateService} />} />
          </Route>
          <Route index path="/createservice" element={<PrivateRoute component={CreateService} />} />
          <Route path="/member-details">
            <Route index path="" element={<PrivateRoute component={MemberList} />} />
            <Route index path=":memberId" element={<PrivateRoute component={CreateMember} />} />
          </Route>
          <Route index path="/createmember" element={<PrivateRoute component={CreateMember} />} />
          <Route index path="/contact-details/:id" element={<PrivateRoute component={AboutUs} />} />
          <Route index path="/header-description/:id" element={<PrivateRoute component={HeaderDescription} />} />
          <Route index path="/right-description/:id" element={<PrivateRoute component={RightDetails} />} />
          <Route index path="/terms-conditions" element={<PrivateRoute component={TermsConditions} />} />

          <Route path="/payment-management">
            <Route
              index
              path=""
              element={<PrivateRoute component={PaymentManagement} />}
            />
            <Route index path="payment-details/:paymentId" element={<PrivateRoute component={ChangePaymentStatus} />} />
          </Route>

          {/* These are HTML routes */}
          <Route index path="/html/login/" element={<Login />} />
          <Route index path="/html/forgot-password" element={<ForgotLoginPassword />} />
          <Route index path="/html/create-new-password" element={<CreateNewPassword />} />
          <Route index path="/html/signup" element={<Signup />} />
          <Route index path="/html/dashboard" element={<Dashboard />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
