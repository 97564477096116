import React, {useState} from 'react';
import TransferBar from './TransferBar';
import { Container, Row, Col } from 'reactstrap';
import MoneyTransferTable from './MoneyTransferTable';
import { transactionData } from './TransactionData';
import SendMoney from './SendMoney';


const MoneyTransfer = ({activeTab, setActiveTab, activeCurrent}) => {
    const [activeComponent, setActiveComponent] = useState('table')
    const handleShowSendMoney = () => {
        setActiveComponent('sendMoney');
    };

    return (
        <Container className='mx-0 px-0 dashboard-table'>
            <div className='py-4'>
                {activeTab === 'transfer' && activeCurrent === 'transfer' && activeComponent !== 'sendMoney' && <TransferBar handleShowSendMoney={handleShowSendMoney} />}
                {activeComponent === 'table' && <MoneyTransferTable tableData={transactionData} />}
                {activeComponent === 'sendMoney' && <SendMoney />}
            </div>
        </Container>
    );
};

export default MoneyTransfer;
