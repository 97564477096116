import React from 'react'
import { LuUpload } from "react-icons/lu";
import Signature from './Signature';


const UploadButton = ({title, isRequired, btnType = 'button', text}) => {
  return (
    <div className='upload-btn '>
        <p className='title'>{title}<span className='text-danger'>*</span></p>
        <div className={`upload-box ${btnType == 'button' ? 'bordered' : 'solid'} p-4 overflow-hidden`}>
            { 
                btnType == 'button' ? (
                    <div className='d-flex flex-column justify-content-center align-items-center upload-wrapper'>
                        <input type='file' className='hidden-file w-100'/>
                        <LuUpload />
                        <p className='mb-0 pb-0 mt-3'>
                            Upload <span>jpg,png</span> images <br/>
                        </p>
                        <p>(5mb max)</p>
                    </div>
                ) : (
                    <div className='d-flex justify-content-center content signature-wrap p-0'>
                        <Signature />
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default UploadButton