import React from 'react';
import Select from "react-select";

function SingleSelect(props) {
    return (<Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={props.selectedValue}
        value={props.selectedValue}
        isSearchable={true}
        name="color"
        options={props.value}
        onChange={props.onChangeHandler}
    />)
};

export default SingleSelect;