// Recharge.js
import React from 'react';
import RechargeBar from './RechargeBar';


const Recharge = () => {
    return (
        <div>
            <div className='py-4'>
                <RechargeBar />
            </div>
        </div>
    );
};

export default Recharge;
