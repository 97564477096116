import React from 'react'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import { Container, Row, Col } from 'reactstrap'

const RechargeBar = () => {
    const handleClick = () => {
        /// handle click
    }

  return (
    <Container className='recharge-bar p-3'>
        <Row className='d-flex justiry-content-between'>
            <Col md={6} className='d-flex mt-0'>
                <div className='item'>
                    <p className='title mb-0'>Account Balance</p>
                    <p className='text mb-0'>(Last update on 17-07-24)</p>
                </div>
            </Col>
            <Col md={6} className='buttons d-flex justify-content-end align-items-center'>
                <p className='price mb-0'>$120.00</p>
                <ButtonComponent
                    onClick={handleClick}
                    disabled={false}
                    className = 'orange-border thick-border'
                    btnType = 'no-border'
                >
                    Recharge Now
                </ButtonComponent>
            </Col>
        </Row>
    </Container>
  )
}

export default RechargeBar