import React, { useState, useEffect } from "react";
import axios from "../Api";
import { useQuery } from "react-query";
const useGetService = (api) => {
  const [response, setresponse] = useState("");
  const {
    isLoading: isLoadingProducts,
    refetch: getresponse,
    isFetching: isFetchItems,
  } = useQuery(
    "query-data",
    async () => {
      return await axios.get(api);
    },
    {
      
      onSuccess: (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        // setGetResult(fortmatResponse(result));
        setresponse(result);
        // setAllProducts(result?.data)
      },
      onError: (err) => {
        // setGetResult(fortmatResponse(err.response?.data || err));
      },
    }
  );
  useEffect(() => {
    getresponse();
  }, []);
  return { response, isLoadingProducts, isFetchItems };
};

export default useGetService;
