import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  FormGroup,
  Form,
  Input,
  Button,
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import useGetService from "../../services/getservice";
import usePutService from "../../services/putservice";
import ReactQuill from "react-quill";

const SingleDepoCalls = () => {
  // const [priceList, setPriceList] = useState(null);
  const [condition, setcondition] = useState("audio_call");
  const [updatedPrices, setUpdatedPrices] = useState(false);
  const [putPayload, setputPayload] = useState("");
  const [addPrices, setAddPrices] = useState({
    audio_price: 0,
    video_price: 0,
    audio_description: "",
    video_description: "",
    updated_by: 1,
  });
  //  console.log("add",addPrices);
  const { response: getSingleCalls } = useGetService(`api/call/getSingleCall`);
  const updateSingleDepoPrice = usePutService(
    putPayload,
    "api/call/updateSingleCall",
    updatedPrices
  );

  useEffect(() => {
    if (getSingleCalls?.status === "200-OK") {
      setAddPrices({
        audio_price: getSingleCalls?.data?.data?.audioSingleCallDetails?.price.toString(),
        video_price: getSingleCalls?.data?.data?.videoSingleCallDetails?.price.toString(),
        audio_description: getSingleCalls?.data?.data?.audioSingleCallDetails?.description,
        video_description: getSingleCalls?.data?.data?.videoSingleCallDetails?.description,
      })

    }
  }, [getSingleCalls]);
  useEffect(() => {
    if (
      updateSingleDepoPrice.status !== "200-OK" &&
      updateSingleDepoPrice !== ""
    ) {
      toast.error(updateSingleDepoPrice.data.message);
    } else if (updateSingleDepoPrice !== "") {
      toast.success(updateSingleDepoPrice.data.message);
    }
    setUpdatedPrices(false);

  }, [updateSingleDepoPrice]);

  const changeHandler = (e) => {
    const temp = { ...addPrices };
    temp[e.target.name] = e.target.value;
    setAddPrices(temp);
  };

  const audioDescriptionChangeHandler = (e) => {
    const temp = { ...addPrices };
    temp["audio_description"] = e;
    setAddPrices(temp);
  };

  const videoDescriptionChangeHandler = (e) => {
    const temp = { ...addPrices };
    temp["video_description"] = e;
    setAddPrices(temp);
  };

  const validate = () => {
    let isError = false;
    if (addPrices?.audio_price?.trim() === "") {
      toast.error("Audio price can't be empty")
      isError = true;
    }
    return isError;
  }
  const validate2 = () => {
    let isError2 = false;
    if (addPrices?.video_price?.trim() === "") {
      toast.error("Video price can't be empty")
      isError2 = true;
    }
    return isError2;
  }
  const handleInput = (e) => {
    setcondition(e.target.value)
  };
  const handleUpdate = () => {
    if (condition === "audio_call") {
      let isError = validate();
      if (!isError) {
        setputPayload({
          package_name: "audio",
          package_type: "audio_call",
          price: addPrices?.audio_price,
          description: addPrices?.audio_description
        })
        setUpdatedPrices(true)
      }

    }
    else if (condition === "video_call") {
      let isError2 = validate2();
      if (!isError2) {
        setputPayload({
          package_name: "video",
          package_type: "video_call",
          price: addPrices?.video_price,
          description: addPrices?.video_description
        })
        setUpdatedPrices(true)
      }
    }
  }
  return (
    <AdminLayout name=" Single Depo Calls">
      {/* {loading && <Loader></Loader>} */}
      <ListHeader />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="mb-0">Single Depo</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-3 align-right"
                  ></div>
                </div>
              </CardHeader>

              <div className="modal-body">
                <Form role="form">
                  <div className="col-md-8 d-flex align-items-center">
                    <h3 className="mb-0">Choose depo calls</h3>
                    <div className="col-md-4">
                      <select id="input-experience"
                        name="experience"
                        autoComplete="nope"
                        className="form-control-alternative form-control"
                        fdprocessedid="2aoekh"
                        value={condition}
                        onChange={handleInput}
                      >
                        <option value="audio_call">Audio</option>
                        <option value="video_call">Video</option>
                      </select>
                    </div>
                  </div>
                  {condition === "audio_call" ?
                    <div>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Audio Price ($)
                        </label>

                        <Input
                          defaultValue=""
                          id="audio_price"
                          placeholder="audio_price"
                          type="text"
                          name="audio_price"
                          value={addPrices.audio_price}
                          autoComplete="nope"
                          onChange={changeHandler}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Audio Description
                        </label>
                        <ReactQuill
                          style={{ height: 200 }}
                          id="audio_description"
                          name="audio_description"
                          theme="snow"
                          value={addPrices.audio_description}
                          onChange={audioDescriptionChangeHandler}
                        />
                      </FormGroup>
                    </div>
                    :
                    <div>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Video Price
                        </label>

                        <Input
                          defaultValue=""
                          id="video_price"
                          placeholder="video_price"
                          type="text"
                          name="video_price"
                          value={addPrices.video_price}
                          autoComplete="nope"
                          onChange={changeHandler}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Video Description
                        </label>
                        <ReactQuill
                          style={{ height: 200 }}
                          id="video_description"
                          name="video_description"
                          theme="snow"
                          value={addPrices.video_description}
                          onChange={videoDescriptionChangeHandler}
                        />
                      </FormGroup>
                    </div>
                  }


                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default SingleDepoCalls;
