import React, { useState,useEffect } from "react";
import { Button, Modal, ModalFooter } from "reactstrap";
import Placeholder from "../../assets/images/team-4.jpg";
import useModalGetService from "../../services/modalgetService";
import { imagePath } from "../../config/filepath";
import useModalPostService from "../../services/modaPostlService";
import { toast } from "react-toastify";
const CategoryStep4Modal = ({ isOpen, toggle,categoryId }) => {
  const [buttonImageState, setbuttonImageState] = useState(false);
  const [Image, setImage] = useState("");
  const [imagePayload, setimagePayload] = useState("");
  const [ImageResponse, setImageResponse] = useState("");
  let {response5,isFetchItems}=useModalGetService(`api/category/getPicturePathByCategory/${categoryId}`);
  let response3=useModalPostService(imagePayload,`api/category/uploadImage/${categoryId}/image`,buttonImageState);
  const [apicall, setapicall] = useState(false);
   useEffect(() => {
    if(response5?.status ==="200-OK"){  
        if(response5?.data?.imagePath !==null){
          setImageResponse(response5?.data?.imagePath)
        }
    }
  }, [response5]);
  const handleClose=()=>{
    setImageResponse("")
    setImage("")
    toggle()
    setapicall(false)
 }
  useEffect(() => {
    if (response3?.status ==="200-OK")
    {
      toast.success(response3?.data?.message)
      handleClose()
      setbuttonImageState(false)
    }
  }, [response3]);
  const handleFieldImageChange = (e) => {
    var formData = new FormData();
    formData.append("image",e.target.files[0]) ;
    setimagePayload(formData)
    setImage(e.target.files[0]);
    setImageResponse("")
  };
  const handleSave=()=>{
    setapicall(true)
    setbuttonImageState(true);
  }
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={handleClose}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add image of Normal Category
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-center">Upload Image</div>
        <div className="d-flex justify-content-center align-items-center">
        <div
          className="card-img-top"
          style={{
            borderRadius: "50%",
            width: "151px",
            height: "151px",
            overflow: "hidden",
            display: "block",
            margin: "0 auto",
          }}
        >
          <img
             src={ Image !== "" ? URL?.createObjectURL(Image) :
             ImageResponse !== ""
               ? imagePath+ImageResponse
             :Placeholder }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt=""
          />{" "}
          <div className="d-flex justify-space-between w-100 align-items-start mt-4"></div>
        </div>
        <div className="d-flex justify-content-center">
          <label className="fileUpload">
            <input
              type="file"
              id="image"
              name="logo"
              hidden
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFieldImageChange}
              multiple={false}
              style={{
                display: "none",
              }}
            />
            <span
              className="d-flex justify-content-center"
              style={{ display: "inline-block", color: "red" }}
            >
                {(Image !==""||ImageResponse !=="" ) ? "Change Image":"Upload Image"}
            
            </span>
          </label>
        </div>
        </div>
       
    
       
      </div>
      <hr/>
      <ModalFooter>
        <Button
          color="info"
          className="no-transform "
            disabled={ImageResponse !==""|| Image ==="" || apicall}
            onClick={handleSave}
        >
          Save
        </Button>
        <Button
          color="warning"
          className="no-transform "
          //   disabled={active !== item.categoryId}
            onClick={handleClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CategoryStep4Modal;
