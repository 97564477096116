import React, {useState} from 'react'
import { MdKeyboardArrowDown } from "react-icons/md";
import DatePicker from './DatePicker';

const DashboardCard = ({className, title, content}) => {


  return (
    <div className={`card-box ${className}`} > 
        <div className='date'>
          <DatePicker />
        </div>
        <p className='title mb-0'>{title}</p>
        <p className='content mb-0 mt-0'>{content}</p>
    </div>
  )
}

export default DashboardCard