import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
  Spinner,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import lblErr from "../../language/CreateUpdateErr";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import useConditiongetService from "../../services/conditiongetService";
import { validate_email } from "../../helpers/utils";
const CreateUpdate = (props) => {
  let { userId } = useParams();
  let navigate = useNavigate();
  let [fromData, setFromData] = useState({
    id: userId || '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isSuspend: '1',
    gender:'',
    role:''
  });

  let [mode, setMode] = useState("create");
  let [isUpdate, setUpdate] = useState(false);
  const [buttonState, setbuttonState] = useState(false);
  const [updateButtonState, setUpdateButtonState] = useState(false);
  const [loading,  setLoading] =useState(false)

  let [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    // password: "",
    // role_id: "",
    phone: "",
    isSuspend: "",
  });

  let { response2 } = useConditiongetService(`api/admin/get-client-by-id/${userId}`, isUpdate);

  let addPayload = {
    firstName: fromData?.firstName,
    lastName: fromData?.lastName,
    email: fromData?.email,
    phone: String(fromData?.phone),
    // isSuspend: String(fromData?.isSuspend),
    roleId:fromData.role,
    gender:fromData.gender
  }
  let response = usePostService(addPayload, '/api/admin/add-client', buttonState);

  let updatePayload = {
    firstName: fromData?.firstName,
    lastName: fromData?.lastName,
    email: fromData?.email,
    phone: String(fromData?.phone),
    isSuspend: String(fromData?.isSuspend)
  }
  let updateResponse = usePutService(updatePayload, `/api/admin/update-client/${fromData?.id}`, updateButtonState);

  useEffect(() => {
    if (userId) {
      setMode('update');
      setUpdate(true);
    }
  }, [])

  useEffect(() => {
    if (response2?.status === "200-OK" && isUpdate) {
      setLoading(false)
      let userDetails = response2?.data?.data;
      setFromData({
        id: userDetails.id,
        firstName: userDetails.firstName,
        lastName: userDetails?.lastName,
        email: userDetails?.email,
        phone: userDetails?.phone,
        isSuspend: userDetails?.isSuspend
      });
      setUpdate(false);
    }
  }, [response2])

  let { firstName, lastName, email, phone, isSuspend , gender , role } = fromData;

  let handleInput = (e) => {
    setFromData((preItem) => ({ ...preItem, [e.target.name]: e.target.value }))
    setLoading(false)
  }

  useEffect(() => {
    if (response.status !== "200-OK" && buttonState) {
      toast.error(response?.data?.message);
      setLoading(false)
    }
    else if (response.status == "200-OK" && buttonState) {
      toast.success(response?.data?.message)
      navigate("/user-management");
      setLoading(false)
    }
    setbuttonState(false)
  }, [response]);

  useEffect(() => {
    if (updateResponse !== "") {
      if (updateResponse.status !== "200-OK" && updateButtonState) {
        toast.error(updateResponse?.data?.message);
        setLoading(false)
      }
      else {
        toast.success(updateResponse?.data?.message)
        navigate("/user-management");
        setLoading(false)
      }
    }
    setUpdateButtonState(false);
  }, [updateResponse]);

  let handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true)
    if (validateCreateUpdateForm()) {
      if (mode === 'create')
        setbuttonState(true);
      else
        setUpdateButtonState(true);
    }
  }

  let validateCreateUpdateForm = () => {
    let isError = false,
      errFirstName = "",
      errLastName = "",
      errEmail = "",
      errPhone = ""
    // errRoleId = "";

    if (!firstName) {
      errFirstName = lblErr.enterFirstName;
      isError = true;
    }

    if (!lastName) {
      errLastName = lblErr.enterLastName;
      isError = true;
    }

    if (!email || !validate_email(email)) {
      errEmail = lblErr.enterAnEmail;
      isError = true;
    } else {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        errEmail = lblErr.invalidEmail;
        isError = true;
      }
    }

    // if (!role_id) {
    //   errRoleId = lblErr.selectARole;
    //   isError = true;
    // }

    if (
      phone &&
      phone.toString().length != 10
    ) {
      errPhone = lblErr.phoneLength;
      isError = true;
    }

    // if (!password && mode === 'create') {
    //   errPassword = lblErr.enterAPassword;
    //   isError = true;
    // }

    setError({
      ...error,
      firstName: errFirstName,
      lastName: errLastName,
      email: errEmail,
      // role_id: errRoleId,
      phone: errPhone,
    });
    return !isError;
  };

  return (
    <AdminLayout>
      <>
        {/* {loading && <Loader></Loader>} */}
        <ListHeader />
        {/* <ToastContainer /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col" >
              <div className="row">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            {(mode === 'create') ? 'Create New User' : 'Update User'}
                            {/* <AdminLayout>
                            Hello world
                        </AdminLayout> */}
                            {/* {currentURL.includes('create')
                            ? "Create New Admin"
                            : "Update Admin"} */}
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form onSubmit={(e) => handleSubmit(e)}>
                        <h6 className="heading-small text-muted mb-4">
                          User information
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  First name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id="input-first-name"
                                  placeholder="First name"
                                  type="text"
                                  required
                                  maxLength={10}
                                  minLength={3}
                                  pattern="[A-Za-z]+"
                                  name="firstName"
                                  autoComplete="nope"
                                  value={firstName}
                                  onChange={(e) => {
                                    if (!e.target.value.includes(' '))
                                      handleInput(e)
                                  }}
                                />
                                {error.firstName ? (
                                  <ErrorMessage errorMsg={error.firstName} />
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Last name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id="input-last-name"
                                  placeholder="Last name"
                                  type="text"
                                  minLength={3}
                                  required
                                  maxLength={10}
                                  pattern="[A-Za-z]+"
                                  name="lastName"
                                  autoComplete="nope"
                                  value={lastName}
                                  onChange={(e) => {
                                    if (!e.target.value.includes(' '))
                                      handleInput(e)
                                  }}
                                />
                                {error.lastName ? (
                                  <ErrorMessage errorMsg={error.lastName} />
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email address
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  placeholder="Email"
                                  type="email"
                                  name="email"
                                  autoComplete="nope"
                                  value={email}
                                  onChange={(e) => handleInput(e)}
                                />
                                {error.email ? (
                                  <ErrorMessage errorMsg={error.email} />
                                ) : null}
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Phone
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-phone-name"
                                  placeholder="Phone"
                                  type="tel"
                                  maxLength={10}
                                  name="phone"
                                  autoComplete="nope"
                                  value={phone}
                                  onChange={(e) => handleInput(e)}
                                />
                                {error.phone ? (
                                  <ErrorMessage errorMsg={error.phone} />
                                ) : null}
                              </FormGroup>
                            </Col>
                            {/* <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Role
                                </label>
                                <Input
                                  type="select"
                                  name="role_id"
                                  id="selectMulti"
                                  value={userCreateUpdate.role_id}
                                  onChange={(e) => handleInput(e)}
                                >
                                  <option value="">--- Select ---</option>
                                  <option value="1">
                                    Hii
                                  </option>
                                  {roles.map((role) => {
                                    return (
                                      <option value={role.id} key={role.id}>
                                        {role.role}
                                      </option>
                                    );
                                  })}
                                </Input>
                                {error.role_id ? (
                                  <ErrorMessage errorMsg={error.role_id} />
                                ) : null}
                              </FormGroup>
                            </Col> */}

                            { mode === "update" && (<Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Status
                                </label>
                                <Input
                                  type="select"
                                  name="isSuspend"
                                  id="selectMulti"
                                  value={isSuspend}
                                  onChange={(e) => handleInput(e)}
                                >
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </Input>
                              </FormGroup>
                            </Col>)}

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Gender
                                </label>
                                <Input
                                  type="select"
                                  name="gender"
                                  id="selectMulti"
                                  value={gender}
                                  onChange={(e) => handleInput(e)}
                                >
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  User Role
                                </label>
                                <Input
                                  type="select"
                                  name="role"
                                  id="selectMulti"
                                  value={role}
                                  onChange={(e) => handleInput(e)}
                                >
                                  <option value="6">Associate User</option>
                                  <option value="7">Normal  User</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            {/* <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Enable Multifactor Authenticator
                                </label>
                                <Input
                                  type="select"
                                  name="is_twofa_enabled"
                                  id="selectMulti"
                                // value={userCreateUpdate.is_twofa_enabled}
                                // onChange={(e) => handleInput(e)}
                                >
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Temporary Account
                                </label>
                                <Input
                                  type="select"
                                  name="temporary_account"
                                  id="selectMulti"
                                // value={userCreateUpdate.temporary_account}
                                // onChange={(e) => handleInput(e)}
                                >
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>

                                </Input>
                              </FormGroup>
                            </Col> */}
                          </Row>
                          {/* {userCreateUpdate.is_twofa_enabled === "1" &&
                          // <Row>
                          //   <Col lg="6"></Col>
                          //   <Col lg="6">
                          //     <a
                          //       className="form-control-label"
                          //       href="javascript:void(0)"
                          //       onClick={() => handleResetSubmit()}
                          //     >
                          //       <small>Reset 2FA</small>
                          //     </a>
                          //   </Col>
                          // </Row>
                          <FormGroup className="d-flex justify-content-end">
                            <a
                              className="form-control-label"
                              href="javascript:void(0)"
                              onClick={() => handleResetSubmit()}
                            >
                              <small>Reset 2FA</small>
                            </a>
                          </FormGroup>

                        } */}
                        </div>
                        <hr className="my-4" />
                        <div className="pl-lg-4">
                          <FormGroup className="d-flex justify-content-end">
                            {/* {
                              (mode === 'update') ? <Button
                                color="info"
                                className="no-transform"
                                onClick={() => handleSubmit()}
                              >
                                Update
                              </Button> : <Button
                                color="info"
                                className="no-transform"
                                onClick={() => handleSubmit()}
                              >
                                Submit
                              </Button>
                            } */}

                            <Button
                              color="info"
                              type="submit"
                              className="no-transform"
                              onClick={(e) => handleSubmit(e)}
                              disabled={loading}
                            >
                              {loading ? <Spinner color="primary">Loading...</Spinner>: (mode === 'update' ? 'Update' : 'Submit')}
                            </Button>


                            <Button
                              color="warning"
                              className="no-transform"
                              onClick={() => navigate("/user-management")}
                            >
                              Cancel
                            </Button>
                          </FormGroup>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
        </Container>
      </>
    </AdminLayout>
  );
};

export default CreateUpdate;
