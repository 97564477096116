// Dropdown.js
import React, { useState } from 'react';
import { MdKeyboardArrowDown } from "react-icons/md";


const DropdownCustom = ({ value, dropName, title, isRequired = true, options, onSelect, defaultOption, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) onSelect(option);
  };

  return (
    <div className={`${className} dropdown-custom w-100`}>
      <label className="dropdown-title w-100">
        {title}
        {isRequired && <span className='required-astrix text-danger'>*</span>}
      </label>
      <div className="dropdown-container-custom w-100" onClick={handleToggle}>
        <div value={value} name={dropName} className="dropdown-selected-custom">{selectedOption || defaultOption}</div>
        <div className="dropdown-arrow">
          <MdKeyboardArrowDown />
        </div>
        {isOpen && (
          <div className="dropdown-menu-custom">
            {options.map((option, index) => (
              <div
                key={index}
                className="dropdown-option-custom"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownCustom;
