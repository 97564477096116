import React, { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
// core components
import ListHeader from "../../../components/Headers/ListHeader";
import AdminLayout from "../../../layouts/AdminLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import useGetService from "../../../services/getservice";
import usePostService from "../../../services/postservice";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
const CategoryStep1 = () => {
  const [payload, setpayload] = useState({
    parentId:"",
    displayName:""
  });
  const [displaynamechanged, setdisplaynamechanged] = useState(false);
  const [searchParams] = useSearchParams();
  const [buttonState, setbuttonState] = useState(false);
  console.log("value",searchParams.get("topParentId"))
  const navigate=useNavigate();
  const [errordisplay,seterrordisplay]=useState("");
  let {response,isFetchItems}=useGetService(`/api/category/getDisplayNameFromParentIdStep1?topParentId=${searchParams.get("topParentId")}`);
  let response2=usePostService(payload,'api/category/createCategoryStep1',buttonState);
  console.log("respon",response2?.status,response)
  useEffect(() => {
    if (response2?.status ==="200-OK" && buttonState){
     toast.success(response2?.data?.message);
     navigate(`/categorystep?step=2&topParentId=${response2?.data?.createdCategoryId}`)
     setbuttonState(false)
     setdisplaynamechanged(false);
    }
    else if(buttonState) {
      seterrordisplay(response2?.data?.message)
      setbuttonState(false)
    }
  }, [response2]);
  useEffect(() => {
  if (response?.data?.displayName !==null){
    setpayload({
      displayName:response?.data?.displayName,
      parentId:searchParams.get("topParentId")});
    //  setdisplayName(response?.data?.displayName)
  }
  }, [response?.data?.displayName, searchParams]);
  console.log("displa",payload);
  const validate=()=>{
    let isError=false;
   if(payload.displayName === "" || payload.displayName === null || payload.displayName === undefined){
    seterrordisplay("Display name can't be empty")
    isError=true;
   } 
   return isError;
  }
  const handleNext=()=>{
    let isError = validate();
    if (payload.parentId !==null && !displaynamechanged){
      navigate(`/categorystep?step=2&topParentId=${payload?.parentId}`)
    }
    else if (!isError) {
      // navigate('/categorystep?step=2')
      setbuttonState(true)
    }
  }
  const handledisplayname=(value)=>{
   setpayload({
    ...payload,
    displayName:value
   })
   setdisplaynamechanged(true)
  }
  return (
    <AdminLayout name="Category">
    <>
      {isFetchItems && <Loader></Loader>}
      <ListHeader />
      {/* <ToastContainer /> */}
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col" >
            <div className="row">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h3 className="mb-0">
                        Display name for category
                        {/* <AdminLayout>
                            Hello world
                        </AdminLayout> */}
                          {/* {currentURL.includes('create')
                            ? "Create New Admin"
                            : "Update Admin"} */}
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form className="justify-content-center">

                      <Row>
                          <Col lg="6" className="mx-auto text-center">
                            <FormGroup className="form-group">
                              <input type="text" 
                              className="form-control" 
                              placeholder="Display name"
                              name="displayName"
                              value={payload.displayName}
                              onChange={(e)=>handledisplayname(e.target.value)}
                              onFocus={()=>seterrordisplay("")}
                              />
                                 <div style={{float:"left"}}>
                            {errordisplay !=="" ? <ErrorMessage errorMsg={errordisplay} /> : null}
                            </div>
                            </FormGroup>
                           

                          </Col>
                      </Row>

                      <hr className="my-4" />
                      
                      <FormGroup className="d-flex justify-content-between">
                          <div></div>
                        <Button 
                        color="info" 
                        className="no-transform"
                        onClick={handleNext}
                        >
                          Next Step &#10230;
                        </Button>
                      </FormGroup>
                     
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
    </>
    </AdminLayout>
  );
};

export default CategoryStep1;
