import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Modal,
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import Loader from '../../components/Loader/Loader';
import useGetService from "../../services/getservice";
import useDebounce from "../../components/custom-hooks/useDebounce";
import useConditiongetService from "../../services/conditiongetService";

const CaseList = () => {
    const navigate = useNavigate();
    let [userModal, setUserModal] = useState(false);
    const [caseList, setCaseList] = useState([]);

    let [searchInput, setSearchInput] = useState('');
    const [caseNoSortOrder, setCaseNoSortOrder] = useState('asc');
    const [courtNoSortOrder, setCourtNoSortOrder] = useState('asc');
    const [claimNoSortOrder, setClaimNoSortOrder] = useState('asc');
    const [fileNoSortOrder, setFileNoSortOrder] = useState('asc');

    const [conditionButton, setConditionButton] = useState(false);
    const [sortName, setSortName] = useState('case_name');
    const [finalsortOrder, setFinalSortOrder] = useState('');

    const [totalPage, settotalPage] = useState(0);
    const [page, setPage] = useState(1);

    const debouncedSearchTerm = useDebounce(searchInput, 500);

    let { response2, isFetchItems } = useConditiongetService(`/api/case/getAllCaseList?sort_name=${sortName}&search=${debouncedSearchTerm}&sort_order=${finalsortOrder}&size=20&page=${page}`, conditionButton);

    const onSearchChange = (searchValue) => setSearchInput(searchValue);

    const sortItemHandler = (name, direction) => {
        setFinalSortOrder(direction);
        setSortName(name);
        setConditionButton(true);
    }

    useEffect(() => {
        if (debouncedSearchTerm !== "") {
            setConditionButton(true);
            setSearchInput(debouncedSearchTerm);
        }
        else if (debouncedSearchTerm === "" && searchInput === "") {
            setConditionButton(true);
            setSearchInput("");
        }
    }, [
        debouncedSearchTerm
    ]);

    useEffect(() => {
        if (response2?.status === "200-OK" && conditionButton) {
            setCaseList(response2?.data?.data);
            settotalPage(response2?.data?.totalPages || 1);
            setConditionButton(false);
        }
    }, [
        response2
    ]);

    const onPageChange = (value) => {
        setPage(value);
        setConditionButton(true);
    }

    return (
        <AdminLayout name="Case Management">
            {isFetchItems && <Loader>Loading</Loader>}
            <ListHeader />
            {/* <ToastContainer /> */}
            {/* <ConfirmationDialog
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
        action={"Delete"}
        onClick={() => handleDelete(selected_id)}
      ></ConfirmationDialog> */}
            <Modal
                className="modal-dialog-centered"
                isOpen={userModal}
                toggle={() => setUserModal(false)}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Case Information
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setUserModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Table className="align-items-center" responsive>
                        <tbody>
                            <tr>
                                <th scope="col">Firstname</th>
                                <td>Hii</td>
                            </tr>
                            <tr>
                                <th scope="col">Lastname</th>
                                <td>Hello</td>
                            </tr>
                            <tr>
                                <th scope="col">Email</th>
                                <td>Hello</td>
                            </tr>
                            <tr>
                                <th scope="col">Phone</th>
                                <td>Phone</td>
                            </tr>
                            <tr>
                                <th scope="col">Status</th>
                                <td>Active</td>
                            </tr>
                            <tr>
                                <th scope="col">Created At</th>
                                <td>
                                    Hello
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Modal>
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3 className="mb-0">Cases List</h3>
                                    </div>
                                    <div
                                        style={{ textAlign: "right" }}
                                        className="col-md-4 align-right"
                                    >
                                        <div
                                            className="mb-0 form-group inline"
                                            style={{
                                                width: "80%",
                                                display: "inline-block",
                                                marginRight: "15px",
                                            }}
                                        >
                                            <div className="input-group-alternative input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-search"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    placeholder="Search"
                                                    type="text"
                                                    className="form-control"
                                                    value={searchInput}
                                                    onChange={(e) => onSearchChange(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <UncontrolledDropdown className="inline">
                                            <DropdownToggle
                                                className="btn-icon-only text-dark"
                                                href="#pablo"
                                                role="button"
                                                size="sm"
                                                color=""
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <i className="fas fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                    style={{ display: "none" }}
                                                >
                                                    Make Active
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                    style={{ display: "none" }}
                                                >
                                                    Make Inactive
                                                </DropdownItem>

                                                <DropdownItem
                                                    // href="javascript:void(0)"
                                                    onClick={() => navigate("/create-case-management")}
                                                >
                                                    Add New Case
                                                </DropdownItem>

                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                    style={{ display: "none" }}
                                                >
                                                    Delete
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive style={{
                                marginBottom: '25px'
                            }}>
                                <thead className="thead-light">
                                    <tr>

                                        <th scope="col">Case Name
                                            {caseNoSortOrder === 'asc' ?
                                                <i className="fa fa-fw fa-sort" onClick={() => {
                                                    sortItemHandler('case_name', 'asc');
                                                    setCaseNoSortOrder('desc')
                                                }}></i>
                                                :
                                                <i className="fa fa-fw fa-sort" onClick={() => {
                                                    sortItemHandler('case_name', 'desc');
                                                    setCaseNoSortOrder('asc')
                                                }}>
                                                </i>}
                                        </th>
                                        <th scope="col">Creator
                                            
                                        </th>
                                        <th scope="col">Court No {courtNoSortOrder === 'asc' ?
                                            <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('court_number', 'asc'); setCourtNoSortOrder('desc') }}></i>
                                            :
                                            <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('court_number', 'desc'); setCourtNoSortOrder('asc') }}></i>}
                                        </th>

                                        <th scope="col">Claim No {claimNoSortOrder === 'asc' ?
                                            <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('claim_number', 'asc'); setClaimNoSortOrder('desc') }}></i>
                                            :
                                            <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('claim_number', 'desc'); setClaimNoSortOrder('asc') }}></i>}
                                        </th>
                                        <th scope="col">File No {fileNoSortOrder === 'asc' ?
                                            <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('file_number', 'asc'); setFileNoSortOrder('desc') }}></i>
                                            :
                                            <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('file_number', 'desc'); setFileNoSortOrder('asc') }}></i>}
                                        </th>

                                        {/* <th scope="col">Attendies</th> */}
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(caseList.length > 0) && caseList.map((caseItem, index) => {
                                        let caseName = caseItem?.case_name ? caseItem?.case_name?.toLowerCase()?.split(' vs ') : '--';
                                        return (<tr key={index}>
                                            <td className="w-20">{typeof caseName == 'string' ? caseName : <>{caseName[0]}
                                                <span className="d-block">vs</span>
                                                {caseName[1]}</>}</td>
                                            <td>{caseItem?.user?.first_name} {caseItem?.user?.last_name}</td>
                                            <td>{caseItem?.court_number}</td>
                                            <td>{caseItem?.claim_number}</td>
                                            <td>{caseItem?.file_number}</td>
                                            {/* <td>{caseItem?.invite_count}</td> */}
                                            <td>{caseItem?.deleted_at ? 'Inactive' : 'Active'}</td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        href="#pablo"
                                                        role="button"
                                                        size="sm"
                                                        color=""
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" container="body">
                                                        <DropdownItem
                                                            // href="javascript:void(0)"
                                                            onClick={() => navigate(`/case-management/view/${caseItem.id}`)}
                                                        >
                                                            View
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => navigate(`/case-management/edit/${caseItem.id}`)}
                                                        >
                                                            Edit
                                                        </DropdownItem>
                                                        {/* <DropdownItem
                                                        // href="javascript:void(0)"

                                                        >
                                                            Delete
                                                        </DropdownItem> */}
                                                        {/* <DropdownItem
                                                        href="javascript:void(0)"
                                                          onClick={() => {
                                                            setSelectedId(user.email);
                                                            setResetModal(true);
                                                          }}
                                                        >
                                                            Reset Password
                                                        </DropdownItem> */}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                {totalPage > 1 ?
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem
                                                // className="disabled"
                                                className={page < 2 ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page - 2);
                                                    }}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {[...Array(totalPage)].map((_, i) => {
                                                return (
                                                    <PaginationItem
                                                        key={i}
                                                        // className="active"
                                                        className={page === i + 1 ? "active" : ""}
                                                    >
                                                        <PaginationLink
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onPageChange(i + 1);
                                                            }}
                                                        >
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                            })}
                                            <PaginationItem
                                                // className="disabled"
                                                className={page + 2 > totalPage ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page + 2);
                                                    }}
                                                >
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                    : null}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>

        </AdminLayout>

    );
};

export default CaseList;
