import React from 'react'
import { Modal, Table } from 'reactstrap';

const ViewDetailsModal = ({ isOpen, title, attorneyDetail, roleList, viewHandler }) => {
    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={viewHandler}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {title}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={viewHandler}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Table className="align-items-center" responsive>
                    <tbody>
                        <tr>
                            <th scope="col">Id</th>
                            <td>{attorneyDetail?.id}</td>
                        </tr>
                        <tr>
                            <th scope="col">Name</th>
                            <td>
                                {attorneyDetail?.first_name}{" "}
                                {attorneyDetail?.last_name}
                            </td>
                        </tr>
                        <tr>
                            <th scope="col">Email ID</th>
                            <td> {attorneyDetail?.email}</td>
                        </tr>
                        <tr>
                            <th scope="col">Phone No.</th>
                            <td> {attorneyDetail?.phone || '--'}</td>
                        </tr>
                        <tr>
                            <th scope="col">Role</th>
                            <td> {roleList?.find(roleItem => roleItem.value === attorneyDetail?.role_id)?.label}</td>
                        </tr>
                        <tr>
                            <th scope="col">NPI Number</th>
                            <td>{attorneyDetail?.npi_number || '--'}</td>
                        </tr>
                        <tr>
                            <th scope="col">Street Address</th>
                            <td>{attorneyDetail?.street_address || '--'}</td>
                        </tr>
                        <tr>
                            <th scope="col">State</th>
                            <td>{attorneyDetail?.state || '--'}</td>
                        </tr>
                        <tr>
                            <th scope="col">City</th>
                            <td>{attorneyDetail?.city || '--'}</td>
                        </tr>
                        <tr>
                            <th scope="col">Zip Code</th>
                            <td>{attorneyDetail?.zipcode || '--'}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Modal>
    )
}

export default ViewDetailsModal;