import React, {useState} from 'react'
import { Col, Container, Row } from 'reactstrap'
import RadioButton from '../../components/Buttons/RadioButton'
import InputField from '../../components/Buttons/InputField'
import CheckBox from '../../components/Buttons/CheckBox'
import ButtonComponent from '../../components/Buttons/ButtonSubmit'
import { Link } from "react-router-dom";
import DropdownCustom from '../../components/Buttons/DropdownCustom'
import ProgressBar from '../../components/Progressbar/ProgressBar'
import DatePickerCustom from '../../components/Buttons/DatePickerCustom'
import { FaChevronDown } from 'react-icons/fa'
import NumberDropdown from '../../components/Buttons/NumberDropdown'
import UploadButton from '../../components/Buttons/UploadButton'
import Popup from '../../components/Popup/Popup'


const Signup = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [step, setStep] = useState(1);  
    const [isOpen, setIsOpen] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        sirname: '',
        dob: '',
        gender: '',
        mobile: '',
        email: '',
        password: '',
        confirmPassword: '',
        clientType: '',
    })

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const options = ['Male', 'Female', 'Other'];

    const handleSelect = (option) => {
        console.log('Selected:', option);
    };

    const handleNumberInput = (option) => {
        console.log('Selected:', option);
    }
  
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.id);
        formData.clientType = event.target.value;
    };

    const nextStep = () => {
        setStep(step + 1);
    }

    const prevStep = () => {
        if(step === 0 || step === 1){
            return setStep(1);
        }
        setStep(step - 1);
        console.log(step);
    }


    const handleSubmit = () => {
        setIsOpen(!isOpen);
    }

    const handleClose = () => {
        setIsOpen(!isOpen);
    }

    const renderContent = () => {
        {
            switch(step){
                case 1:
                return(
                    <>
                        <Col md={12} className='form-inputs mt-5'>
                            <h3 className='mb-3'>Personal Details</h3>
                            <Row>
                                <Col md={12}>
                                    <InputField
                                        name="name"
                                        type="text"
                                        label="Name"
                                        placeholder="Enter name"
                                        required
                                        value={formData.name}
                                        onChange={(e) => setFormData(e.target.value)}
                                    />
                                    <InputField
                                        name="sirname"
                                        type="text"
                                        label="Surname"
                                        placeholder="Enter Surname"
                                        required
                                        value={formData.sirname}
                                        onChange={(e) => setFormData(e.target.value)}
                                        className='mt-4'
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <DatePickerCustom 
                                        name="dob"
                                        title='DOB'
                                        required={true}
                                        value={formData.dob}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <DropdownCustom 
                                        name="gender"
                                        title="Gender"
                                        options={options}
                                        onSelect={handleSelect}
                                        defaultOption='Choose Gender'
                                        value={formData.gender}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <NumberDropdown
                                        name="mobile"
                                        title='Mobile Number'
                                        options={['+91', '+91', '+1']}
                                        onSelect={handleNumberInput}
                                        defaultOption='+01'
                                        type='number'
                                        placeholder='Enter Mobile Number'
                                        required={true}
                                        onChange={(e) => setFormData(e.target.value)}
                                        value={formData.mobile}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <InputField
                                        name="email"
                                        type="email"
                                        label="Email"
                                        placeholder="Enter Email Address"
                                        required
                                        value={formData.email}
                                        onChange={(e) => setFormData(e.target.value)}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <InputField
                                        name="password"
                                        type="password"
                                        label="Password"
                                        placeholder="Enter Password"
                                        imageSrc="../assets/info.png"
                                        required
                                        value={formData.password}
                                        onChange={(e) => setFormData(e.target.value)}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <InputField
                                        name="confirmPassword"
                                        type="password"
                                        label="Confirm Password"
                                        placeholder="Re-enter Password"
                                        imageSrc="../assets/info.png"
                                        required
                                        value={formData.confirmPassword}
                                        onChange={(e) => setFormData(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col md={12} className='mt-5 d-flex justify-content-center'>
                        <ButtonComponent className='w-100' onClick={nextStep}>
                            Next
                            <i className="ml-3 fa fa-regular fa-angle-right"></i>
                        </ButtonComponent>
                        </Col>
                    </>
                )
                
                case 2:
                return(
                    <>
                        <Col md={12} className='form-inputs mt-5'>
                            <h3 className='mb-3'>Other Details</h3>
                            <Row>
                                <Col md={12}>
                                    <InputField
                                        name="address"
                                        type="text"
                                        label="Address"
                                        placeholder="Enter Address"
                                        required
                                        value={formData.address}
                                        onChange={(e) => setFormData(e.target.value)}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <DropdownCustom 
                                        name="country"
                                        title="Country"
                                        options={options}
                                        onSelect={handleSelect}
                                        defaultOption='Choose Country'
                                        value={formData.country}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <DropdownCustom 
                                        name="state"
                                        title="State"
                                        options={options}
                                        onSelect={handleSelect}
                                        defaultOption='Choose State'
                                        value={formData.state}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <DropdownCustom 
                                        name="city"
                                        title="City"
                                        options={options}
                                        onSelect={handleSelect}
                                        defaultOption='Enter City'
                                        value={formData.city}
                                    />
                                </Col>
                                <Col md={6} className='mt-4'>
                                    <DropdownCustom 
                                        name="zipcode"
                                        title="Zip Code"
                                        options={options}
                                        onSelect={handleSelect}
                                        defaultOption='Enter Zip Code'
                                        value={formData.zipcode}
                                    />
                                </Col>

                                <Col md={12} className='mt-4'>
                                    <h3>Upload Documents</h3>
                                    <Row className='m-0 p-0'>
                                        <Col md={6} className='mb-5 pl-0'><UploadButton title='ID Document' /></Col>
                                        <Col md={6} className='mb-5 pr-0'><UploadButton title='Profile Image' /></Col>
                                        <Col md={6} className='mb-3 pl-0'><UploadButton title='Upload Signature' /></Col>
                                        <Col md={6} className='mb-3 pr-0'><UploadButton title='Digital Signature' btnType="" /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={12} className='mt-0 checkbox position-relative'>
                            <CheckBox
                            className="custom-check"
                            id="terms-checkbox"
                            label={
                                <>
                                    Yes, I agree to the <span className="highlighted-text">Terms & Conditions</span> agreement.
                                </>
                                }
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            />
                        </Col>

                        <Col md={6} className='mt-5 d-flex justify-content-center'>
                            <ButtonComponent className='w-100' onClick={prevStep} btnType=''>
                                <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                Back
                            </ButtonComponent>
                        </Col>
                        <Col md={6} className='mt-5 d-flex justify-content-center'>
                            <ButtonComponent className='w-100' onClick={handleSubmit}>
                                Submit
                                <i className="ml-3 fa fa-regular fa-angle-right"></i>
                            </ButtonComponent>
                        </Col>
                    </>
                )
                default:
                return null
            }
        }
    }

  return (
    <Container fluid className='normal-user signup-user px-0 mx-0'>
        <Row className='row px-0 mx-0'>
            <Col md={12} xl={7} className='px-0 mx-0 left-column d-flex justify-content-center'>
                <div className='m-0 left-inner w-100'>
                <Row className='row px-0 mx-0'>
                    <Col md={12} className='mx-0'>
                    <h2 className=''>Sign up
                        <span className='d-block mt-2'>Create your account to access exclusive features!</span></h2>
                    </Col>

                    <Col md={12} className='mx-0 mt-3'>
                        <div className='radio-btn-wrap d-flex align-items-center flex-wrap'>
                            <div className='mr-18 mb-0 d-flex align-items-center'>
                                <p className='mb-2'>Client Type*</p>
                            </div>
                            <div className='mb-0 d-flex align-items-center flex-wrap'>
                            <div className='mr-2 mr-10 mb-2'>
                                <RadioButton
                                    id="option1"
                                    name="custom-radio"
                                    checked={selectedOption === 'option1'}
                                    onChange={handleRadioChange}
                                    label="Normal"
                                    value="Normal"
                                />
                            </div>
                            <div className='mr-10 mb-2'>
                                <RadioButton
                                    id="option2"
                                    name="custom-radio"
                                    checked={selectedOption === 'option2'}
                                    onChange={handleRadioChange}
                                    label="Associate"
                                    value="Associate"
                                />
                            </div>
                            <div className='mr-0 mb-2'>
                                <RadioButton
                                    id="option3"
                                    name="custom-radio"
                                    checked={selectedOption === 'option3'}
                                    onChange={handleRadioChange}
                                    label="Agency"
                                    value="Agency"
                                />
                            </div>
                            </div>
                        </div>
                    </Col>
                    
                    <Col md={12} className='mt-5'>
                        <ProgressBar 
                            currentStep={step}
                            startText="1. Personal Details"
                            endText="2. Other Details"
                            startImg="../assets/user-detail-form.png"
                            endImg="../assets/file.png"
                            stepOne={step > 1 ? true : false}
                            stepTwo={step > 2 ? true : false}
                        />
                    </Col>

                    {renderContent()}

                    <Col md={12} className='mt-4 signup-link d-flex justify-content-center'>
                        <p>Already have an account?
                            <Link to='/html/login' className='text-decoration-none'> Login</Link> 
                        </p>
                    </Col>

                </Row>  
                </div>
            </Col>
        
            <Col md={12} xl={5} className='px-0 mx-0 right-column'>
                <img src='../assets/signup-banner.webp' />
            </Col>
        </Row>
        { isOpen && 
            <Row className='row mx-0 popup-container d-flex justify-content-center align-items-center px-3 px-md-0'>
                <Col md={12} className='popupWrapper'>
                    <Popup onClick={handleClose} />
                </Col>
            </Row>
        }
    </Container>
  )
}

export default Signup