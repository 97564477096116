import React from 'react'
import Sidebar from '../features/client/Dashboard/Sidebar';
import { Col, Container, Row } from 'reactstrap';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import TopBar from '../features/client/Dashboard/TopBar';

const ClientLayout = (props) => {
  const location = useLocation()
  // console.log(location.pathname.split('/')[2])
  return (
    <div  className='normal-dashboard container-fluid'>
      <div className='row'>
        <div  className='dashboard-sidebar px-0 col-md-2'>
          <Sidebar />
        </div>
        <div  className='dashboard-main-content col-md-10'>
          <div  className='px-0 mx-0 p-0 m-0 container-fluid'>
            <Row className='px-0 mx-0 p-0 m-0'>
              <div className='px-0 col-12'>
                <TopBar title={location?.pathname?.split('/')[2].toUpperCase()} />
              </div>

              <div  className='px-0 col-md-12'>
                {props.children}
              </div>
              <div  className='px-0 col-md-12'>
                <p className='copyright-footer d-flex align-items-center'>
                  Copyright © 2024 Dualmoney.com. All Rights Reserved |
                  <Link to=''>Terms and Condition</Link> |
                  <Link to=''>Privacy Policy</Link> |
                  <Link to=''>About Us</Link>
                </p>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientLayout