import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { FaEye, FaEyeSlash, FaCalendar } from 'react-icons/fa';
import { CiCalendarDate } from "react-icons/ci";

// Define the InputField component
const InputField = ({
  inputName = '',
  type = 'text',       // Type of input: 'text', 'email', 'password'
  label,               // Label for the input field
  placeholder,         // Placeholder text for the input field
  icon: Icon,          // Optional icon component
  imageSrc,          // Optional image component
  required = false,    // Whether the field is required
  onChange,            // Change event handler
  value,               // Value of the input field
  className = '',      // Custom CSS class for styling
}) => {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const inputType = type === 'password' ? (showPassword ? 'text' : 'password') : type;

  return (
    <div className={`input-field ${className}`}>
      <div className="label-container">
        <label>
          {label}
          {required && <span className="required-asterisk text-danger">*</span>}
          {Icon && <span className="icon-container">{<Icon />}</span>}
          {imageSrc && <img src={imageSrc} alt="" className="image-container" />}
        </label>
      </div>
      <div className='d-flex input-wrapper'>
        <input
            name={inputName}
            type={inputType}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="input-field-input"
        />
        {type === 'password' && (
            <button
              type="button"
              className="toggle-password-btn"
              onClick={togglePasswordVisibility}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
        )}
        {type==='date' && (
          <button
            type="button"
            className="date-btn"
            onClick={""}
          >
            <CiCalendarDate />
          </button>
        )}
      </div>
    </div>
  );
};

// Define prop types
InputField.propTypes = {
  inputName: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password']),
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.elementType,
  imageSrc: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default InputField;
