import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import lblErr from "../../language/CreateUpdateErr";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import ReactQuill from "react-quill";
import Placeholder from "../../assets/images/team-4.jpg";
import useConditiongetService from "../../services/conditiongetService";
import { baseURL } from "../../config/filepath";

const CreateService = () => {
  let { serviceId } = useParams();
  let navigate = useNavigate();
  const [image, setimage] = useState("");
  const [imagepayload, setimagepayload] = useState('');
  const [uploadpayload, setuploadpayload] = useState('');
  const [description, setDescription] = useState('');
  let [fromData, setFromData] = useState(
    {
      name: "",
      description: "",
      image: "",
    },
  );

  let [mode, setMode] = useState("create");
  const [buttonimageState, setbuttonimageState] = useState(false);
  const [updateButtonState, setUpdateButtonState] = useState(false);

  const [updateInfoState, setUpdateInfoState] = useState(false);
  const [updateInfoButtonState, setUpdateInfoButtonState] = useState(false);

  let [error, setError] = useState({
    name: "",
    description: ""
  });

  let response = usePostService(imagepayload, "/api/imageUpload/service_upload", buttonimageState);
  let response2 = usePostService(uploadpayload, "/api/admin/service/create", updateButtonState)

  let { response2: updateInfoResponse } = useConditiongetService(`/api/admin/getServiceById/${serviceId}`, updateInfoState)
  let updateResponse = usePutService(uploadpayload, `/api/admin/updateService/${serviceId}`, updateInfoButtonState)

  useEffect(() => {
    if (serviceId) {
      setMode("update")
      setUpdateInfoState(true);
    }
  }, [serviceId])

  useEffect(() => {
    if (updateResponse?.status === "200-OK" && updateInfoButtonState) {
      toast.success(response2?.data?.message);
      setUpdateInfoButtonState(false);
      setbuttonimageState(false)
      navigate("/services")
    }
  }, [updateResponse]);

  useEffect(() => {
    if (updateInfoResponse?.status === "200-OK" && updateInfoState) {
      setUpdateInfoState(false);
      setFromData({
        name: updateInfoResponse?.data?.data?.name,
        description: updateInfoResponse?.data?.data?.description,
        image: updateInfoResponse?.data?.data?.image
      });
      setDescription(updateInfoResponse?.data?.data?.description || '');
    }
  }, [updateInfoResponse]);

  useEffect(() => {
    if (response?.status === "200-OK") {
      if (serviceId) {
        setuploadpayload({ ...fromData, description: description, image: response?.data?.fileData[0].file_name });
        setUpdateInfoButtonState(true);
      } else {
        let arrayformdata = {
          services: [{ name: fromData?.name, description: description, image: response?.data?.fileData[0].file_name, page_name: "service" }]
        }
        setuploadpayload(arrayformdata);
        setUpdateButtonState(true);
      }
    }
  }, [response]);

  useEffect(() => {
    if (response2?.status === "200-OK") {
      setUpdateButtonState(false)
      setbuttonimageState(false)
      toast.success(response2?.data?.message)
      navigate("/services")
    }
  }, [response2]);

  const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["clean"],
  ];
  const handledescription = (e) => {
    setFromData({
      ...fromData,
      description: e
    })
  }
  const handleFieldImageChange = (e) => {
    setimage(e.target.files[0]);
  };
  const validate = () => {
    let isError = false;
    let temp = { ...error };
    if (fromData?.name === "") {
      temp.name = "Service name can't be empty"
      isError = true;
    }
    if (description === "") {
      temp.description = "Service description can't be empty"
      isError = true;
    }
    setError(temp);
    return isError;

  }
  let handleSubmit = () => {
    let isError = validate();
    if (!isError) {
      if (image !== "" && typeof image !== 'string') {
        var formData = new FormData();
        formData.append("image_upload", image);
        setimagepayload(formData)
        setbuttonimageState(true)
      }
      else {
        if (serviceId) {
          setUpdateInfoButtonState(true);
          setuploadpayload({ ...fromData, description: description })
        } else {
          let arrayformdata = {
            services: [{ ...fromData, description: description, page_name: "service" }]
          }
          setUpdateButtonState(true)
          setuploadpayload(arrayformdata)
        }
      }
    }
  };
  let handleInput = (e) => {
    setFromData({
      ...fromData,
      name: e.target.value
    })
  }

  return (
    <AdminLayout>
      <>
        {/* {loading && <Loader></Loader>} */}
        <ListHeader />
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <div className="row">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            {mode === "create"
                              ? "Create Service"
                              : "Update Service"}
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          Service information
                        </h6>

                        <div className="pl-lg-4">
                          <div
                            className="card-img-top"
                            style={{
                              borderRadius: "3%",
                              width: "320px",
                              height: "190px",
                              overflow: "hidden",
                              display: "block",
                              margin: "0 auto",
                            }}
                          >
                            <img
                              src={image !== "" ? URL?.createObjectURL(image) : fromData.image !== '' ? baseURL + 'public/serviceUploadFile/' + fromData.image : Placeholder}
                              crossorigin="anonymous"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              alt=""
                            />{" "}
                            <div className="d-flex justify-space-between w-100 align-items-start mt-4"></div>
                          </div>
                          <div className="d-flex justify-content-center p-2">
                            <label className="fileUpload">
                              <input
                                type="file"
                                id="image"
                                name="logo"
                                hidden
                                accept="image/jpeg, image/png, image/jpg"
                                onChange={handleFieldImageChange}
                                multiple={false}
                                style={{
                                  display: "none",
                                }}
                              />
                              <span
                                className="d-flex justify-content-center"
                                style={{
                                  display: "inline-block",
                                  color: "red",
                                }}
                              >
                                {image !== "" ? "Change Image" : "Upload Image"}
                                {/* {(OverviewImage !==""||OverViewImageResponse !=="" ) ? "Change Image":"Upload Image"} */}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Service Name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id="input-first-name"
                                  placeholder="Service name"
                                  type="text"
                                  name="first_name"
                                  autoComplete="nope"
                                  value={fromData?.name}
                                  onChange={(e) => handleInput(e)}
                                />
                                {error?.name ? (
                                  <ErrorMessage errorMsg={error.name} />
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Service Description
                                </label>
                                <ReactQuill
                                  theme="snow"
                                  value={description}
                                  onChange={setDescription}
                                  placeholder="Start writing..."
                                  modules={{
                                    toolbar: {
                                      container: TOOLBAR_OPTIONS,
                                    }
                                  }}
                                />
                                {error?.description ? (
                                  <ErrorMessage errorMsg={error.description} />
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div></div>
                        <hr className="my-4" />
                        <div className="pl-lg-4">
                          <FormGroup className="d-flex justify-content-end">
                            {mode === "update" ? (
                              <Button
                                color="info"
                                className="no-transform"
                                onClick={() => handleSubmit()}
                              >
                                Update
                              </Button>
                            ) : (
                              <Button
                                color="info"
                                className="no-transform"
                                onClick={handleSubmit}
                              >
                                Submit
                              </Button>
                            )}
                            <Button
                              color="warning"
                              className="no-transform"
                              onClick={() => navigate("/services")}
                            >
                              Cancel
                            </Button>
                          </FormGroup>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
        </Container>
      </>
    </AdminLayout>
  );
};

export default CreateService;
