import React, { useState, useEffect } from "react";

// reactstrap components
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Row } from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import Loader from "../../components/Loader/Loader";
import { useQuery } from "react-query";
import axios from "../../Api";
import { Link } from "react-router-dom";

const DashBoard = () => {
 const [role, setrole] = useState(null);
 const [start_date, setstart_date] = useState("");
 const [end_date, setend_date] = useState("");
 const [role_id, setrole_id] = useState("");
 const {data, refetch,isFetching} = useQuery({
  queryKey: ['dashboard'],
    queryFn: async () => {
      const res = await axios.get(`api/dashboard/getDashboardList?start_date=${start_date}&end_date=${end_date}&role_id=${role_id}`)
      return res.data
    },
  }
 )
 useEffect(() => {
  if(role ===null){
    refetch()
  }
 }, [role]);
console.log("data",role);
 const handleInput=async(e)=>{
  if(e.target.value !==""){
    console.log("e",e.target.value);
  let role=data?.data?.allRoleList?.filter((item)=>item?.id==e.target.value)
  setrole(role[0]?.role)
  await setrole_id(e.target.value)
  refetch()
  }
 }
 const handleSubmit=()=>{
  refetch()
 }
 console.log("rol",role);
  return (
    <AdminLayout name="DashBoard">
       {isFetching && <Loader></Loader>} 
      <ListHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="4">
            <Link to="/waiting-approval">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Pending approval
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{data?.data?.allPendingApproval}</span>
                  </div>
                </Row>
              </CardBody>
            </Card>
            </Link>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="4">
          <Link to="/case-management">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Active Cases
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{data?.data?.allCasesCount?.activeCaseCount}</span>
                  </div>
                </Row>
              </CardBody>
            </Card>
            </Link>
          </Col>
          <Col className="mb-5 mb-xl-0 " xl="4">
            <Link to="/case-management">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Closed Cases
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{data?.data?.allCasesCount?.closedCaseCount}</span>
                  </div>
                </Row>
              </CardBody>
            </Card>
            </Link>
           
          </Col>
        </Row>
        <Row className="mt-5">
          
          <Col className="mb-5 mb-xl-0" xl="4">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <Link to="/deposition-management">
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Pending Deposition
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{data?.data?.allDepositionCount?.pendingDepoCount}</span>
                  </div>
                  </Link>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="4">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                <Link to="/deposition-management">
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Confirmed Deposition
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{data?.data?.allDepositionCount?.confirmedDepoCount}</span>
                  </div>
                </Link>
                </Row>
              </CardBody>
            </Card>
          </Col>
           <Col className="mb-5 mb-xl-0" xl="4">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Pending payments
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{data?.data?.allPendingPayments}</span>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0 d-flex align-items-center" xl="6">
           <span className="h2 font-weight-bold mb-0">Select user</span>
           <div className="col-md-5">
                      <select id="input-experience" 
                      name="experience" 
                      autoComplete="nope" 
                      className="form-control-alternative form-control" 
                      fdprocessedid="2aoekh"
                      value={role_id}
                      onChange={(e) => handleInput(e)}
                      >
                        <option value="">Select one</option>
                        {data?.data?.allRoleList?.map((item)=>(
                          <option value={item?.id} key={item?.id}>{item?.role}</option>
                        ))}
                        {/* <option value="Attorney">Attorney</option>
                        <option value="Physician">Physician</option>
                        <option value="Court Reporter">Court Reporter</option> */}
                      </select>
                    </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0 " xl="4">
            <Link 
            to={`${role==="Attorney"?"/lawfirm-management":role==="Physician"?"/physicians-management":
            role==="Court Reporter"?"/courtreporter-management":role==="Expert"?"/expert-management":""}`}
            //to="/lawfirm-management"
            >
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Members
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{data?.data?.allUserList}</span>
                  </div>
                  
                </Row>
              </CardBody>
            </Card>
            </Link>
            
          </Col>
          <Col className="mb-5 mb-xl-0 " xl="4">
            <Link to="/invitation-management">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Pending Invitation
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0">{data?.data?.allPendingInvitation}</span>
                  </div>
                  
                </Row>
              </CardBody>
            </Card>
            </Link>
          </Col>
          </Row>
          {role ==="Attorney" && 
          <>
          <Row className="mt-5">
          <Col className="mb-5 mb-xl-0 d-flex align-items-center" xl="9">
           <div className="col-md-4">
                              <input type="date" 
                              className="form-control" 
                              value={start_date}
                              onChange={(e)=>setstart_date(e.target.value)}
                            //   onFocus={()=>seterrordisplay("")}
                              />
                    </div>
                    <span className="h2 font-weight-bold mb-0">To</span>
           <div className="col-md-4">
            
                              <input type="date" 
                              className="form-control" 
                              value={end_date}
                              onChange={(e)=>setend_date(e.target.value)}
                            //   onFocus={()=>seterrordisplay("")}
                              />
            
                      {/* <select id="input-experience" 
                      name="experience" 
                      autoComplete="nope" 
                      className="form-control-alternative form-control" 
                      fdprocessedid="2aoekh"
                      value={role}
                      onChange={(e) => handleInput(e)}
                      >
                        <option value="Attorney">Attorney</option>
                        <option value="Physician">Physician</option>
                        <option value="Court Reporter">Court Reporter</option>
                      </select> */}
                    </div>
                    <Button
                      className="my-4"
                      color="primary"
                      // color={(start_date===""||end_date==="")?"secondary":"primary"}
                      type="button"
                      disabled={start_date===""||end_date===""}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
          </Col>
        </Row>
          <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="4">
          <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total Call bought
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">{data?.data?.allSingleCallCount?.count}</span>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="mb-5 mb-xl-0" xl="4">
          <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                  >
                    Total Call amount
                  </CardTitle>
                  {data?.data?.singleCallAmount?.rows?.length >1 ?
                  <span className="h2 font-weight-bold mb-0">{data?.data?.allSingleCallCount?.count * data?.data?.singleCallAmount?.rows[0]?.price}</span>:
                  <span className="h2 font-weight-bold mb-0">0</span>
                }
                  
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
        </Row>
        </>
          }
          
        
      </Container>
    </AdminLayout>
  );
};

export default DashBoard;
