import React, {useState} from 'react'
import { Container, Row, Col } from 'reactstrap'
import { CiSearch } from "react-icons/ci";
import DropdownCustom from '../../../components/Buttons/DropdownCustom';
import { dataTable } from './DataTable';
import RechargeTransactionTable from './RechargeTransactionTable';


const RechargeTable = () => {
    const [search, setSearch] = useState();
    const [optionData, setOptionData] = useState();

    const handleChange = (e) => {
        setSearch(e.target.value);
    }

    const options = ['Latest', 'Low to High', 'High to Low'];
    const filterOption = ['Status-all', 'Status'];

    const handleSelect = (e) => {
        setOptionData(e.target.value)
    };

  return (
    <Container className='dashboard-table mt-4 px-0'>
        <Row>
            <Col md={6} className='mt-3'>
                <div className='search-group d-flex align-items-center'>
                    <CiSearch />
                    <input className='p-2' placeholder='Search' type='text' name='search' value={search} onChange={handleChange}/>
                </div>
            </Col>
            <Col md={3} className='px-0'>
                <div className='sort d-flex align-items-center'>
                    <img src='../assets/sort.svg' />
                    <label htmlFor='sort' className='mb-0 pl-2'>Sort By:</label>
                    <DropdownCustom 
                        className='dashboard-drop dark-text pl-2'
                        name="sort"
                        title=""
                        isRequired = {false}
                        options={options}
                        onSelect={handleSelect}
                        defaultOption='Latest'
                        value={optionData}
                    />
                </div>
            </Col>
            <Col md={3} className='px-0'>
                <div className='sort filter d-flex align-items-center'>
                    <img src='../assets/filter.svg' />
                    <label htmlFor='sort' className='mb-0 pl-2'>Filter By:</label>
                    <DropdownCustom 
                        className='dashboard-drop dark-text pl-2'
                        name="sort"
                        title=""
                        isRequired = {false}
                        options={filterOption}
                        onSelect={handleSelect}
                        defaultOption='Status-all'
                        value={optionData}
                    />
                </div>
            </Col>
            <Col md={12} className=''>
                <RechargeTransactionTable tableData={dataTable} />
            </Col>
        </Row>
    </Container>
  )
}

export default RechargeTable