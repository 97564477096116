import React from 'react'
import ButtonComponent from '../../components/Buttons/ButtonSubmit'
import { Container, Row, Col } from 'reactstrap'

const Userbar = () => {

    const handleClick = () => {
        /// handle click
    }

  return (
    <Container className='user-bar p-3'>
        <Row className=''>
            <Col md={12} className=''>
                <p className='title mb-0'>Hello <span>Daniel !!!</span> </p>
            </Col>
        </Row>
        <Row className='d-flex justiry-content-between'>
            <Col md={6} className='d-flex mt-2'>
                <div className='item'>
                    <p className='title mb-0'>Phone</p>
                    <p className='text mb-0'>+1 702-414-1000</p>
                </div>
                <div className='item'>
                    <p className='title mb-0'>Email</p>
                    <p className='text mb-0 email'>daniel@gmail.com</p>
                </div>
                <div className='item'>
                    <p className='title mb-0'>Account type</p>
                    <p className='text mb-0'>Normal</p>
                </div>
            </Col>
            <Col md={6} className='buttons d-flex justify-content-end'>
                <ButtonComponent
                    onClick={handleClick}
                    disabled={false}
                    className = 'orange-border thick-border'
                    btnType = 'border'
                >
                    Recharge
                    <i className="ml-3 fa fa-regular fa-angle-right"></i>
                </ButtonComponent>

                <ButtonComponent
                    onClick={handleClick}
                    disabled={false}
                    className = 'orange-border thick-border'
                    btnType = 'no-border'
                >
                    Send Money
                    <i className="ml-3 fa fa-regular fa-angle-right ml-2"></i>
                </ButtonComponent>
            </Col>
        </Row>
    </Container>
  )
}

export default Userbar