import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import ConfirmationDialog from "../../components/confirmation.dialog";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import AdminLayout from "../../layouts/AdminLayout";
import useConditiongetService from "../../services/conditiongetService";
import useDeleteService from "../../services/deleteservice";
import useGetService from "../../services/getservice";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import SingleSelect from '../../components/Select/SingleSelect';
import Loader from "../../components/Loader/Loader";
import { DateFormat, getFormattedDate, hoursbetween } from "../../helpers/utils";
import useDebounce from "../../components/custom-hooks/useDebounce";
import moment from "moment";

const DepositionManagmentList = () => {
  const [depositionList, setdepositionList] = useState([]);
  const navigate = useNavigate()
  let [userModal, setUserModal] = useState(false);
  const [addAttorney, setAddAttorney] = useState({
    first_name: "",
    last_name: "",
    phone: 0,
    email: "",
    phone: "",
    role: 0
  });

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [getListData, setListData] = useState(false);

  const [sortName, setSortName] = useState("start_time");
  const [totalPage, settotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [asc, setAsc] = useState("desc");

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    created_by: 1,
    phone: "",
  });

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  let debouncedSearchTerm = useDebounce(searchInput, 500);
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
  };
  useEffect(() => {
    setListData(true)
    setPage(1)
    setSortName("start_time")
    setAsc("desc")
  }, [debouncedSearchTerm]);



  const { response2, isFetchItems } = useConditiongetService(
    `api/depo/getDepoCallList?sort_name=${sortName}&sort_order=${asc}&search=${searchInput}&size=20&page=${page}`,
    getListData
  );
  // const { response, refetch } = useGetService(`api/attendee/getAttorneys`);


  console.log("response2", response2);


  useEffect(() => {
    if (response2.status === "200-OK" && getListData) {
      setdepositionList(response2?.data?.data)
      setListData(false);
      settotalPage(response2?.data?.totalPages)
    }
    if (response2.status === "202-Accepted" && getListData) {
      setdepositionList(response2?.data?.data?.rows)
      setListData(false)
      settotalPage(0)
    }
  }, [response2]);


  const handlesort = (sort, condition) => {
    setAsc(condition)
    setSortName(sort)
    setListData(true)
  }
  const onPageChange = (value) => {
    setPage(value)
    setListData(true)
  }

  return (
    <AdminLayout name="Deposition Management">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />

      <ConfirmationDialog
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
        action={"Delete"}
        onClick={() => {
          setConfirmationModal(false);
          //   handleDelete();
        }}
      ></ConfirmationDialog>


      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-4">
                    <h3 className="mb-0">Depositions List</h3>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-md-8 align-right"
                  >
                    <div
                      className="mb-0 form-group inline"
                      style={{
                        width: "40%",
                        display: "inline-block",
                        marginRight: "15px",
                      }}
                    >
                      <div className="input-group-alternative input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                        <input
                          placeholder="Search"
                          type="text"
                          className="form-control"
                          onChange={(e) => searchItems(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{
                  marginBottom: "25px",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      Case Name
                      <span>
                        <i className="fa fa-fw fa-sort-down"
                          onClick={() => handlesort("case_name", "desc")}
                          style={{
                            color: sortName === "case_name" && asc === "desc" ? 'green' : null,
                            width: "0.5em",
                            cursor: "pointer"
                          }}
                        ></i>
                        <i className="fa fa-fw fa-sort-up"
                          onClick={() => handlesort("case_name", "asc")}
                          style={{
                            color: sortName === "case_name" && asc === "asc" ? 'green' : null,
                            width: "0.5em",
                            cursor: "pointer"
                          }}
                        ></i>
                      </span>
                    </th>
                    <th scope="col">
                      Deponent Name
                      <span>
                        <i className="fa fa-fw fa-sort-down"
                          onClick={() => handlesort("deponent_name", "desc")}
                          style={{
                            color: sortName === "deponent_name" && asc === "desc" ? 'green' : null,
                            width: "0.5em",
                            cursor: "pointer"
                          }}
                        ></i>
                        <i className="fa fa-fw fa-sort-up"
                          onClick={() => handlesort("deponent_name", "asc")}
                          style={{
                            color: sortName === "deponent_name" && asc === "asc" ? 'green' : null,
                            width: "0.5em",
                            cursor: "pointer"
                          }}
                        ></i>
                      </span>
                    </th>
                    <th scope="col">
                      Attendees Email
                    </th>
                    <th scope="col">
                      Start time
                      <span>
                        <i className="fa fa-fw fa-sort-down"
                          onClick={() => handlesort("start_time", "desc")}
                          style={{
                            color: sortName === "start_time" && asc === "desc" ? 'green' : null,
                            width: "0.5em",
                            cursor: "pointer"
                          }}
                        ></i>
                        <i className="fa fa-fw fa-sort-up"
                          onClick={() => handlesort("start_time", "asc")}
                          style={{
                            color: sortName === "start_time" && asc === "asc" ? 'green' : null,
                            width: "0.5em",
                            cursor: "pointer"
                          }}
                        ></i>
                      </span>
                    </th>
                    {/* <th scope="col">
                      Time(hr)
                    </th> */}
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col" ></th>
                  </tr>
                </thead>
                <tbody>
                  {depositionList?.length > 0 ? depositionList?.map((item, i) => {
                    let caseName = item?.case?.case_name ? item?.case?.case_name?.toLowerCase()?.split(' vs ') : '--';
                    return (
                      <tr key={i}>
                        <td>{typeof caseName == 'string' ? caseName : <>{caseName[0]}
                          <span className="d-block">vs</span>
                          {caseName[1]}</>}</td>
                        <td>{item?.deponent_name ? item?.deponent_name : '--'}</td>
                        <td>{item?.case_invitees?.length > 0 && <div>{item?.case_invitees[0]?.email}
                          <span className="pl-1">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                +{item?.case_invitees?.length - 1}
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" container="body">
                                {item?.case_invitees?.map((item2, i2) => (
                                  <DropdownItem key={i2}
                                  >
                                    {item2.email}
                                  </DropdownItem>
                                ))}

                              </DropdownMenu>
                            </UncontrolledDropdown>

                          </span></div>}</td>
                          <td>
                            {item?.start_time
                              ? moment(
                                DateFormat(item?.start_time)
                              ).format('MM/DD/yyyy H:mm')
                              : '--'}
                          </td>
                        {/* <td>{getFormattedDate(item?.start_time)}</td> */}
                        {/* <td>{hoursbetween(item?.end_time,item?.start_time).toFixed(2)}</td> */}
                        <td>{item?.status == "1"
                          ? "Pending"
                          : item?.status == "2"
                            ? "Scheduled"
                            : item?.status == "3"
                              ? "Completed"
                              : item?.status == "4"
                                ? 'Canceled with refund'
                                : item?.status == "0"
                                  ? 'Canceled without refund'
                                  : item?.status == "5"
                                    ? 'Zoom link'
                                    : "Zoom link incomplete"}</td>
                        <td className="text-right">
                          {(parseInt(item?.status) === 2 || parseInt(item?.status) === 1 || parseInt(item?.status) === 3) &&
                            <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" container="body">
                              {(parseInt(item?.status) === 2 || parseInt(item?.status) === 1) &&
                               <DropdownItem
                               onClick={() => navigate(`/deposition-management/${item?.id}`)}
                             >
                               Edit
                             </DropdownItem>
                              }
                              
                              {parseInt(item?.status) === 3 &&
                                <DropdownItem
                                >
                                  <Link href={item?.call_download_url ? item?.call_download_url : ''} className="text-dark" target="_blank">
                                    Download Record
                                  </Link>
                                </DropdownItem>
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          }
                         
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td>
                        Sorry no deposition found
                      </td>

                    </tr>
                  }
                </tbody>

              </Table>
              <CardFooter className="py-4 d-flex justify-content-end">
                {totalPage > 1 ?
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        // className="disabled"
                        className={page < 2 ? "disabled" : ""}
                      >
                        <PaginationLink
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page - 2);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPage)].map((_, i) => {
                        return (
                          <PaginationItem
                            key={i}
                            className={page === i + 1 ? "active" : ""}
                          >
                            <PaginationLink
                              onClick={(e) => {
                                e.preventDefault();
                                onPageChange(i + 1);
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      <PaginationItem
                        // className="disabled"
                        className={page + 2 > totalPage ? "disabled" : ""}
                      >
                        <PaginationLink
                          onClick={(e) => {
                            e.preventDefault();
                            onPageChange(page + 2);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                  : null}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default DepositionManagmentList;
