import React from "react";
import "react-toastify/dist/ReactToastify.css";
// reactstrap components
import { Modal, Button, FormGroup } from "reactstrap";
// core components
const ConfirmationDialog = ({ action, isOpen, toggle, onClick, dataType }) => {
  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} style={{ width: '30.0rem' }}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" style={{ textTransform: 'capitalize' }}>
            {action} Confimation
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>
            Are you sure you want to {action}{" "}
            {action === "Delete" || action === "update"
              ? `this ${dataType || "account"}`
              : `for this ${dataType || "account"}`}
            ?
          </h5>

          <FormGroup className="d-flex justify-content-end">
            <Button
              color="info"
              className="no-transform"
              size="sm"
              onClick={onClick}
            >
              Yes
            </Button>
            <Button
              color="warning"
              className="no-transform"
              size="sm"
              onClick={toggle}
            >
              No
            </Button>
          </FormGroup>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationDialog;
