import React, { useState,useEffect } from "react";
import { Button, Modal, ModalFooter } from "reactstrap";
import Placeholder from "../../assets/images/team-4.jpg";
import useModalPostService from "../../services/modaPostlService";
import { toast } from "react-toastify";
import useModalGetService from "../../services/modalgetService";
import { imagePath } from "../../config/filepath";
import Loader from "../Loader/Loader";
const CategoryStep2Modal = ({ isOpen, toggle,categoryId }) => {
  const [buttonImageState, setbuttonImageState] = useState(false);
  const [imagePayload, setimagePayload] = useState("");
  const [iconPayload, seticonPayload] = useState("");
  const [buttonIconState, setbuttonIconState] = useState(false);
  const [apicall, setapicall] = useState(false);
  const [Image, setImage] = useState("");
  const [Icon, setIcon] = useState("");
  const [ImageResponse, setImageResponse] = useState("");
  const [IconResponse, setIconResponse] = useState("");
  let response3=useModalPostService(imagePayload,`api/category/uploadImage/${categoryId}/image`,buttonImageState);
  let response4=useModalPostService(iconPayload,`api/category/uploadImage/${categoryId}/icon`,buttonIconState);
  let {response5,isFetchItems}=useModalGetService(`api/category/getPicturePathByCategory/${categoryId}`);
  console.log("imageResponse",ImageResponse);
  console.log("iconResponse",IconResponse);
  console.log("respon",response5);
  useEffect(() => {
    if(response5?.status ==="200-OK"){
        if(response5?.data?.iconPath !==null){
            setIconResponse(response5?.data?.iconPath)
        }
        if(response5?.data?.imagePath !==null){
          setImageResponse(response5?.data?.imagePath)
        }
    }
  }, [response5]);
  const handleClose=()=>{
    setImageResponse("")
    setIconResponse("")
    setImage("")
    setIcon("")
    toggle()
    setapicall(false)
 }
      useEffect(() => {
        if (response3?.status ==="200-OK" && buttonImageState)
        {
         if(Icon ==="" ){
          toast.success(response3?.data?.message)
          handleClose()
          setbuttonImageState(false)
         }
         else {
            setbuttonImageState(false)
     
            setbuttonIconState(true)
         }
         
        }
      }, [response3]);
      useEffect(() => {
        if (response4?.status ==="200-OK" && buttonIconState)
        {
         toast.success(response4?.data?.message);
         setbuttonIconState(false)
          handleClose()
        }
      }, [response4]);

  const handleFieldImageChange = (e) => {
    var formData = new FormData();
    formData.append("image",e.target.files[0]) ;
    setimagePayload(formData)
    setImage(e.target.files[0]);
    setImageResponse("")
  };
  const handleFieldIconChange = (e) => {
    setIcon(e.target.files[0]);
    var formData = new FormData();
    formData.append("image", e.target.files[0]);
    seticonPayload(formData)
    setIconResponse("")
  };
  const handleSave=()=>{
   
   if(Image ==="" && Icon !==""){
    setapicall(true)
    setbuttonIconState(true);
   }
   if((Image !=="" || Icon !=="") && !buttonIconState  ){
    setapicall(true)
    setbuttonImageState(true);
}
  }

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={handleClose}>
        {isFetchItems && <Loader/>}
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add image and icon of Category
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-center">Upload Image</div>
        <div className="d-flex justify-content-center align-items-center">
        <div
          className="card-img-top"
          style={{
            borderRadius: "50%",
            width: "151px",
            height: "151px",
            overflow: "hidden",
            display: "block",
            margin: "0 auto",
          }}
        >
          <img
            src={ Image !== "" ? URL?.createObjectURL(Image) :
            ImageResponse !== ""
              ? imagePath+ImageResponse
            :Placeholder }
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            // src={Placeholder}
            alt=""
          />{" "}
          <div className="d-flex justify-space-between w-100 align-items-start mt-4"></div>
        </div>
        <div className="d-flex justify-content-center">
          <label className="fileUpload">
            <input
              type="file"
              id="image"
              name="logo"
              hidden
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFieldImageChange}
              multiple={false}
              style={{
                display: "none",
              }}
            />
            <span
              className="d-flex justify-content-center"
              style={{ display: "inline-block", color: "red" }}
            >
                {(Image !==""||ImageResponse !=="" )? "Change Image":"Upload Image"}
            
            </span>
          </label>
        </div>
        </div>
       
        <div className="d-flex justify-content-center">Upload Icon</div>
        <div className="d-flex justify-content-center align-items-center">
        <div
          className="card-img-top"
          style={{
            borderRadius: "50%",
            width: "151px",
            height: "151px",
            overflow: "hidden",
            display: "block",
            margin: "0 auto",
          }}
        >
          <img
            src={Icon !== "" ? URL?.createObjectURL(Icon) : 
            IconResponse !== ""
              ? imagePath+IconResponse
            :
            Placeholder}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            // src={Placeholder}
            alt=""
          />{" "}
          <div className="d-flex justify-space-between w-100 align-items-start mt-4"></div>
        </div>
        <div className="d-flex justify-content-center">
          <label className="fileUpload">
            <input
              type="file"
              id="image"
              name="logo"
              hidden
              accept="image/jpeg, image/png, image/jpg"
              onChange={handleFieldIconChange}
              multiple={false}
              style={{
                display: "none",
              }}
            />
            <span
              className="d-flex justify-content-center"
              style={{ display: "inline-block", color: "red" }}
            >
                {(Icon !==""||IconResponse !=="" ) ? "Change Icon":"Upload Icon"}
            
            </span>
          </label>
        </div>
        </div>
       
      </div>
      <hr/>
      <ModalFooter>
        <Button
          color="info"
          className="no-transform "
            disabled={(ImageResponse!==""&&IconResponse !=="") || (Icon ==="" && IconResponse ==="") || (Image ==="" && ImageResponse ==="") ||apicall}
            onClick={handleSave}
        >
          Save
        </Button>
        <Button
          color="warning"
          className="no-transform "
            onClick={handleClose}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CategoryStep2Modal;
