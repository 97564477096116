import React,{useState,useEffect} from 'react'
import { useMutation } from 'react-query';
import axios from '../Api';
const usePostService = (data,api,buttonState) => {
    const [response, setresponse] = useState("");
    const { isLoading: isPostingTutorial, mutate: post } = useMutation(
        async (data) => {
          return await axios.post(`${api}`, 
          data
          );
        },
        {
          onSuccess: (res) => {
            const result = {
              status: res.status + "-" + res.statusText,
              headers: res.headers,
              data: res.data,
            };
            setresponse(result)
            // setPostResult(fortmatResponse(result));
          },
          onError: (err) => {
            setresponse(err.response)
            // setPostResult(fortmatResponse(err.response?.data || err));
          },
        }
      );
      useEffect(() => {
        if (buttonState !==false) {
         post(data)
        }
      }, [buttonState]);
  return response
}

export default usePostService