import axiosInstance from "../../Api";
import axiosInstanceClient from "../../Api/client";
import { baseURL } from "../../config/filepath";

const GetDashboard = (payload) => {
    return axiosInstanceClient.post(baseURL + "client/get-dashboard-data" , payload)
    .then((response) => {
        return response;
    })
};


const Gettransaction = (payload) => {
    return axiosInstanceClient.post(baseURL + "client/get-transactions-for-dashboard" , payload)
    .then((response) => {
        return response;
    })
};


export default {GetDashboard ,Gettransaction}