import React from 'react';
import { FaCheck } from 'react-icons/fa';

const CheckBox = ({ checkName, id, label, checked, onChange, className }) => {
  return (
    <>
    <div className={`custom-checkbox ${className}`}>
      <input
        name={checkName}
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="checkbox-input"
      />
      <label htmlFor={id} className="custom-checkbox-box mx-0">
        {checked && <FaCheck className="checkbox-icon" />}
      </label>
      <span className="checkbox-label mx-3">{label}</span>
    </div>
    </>
  );
};

export default CheckBox;
