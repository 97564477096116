import { useQueries } from 'react-query';
import axios from '../Api';
const useParallelServiceConditionally = (apis, getbutton) => {

    const fetchdata = async (api) => {
        return await axios.get(`${api}`)
    }

    let Queries = getbutton ? apis.map((api) => ({
        queryKey: ['location', api],
        queryFn: () => fetchdata(api)
    })) : []

    const queryResults = useQueries(Queries);
    let isOk = queryResults?.every((query) => query.isSuccess);
    return isOk && queryResults?.map(obj => obj?.isSuccess && obj?.data?.data);
}

export default useParallelServiceConditionally;