import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import useConditiongetService from "../../services/conditiongetService";
const AnalyticsReportsList = () => {
  const [alldata, setAlldata] = useState([]);
  const [getbutton, setgetbutton] = useState(false);
  const [deleteid, setdeleteid] = useState("");
  const [buttonState, setbuttonState] = useState(true);
  const [condition, setcondition] = useState("saved");
  const [page,setPage]=useState(1)
  const navigate=useNavigate();
  let {response2:{response},isLoadingProducts,isFetchItems}=useConditiongetService(`api/category/getAllTopCategories?mode=${condition}&page=${page}`,buttonState);
  let {response2}=useConditiongetService(`api/category/deleteTopParentId/${deleteid}`,getbutton);
  useEffect(() => {
    if (response2?.status ==="200-OK" &&buttonState){
      // console.warn("bad")
     setAlldata(response2?.data?.list)
     setbuttonState(false)
    }
  }, [response2]);
  console.log("cond",condition);
  const handleInput = async(e) => {
    setcondition(e.target.value)
    setbuttonState(true)
    // setSearchText("")
    // setCurrentPage(1)
    // await listUsersLogsData(0,10,"",e.target.value)
  };
  console.log("delete",alldata)
  useEffect(() => {
    if(response2 !=="" && getbutton){
     setAlldata(response2.data?.remainingTopParentCategories)
     setgetbutton(false)
    }
  }, [response2]);
  const handleDelete=(i)=>{
   setdeleteid(i)
   setgetbutton(true)
  }
  console.log("respon",response)
  return (
    <AdminLayout name="analytics-reports">
      {isFetchItems && <Loader></Loader>}
      <ListHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
          </div>
        </Row>
      </Container>
      
    </AdminLayout>
   
  );
};

export default AnalyticsReportsList;
