import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import lblErr from "../../language/CreateUpdateErr";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import usePostService from "../../services/postservice";
import usePutService from "../../services/putservice";
import useGetService from "../../services/getservice";
import Placeholder from "../../assets/images/team-4.jpg";
import Loader from "../Loader/Loader";
import { baseURL } from "../../config/filepath";
// import TextField from "../../components/TextField/TextField";
const UpdateBanner = () => {
  let { bannerId } = useParams();
  const navigate = useNavigate();
  const [image, setimage] = useState("");
  const [imagepayload, setimagepayload] = useState("");
  const [buttonimageState, setbuttonimageState] = useState(false);
  const [buttonapi, setbuttonapi] = useState(false);
  const [apipayload, setapipayload] = useState("");
  const [error, seterror] = useState("");
  let { response, isFetchItems } = useGetService(`/api/admin/getBannerById/${bannerId}`);
  let response2 = usePostService(imagepayload, "/api/imageUpload/banner_upload", buttonimageState);
  let response3 = usePostService(apipayload, "/api/admin/createBanner", buttonapi);
  const handleFieldImageChange = (e) => {
    let filename = e.target.files[0]?.name;
    if (!(filename.includes("jpg", "png", "jpeg") || filename.includes("png") || filename.includes("jpeg"))) {
      seterror('You can only add photo with jpg, png and jpeg extension');
      return 0;
    }
    setimage(e.target.files[0]);
    seterror('');
  }
  const handleupdate = () => {
    var formData = new FormData();
    formData.append("image_upload", image);
    setimagepayload(formData)
    setbuttonimageState(true)
  }
  useEffect(() => {
    if (response2?.status === "200-OK") {
      setbuttonapi(true)
      setbuttonimageState(false)
      let payload = {
        id: bannerId,
        image: response2?.data?.fileData[0]?.file_name
      }
      setapipayload(payload)
    }
  }, [response2]);
  useEffect(() => {
    if (response3?.status === "200-OK") {
      setbuttonapi(false)
      toast.success(response3?.data?.message)
      navigate("/banner-management")
      setapipayload('')
      setimagepayload('')
    }
  }, [response3]);
  
  return (
    <AdminLayout>
      <>
        {isFetchItems && <Loader></Loader>}
        <ListHeader />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col" >
              <div className="row">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            {/* {(mode === 'create') ? 'Create New Content' : 'Update Content'} */}
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          {response?.data?.data?.page_name?.slice(0, -7)} Banner
                        </h6>

                        <div className="pl-lg-4">
                          <div
                            className="card-img-top"
                            style={{
                              borderRadius: "3%",
                              width: "720px",
                              height: "190px",
                              overflow: "hidden",
                              display: "block",
                              margin: "0 auto",
                            }}
                          >
                            <img
                              src={image !== "" ? URL?.createObjectURL(image)
                                : response?.data?.data?.image !== '' ? baseURL + 'public/bannerUploadFile/' +
                                  response?.data?.data?.image : Placeholder}
                              crossorigin="anonymous"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              alt=""
                            />{" "}
                            <div className="d-flex justify-space-between w-100 align-items-start mt-4"></div>
                          </div>
                          <div className="d-flex justify-content-center p-2">
                            <label className="fileUpload">
                              <input
                                type="file"
                                id="image"
                                name="logo"
                                hidden
                                accept="image/jpeg, image/png, image/jpg"
                                onChange={handleFieldImageChange}
                                multiple={false}
                                style={{
                                  display: "none",
                                }}
                              />
                              <span
                                className="d-flex justify-content-center"
                                style={{
                                  display: "inline-block",
                                  color: "red",
                                  cursor: "pointer"
                                }}
                              >
                                {(image !== "" || response?.data?.data?.image !== '') ? "Change Image" : "Upload Image"}
                              </span>
                            </label>
                          </div>

                          {error !== '' && <span
                            className="d-flex justify-content-center"
                            style={{
                              display: "inline-block",
                              color: "red",
                              cursor: "pointer"
                            }}
                          >
                            {error}
                          </span>}
                          <span
                            className="d-flex ml-10"
                            style={{
                              display: "inline-block",
                              color: "black",
                              cursor: "pointer",
                              paddingLeft:"10px"
                            }}
                          >
                           Note:{response?.data?.data?.page_name?.slice(0, -7) !=="home"?"Image resolution will be (1366✖250) pixels":
                           "Image resolution will be (1366✖680) pixels"}
                          </span>
                        </div>
                        <hr className="my-4" />
                        <div className="pl-lg-4">
                          <FormGroup className="d-flex justify-content-end">
                            {
                              <Button
                                color="info"
                                className="no-transform"
                                disabled={image === ""}
                                onClick={handleupdate}
                              >
                                Update
                              </Button>

                            }
                            <Button
                              color="warning"
                              className="no-transform"
                              onClick={() => navigate("/banner-management")}
                            >
                              Cancel
                            </Button>
                          </FormGroup>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
        </Container>
      </>
    </AdminLayout>
  );
};

export default UpdateBanner;