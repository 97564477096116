import React,{useState,useEffect} from 'react'
import axios from '../Api';
import { useQuery } from 'react-query';
const useModalGetService = (api) => {
    const [response5, setresponse5] = useState("");
    const { isLoading: isLoadingProducts,refetch: getresponse,isFetching:isFetchItems } = useQuery(
        "modal-get",
        async () => {
          return await axios.get(api);
        },
        {
          enabled: false,
          onSuccess: (res) => {
            const result = {
              status: res.status + "-" + res.statusText,
              headers: res.headers,
              data: res.data,
            };
            // setGetResult(fortmatResponse(result));
            setresponse5(result)
            // setAllProducts(result?.data)
          },
          onError: (err) => {
            // setGetResult(fortmatResponse(err.response?.data || err));
          },
        }
      );
      useEffect(() => {
        getresponse()
      }, []);
    return {response5,isLoadingProducts,isFetchItems}
}

export default useModalGetService