import React, { useState } from 'react';
import Recharge from './Recharge';
import RechargeHistory from './RechargeHistory';
import { Col, Container, Row } from 'reactstrap';


const RechargeAccount = () => {
    const [activeTab, setActiveTab] = useState('recharge');
    const renderContent = () => {
        switch (activeTab) {
            case 'recharge':
                return <Recharge />;
            case 'history':
                return <RechargeHistory />;
            default:
                return <Recharge />;
        }
    };

    return (
      <Container className='recharge-account'>
        <Row>
          <Col md={12} className='px-0'>
            <div>
              <div className='tabs d-flex align-items-center px-0'>
                  <button onClick={() => setActiveTab('recharge')} 
                          className={`tab p-0 pb-3 mr-4 ${activeTab === 'recharge' ? 'active' : ''}`}>
                      Recharge
                  </button>
                  <button 
                          onClick={() => setActiveTab('history')} 
                          className={`tab p-0 pb-3 ${activeTab === 'history' ? 'active' : ''}`}>
                      Recharge History
                  </button>
              </div>
              <div className='tab-content'>
                  {renderContent()}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
};

export default RechargeAccount;
