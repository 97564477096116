import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Modal,
} from "reactstrap";
// core components
// core components
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import Loader from '../../components/Loader/Loader';
import useConditiongetService from "../../services/conditiongetService";
import useDebounce from "../../components/custom-hooks/useDebounce";
import moment from "moment";
import { DateFormat } from "../../helpers/utils";
const WaitingApprovalList = () => {
    const navigate = useNavigate();
    let [userModal, setUserModal] = useState(false);
    let [waitingList, setWaitingList] = useState([]);

    let [searchInput, setSearchInput] = useState('');

    const [totalPage, settotalPage] = useState(0);
    const [page, setPage] = useState(1);

    const [idSortOrder, setIdSortOrder] = useState('asc');
    const [firstNameSortOrder, setFirstNameSortOrder] = useState('asc');
    const [emailSortOrder, setEmailSortOrder] = useState('asc');
    const [phoneSortOrder, setPhoneSortOrder] = useState('asc');

    const [sortName, setSortName] = useState('createdAt');
    const [finalsortOrder, setFinalSortOrder] = useState('');

    const [conditionButton, setConditionButton] = useState(false);

    const [selectedWaiting, setSelectedWaiting] = useState(null);

    let viewUser = (value) => {
        setSelectedWaiting(value);
        setUserModal(true);
    }
    const debouncedSearchTerm = useDebounce(searchInput, 500);

    let { response2, isFetchItems } = useConditiongetService(`/api/user/getAllPendingUsers?sort_name=${sortName}&search=${debouncedSearchTerm}&sort_order=${finalsortOrder}&size=20&page=${page}`, conditionButton);


    useEffect(() => {
        if (debouncedSearchTerm !== "") {
            setConditionButton(true);
            setSearchInput(debouncedSearchTerm);
        }
        else if (debouncedSearchTerm === "" && searchInput === "") {
            setConditionButton(true);
            setSearchInput("");
        }
    }, [
        debouncedSearchTerm
    ]);

    useEffect(() => {
        if (response2.status === "200-OK" && conditionButton) {
            setWaitingList(response2?.data?.data);
            settotalPage(response2?.data?.totalPages);
            setConditionButton(false);
        }
    }, [response2]);

    const onSearchChange = (searchValue) => setSearchInput(searchValue);

    const sortItemHandler = (name, direction) => {
        setFinalSortOrder(direction);
        setSortName(name);
        setConditionButton(true);
    }

    const onPageChange = (value) => {
        setPage(value);
        setConditionButton(true);
    }

    return (
        <AdminLayout name="Waiting For Approval">
            {isFetchItems && <Loader></Loader>}
            <ListHeader />
            {/* <ToastContainer /> */}
            {/* <ConfirmationDialog
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
        action={"Delete"}
        onClick={() => handleDelete(selected_id)}
      ></ConfirmationDialog> */}
            {/* <ConfirmationDialog
        isOpen={reset2faModal}
        toggle={() => setReset2faModal(false)}
        action={"Reset 2FA"}
        onClick={() => handleReset2faSubmit(selected_id)}
      ></ConfirmationDialog>
      <ConfirmationDialog
        isOpen={resetModal}
        toggle={() => setResetModal(false)}
        action={"Reset Password"}
        onClick={() => handleResetSubmit(selected_id)}
      ></ConfirmationDialog> */}
            <Modal
                className="modal-dialog-centered"
                isOpen={userModal}
                toggle={() => setUserModal(false)}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Waiting For Approval Information
                    </h5>
                    {console.log("s",selectedWaiting?.npi_number)}
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setUserModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Table className="align-items-center" responsive>
                        <tbody>
                            <tr>
                                <th scope="col">Name</th>
                                <td>{selectedWaiting?.first_name + ' ' + selectedWaiting?.last_name}</td>
                            </tr>
                            <tr>
                                <th scope="col">Email</th>
                                <td>{selectedWaiting?.email}</td>
                            </tr>
                            <tr>
                                <th scope="col">Phone</th>
                                <td>{selectedWaiting?.phone || '--'}</td>
                            </tr>
                            {selectedWaiting?.npi_number !==null &&
                            <tr>
                                <th scope="col">NPI number</th>
                                <td>
                                    {selectedWaiting?.npi_number}
                                </td>
                            </tr>}
                            <tr>
                                <th scope="col">Status</th>
                                <td>{selectedWaiting?.is_approved === 0 ? 'Rejected' : selectedWaiting?.is_approved === 1 ? 'Approved' : 'Pending'}</td>
                            </tr>
                            <tr>
                                <th scope="col">Role</th>
                                <td>
                                    {selectedWaiting?.role?.role}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Modal>
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h3 className="mb-0">Waiting For Approval List</h3>
                                    </div>
                                    <div
                                        style={{ textAlign: "right" }}
                                        className="col-md-4 align-right"
                                    >
                                        <div
                                            className="mb-0 form-group inline"
                                            style={{
                                                width: "80%",
                                                display: "inline-block",
                                                marginRight: "15px",
                                            }}
                                        >
                                            <div className="input-group-alternative input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-search"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    placeholder="Search"
                                                    type="text"
                                                    className="form-control"
                                                    value={searchInput}
                                                    onChange={(e) => onSearchChange(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <UncontrolledDropdown className="inline">
                                            <DropdownToggle
                                                style={{ display: "none" }}
                                                className="btn-icon-only text-dark"
                                                href="#pablo"
                                                role="button"
                                                size="sm"
                                                color=""
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <i className="fas fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                    style={{ display: "none" }}
                                                >
                                                    Make Active
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                    style={{ display: "none" }}
                                                >
                                                    Make Inactive
                                                </DropdownItem>

                                                <DropdownItem
                                                    style={{ display: "none" }}
                                                    href="javascript:void(0)"
                                                    onClick={() => navigate("/create-role-management")}
                                                >
                                                    Add New Role
                                                </DropdownItem>

                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                    style={{ display: "none" }}
                                                >
                                                    Delete
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive style={{
                                marginBottom: '25px'
                            }}>
                                <thead className="thead-light">
                                    <tr>
                                        {/* <th scope="col">ID {idSortOrder === 'asc' ?
                                            <i className="fa fa-fw fa-sort" onClick={() => {
                                                sortItemHandler('id', 'asc');
                                                setIdSortOrder('desc')
                                            }}></i>
                                            :
                                            <i className="fa fa-fw fa-sort" onClick={() => {
                                                sortItemHandler('id', 'desc');
                                                setIdSortOrder('asc')
                                            }}>
                                            </i>}</th> */}
                                        <th scope="col">Name {firstNameSortOrder === 'asc' ?
                                            <i className="fa fa-fw fa-sort" onClick={() => {
                                                sortItemHandler('first_name', 'asc');
                                                setFirstNameSortOrder('desc')
                                            }}></i>
                                            :
                                            <i className="fa fa-fw fa-sort" onClick={() => {
                                                sortItemHandler('first_name', 'desc');
                                                setFirstNameSortOrder('asc')
                                            }}>
                                            </i>}</th>
                                        <th scope="col">Email {emailSortOrder === 'asc' ?
                                            <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('email', 'asc'); setEmailSortOrder('desc') }}></i>
                                            :
                                            <i className="fa fa-fw fa-sort" onClick={() => { sortItemHandler('email', 'desc'); setEmailSortOrder('asc') }}></i>}</th>
                                        <th scope="col">Phone No</th>
                                        <th scope="col">Role {firstNameSortOrder === 'asc' ?
                                            <i className="fa fa-fw fa-sort" onClick={() => {
                                                sortItemHandler('role_id', 'asc');
                                                setFirstNameSortOrder('desc')
                                            }}></i>
                                            :
                                            <i className="fa fa-fw fa-sort" onClick={() => {
                                                sortItemHandler('role_id', 'desc');
                                                setFirstNameSortOrder('asc')
                                            }}>
                                            </i>}</th>
                                        <th scope="col">Requested At {firstNameSortOrder === 'asc' ?
                                            <i className="fa fa-fw fa-sort" onClick={() => {
                                                sortItemHandler('createdAt', 'asc');
                                                setFirstNameSortOrder('desc')
                                            }}></i>
                                            :
                                            <i className="fa fa-fw fa-sort" onClick={() => {
                                                sortItemHandler('createdAt', 'desc');
                                                setFirstNameSortOrder('asc')
                                            }}>
                                            </i>}</th>
                                        <th scope="col">Status</th>
                                        {/* <th scope="col">Created At</th> */}
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(waitingList.length > 0) &&
                                        waitingList.map((item) => {
                                            return (<tr key={item?.id}>
                                                {/* <td>{item?.id}</td> */}
                                                <td>{item?.first_name + ' ' + item?.last_name}</td>
                                                <td>{item?.email}</td>
                                                <td>{item?.phone || '--'}</td>
                                                <td>{item?.role?.role}</td>
                                                <td>
                                                    {item?.createdAt
                                                        ? moment(
                                                            DateFormat(item?.createdAt)
                                                        ).format('MM/DD/yyyy H:mm')
                                                        : '--'}
                                                </td>
                                                <td>
                                                    {item?.is_approved === 0 ? 'Rejected' : item?.is_approved === 1 ? 'Approved' : 'Pending'}
                                                </td>
                                                {/* <td>
                                                    Created At
                                                </td> */}
                                                <td className="text-right">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            className="btn-icon-only text-light"
                                                            href="#pablo"
                                                            role="button"
                                                            size="sm"
                                                            color=""
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            <i className="fas fa-ellipsis-v" />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-arrow" container="body">
                                                            <DropdownItem
                                                                href="javascript:void(0)"
                                                                onClick={() => viewUser(item)}
                                                            >
                                                                View
                                                            </DropdownItem>
                                                            <DropdownItem
                                                                onClick={() => navigate(`/waiting-approval/edit/${item?.id}`)}
                                                            >
                                                                Edit
                                                            </DropdownItem>
                                                            {/* <DropdownItem
                                                            href="javascript:void(0)"

                                                            >
                                                                Delete
                                                            </DropdownItem> */}
                                                            {/* <DropdownItem
                                                            href="javascript:void(0)"
                                                              onClick={() => {
                                                                setSelectedId(user.email);
                                                                setResetModal(true);
                                                              }}
                                                            >
                                                                Reset Password
                                                            </DropdownItem> */}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                {totalPage > 1 ?
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem
                                                // className="disabled"
                                                className={page < 2 ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page - 2);
                                                    }}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {[...Array(totalPage)].map((_, i) => {
                                                return (
                                                    <PaginationItem
                                                        key={i}
                                                        // className="active"
                                                        className={page === i + 1 ? "active" : ""}
                                                    >
                                                        <PaginationLink
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                onPageChange(i + 1);
                                                            }}
                                                        >
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                            })}
                                            <PaginationItem
                                                // className="disabled"
                                                className={page + 2 > totalPage ? "disabled" : ""}
                                            >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onPageChange(page + 2);
                                                    }}
                                                >
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>
                                    : null}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>

        </AdminLayout >

    );
};

export default WaitingApprovalList;
