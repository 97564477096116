import React from "react";

const ErrorMessage = (props) => {
  return (
    <div
      className="err-message"
      style={{
        width: "100%",
        color: "red",
        fontSize: "13px",
        paddingTop: "3px",
      }}
    >
      <span
        className="err_svg"
        style={{ marginRight: "5px", position: "relative", bottom: "2px" }}
      >
        <svg
          aria-hidden="true"
          className="stUf5b qpSchb"
          fill="currentColor"
          focusable="false"
          width="16px"
          height="16px"
          viewBox="0 0 24 24"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
        </svg>
      </span>
      {props.errorMsg}
    </div>
  );
};

export default ErrorMessage;
