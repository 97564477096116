import React, {useEffect, useState} from 'react'
import { Container, Row, Col, Spinner } from 'reactstrap'
import { CiSearch } from "react-icons/ci";
import DropdownCustom from '../../../components/Buttons/DropdownCustom';
import TransactionTable from './TransactionTable';
import { useDispatch, useSelector } from 'react-redux';
import { GetTransction } from '../../../redux/actions/client/transaction';
import useDebounce from '../../../components/custom-hooks/useDebounce';


const DashboardTable = () => {
    const dispatch =  useDispatch()
    const [search, setSearch] = useState();
    const [optionData, setOptionData] = useState("completed");
    const [optionOrderData, setOptionOrderData] = useState("ASC");
    const [loading, setLoading] = useState(false)
    const {data}  = useSelector((state) => state?.transaction?.transaction)

    const debouncedSearchInput = useDebounce(search, 500); 

    const options = ['ASC', 'DESC'];
    const filterOption = ['pending', 'completed', 'failed'];

    const handleChange = (e) => {
        setLoading(true)
        setSearch(e.target.value);
    }

    const handleSelect = (e , type) => {
        setLoading(true)
        if(type === 'status') {
            setOptionData(e)
        }else if(type === 'order') {
            setOptionOrderData(e)
        }
        
    };


    useEffect(() => {
        const payload = {
            order: optionOrderData,
            status: optionData,
            search: debouncedSearchInput === '' ? 0 : debouncedSearchInput
        };
        dispatch(GetTransction(payload))
    }, [debouncedSearchInput, optionOrderData ,optionData, dispatch]); 

    useEffect(() => {
        setLoading(false)
    },[data])
    



  return (
    <Container className='dashboard-table mt-4 px-0'>
        <Row>
            <Col md={12} className='px-0'>        
                <div className='dashboard-table-title'>
                    <p className='mb-0'>My Transaction</p>
                </div>
            </Col>
            <Col md={6} className='mt-3 px-0'>
                <div className='search-group d-flex align-items-center'>
                    <CiSearch />
                    <input className='p-2' placeholder='Search By Transaction Id' type='number' numeric name='search' value={search} onChange={handleChange}/>
                </div>
            </Col>
            <Col md={3} className='px-0'>
                <div className='sort d-flex align-items-center'>
                    <img src='../assets/sort.svg' />
                    <label htmlFor='sort' className='mb-0 pl-2'>Sort By:</label>
                    <DropdownCustom 
                        className='dashboard-drop dark-text pl-2'
                        name="sort"
                        title=""
                        isRequired = {false}
                        options={options}
                        onSelect={(e) => handleSelect(e ,'order')}
                        defaultOption='Latest'
                        value={optionOrderData}
                    />
                </div>
            </Col>
            <Col md={3} className='px-0'>
                <div className='sort filter d-flex align-items-center'>
                    <img src='../assets/filter.svg' alt='filter-img' />
                    <label htmlFor='sort' className='mb-0 pl-2'>Filter By:</label>
                    <DropdownCustom 
                        className='dashboard-drop dark-text pl-2'
                        name="sort"
                        title=""
                        isRequired = {false}
                        options={filterOption}
                        onSelect={(e) => handleSelect(e, 'status')}
                        defaultOption='completed'
                        value={optionData}
                    />
                </div>
            </Col>
            <Col md={12} className='px-0'>
            {loading? <div className='d-flex justify-content-center mt-4'> <Spinner></Spinner> </div> : 

                <TransactionTable data={data} />
            }

            </Col>
        </Row>
    </Container>
  )
}

export default DashboardTable