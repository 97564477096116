import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { LuLayoutDashboard, LuBadgeDollarSign, LuWallet2, LuHelpingHand, LuMapPin, LuHeartHandshake } from "react-icons/lu";
import { TbAddressBook } from "react-icons/tb";
import DashboardHome from './DashboardHome';

const Sidebar = ({ onComponentChange, onTitleChange }) => {
    const navigate = useNavigate();
    const location = useLocation();


    return (
        <>
            <div className='logo d-flex justify-content-center'>
                <Link to='/client/dashboard' className='p-0 m-0'>
                    <img src='../assets/logo-dashboard.webp' alt='logo' />
                </Link>
            </div>
            <div className='dashboard-items pt-4'>
                <ul className='m-0 p-0'>
                    <div className='dashboard-items pt-4'>
                        <ul className='m-0 p-0'>
                            <li onClick={() => navigate("/client/dashboard")} className={`d-flex m-0 p-0 d-flex justify-content-between align-items-center px-3 ${location.pathname.includes("/client/dashboard") && ("active")}`}  >
                                <div navbar={true} className={`d-flex align-items-baseline fontsize-navbar`} role="button" >
                                    <LuLayoutDashboard />
                                    <p className="m-0 p-0 pl-3">Dashboard</p>
                                </div>
                            </li>

                            <li onClick={() => navigate("/client/recharge")} className={`d-flex m-0 p-0 d-flex justify-content-between align-items-center px-3 ${location.pathname.includes("/client/recharge") && ("active")}`}  >
                                <div navbar={true} className={`d-flex align-items-baseline fontsize-navbar`} role="button" >
                                    <LuBadgeDollarSign />
                                    <p className="m-0 p-0 pl-3">Recharge Account</p>
                                </div>
                            </li>

                            <li onClick={() => navigate("/client/profile")} className={`d-flex m-0 p-0 d-flex justify-content-between align-items-center px-3 ${location.pathname.includes("/client/profile") && ("active")}`}  >
                                <div navbar={true} className={`d-flex align-items-baseline fontsize-navbar`} role="button" >
                                    <TbAddressBook />
                                    <p className="m-0 p-0 pl-3">Profile Management</p>
                                </div>
                            </li>

                            <li onClick={() => navigate("/client/transaction")} className={`d-flex m-0 p-0 d-flex justify-content-between align-items-center px-3 ${location.pathname.includes("/client/transaction") && ("active")}`}  >
                                <div navbar={true} className={`d-flex align-items-baseline fontsize-navbar`} role="button" >
                                    <TbAddressBook />
                                    <p className="m-0 p-0 pl-3">Transaction</p>
                                </div>
                            </li>

                            <li onClick={() => navigate("/client/geolocation")} className={`d-flex m-0 p-0 d-flex justify-content-between align-items-center px-3 ${location.pathname.includes("/client/geolocation") && ("active")}`}  >
                                <div navbar={true} className={`d-flex align-items-baseline fontsize-navbar`} role="button" >
                                    <TbAddressBook />
                                    <p className="m-0 p-0 pl-3">Map View</p>
                                </div>
                            </li>

                            <li onClick={() => navigate("/client/invite")} className={`d-flex m-0 p-0 d-flex justify-content-between align-items-center px-3 ${location.pathname.includes("/client/invite") && ("active")}`}  >
                                <div navbar={true} className={`d-flex align-items-baseline fontsize-navbar`} role="button" >
                                    <TbAddressBook />
                                    <p className="m-0 p-0 pl-3">Invite User</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>
        </>
    )
}

export default Sidebar