import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    CardBody,
    FormGroup,
    Form,
    Input,
    Col,
} from "reactstrap";
import { useParams, useNavigate } from 'react-router';
import ReactQuill from "react-quill";
// core components
import { baseURL } from "../../config/filepath";
import Placeholder from "../../assets/images/team-4.jpg";
import ListHeader from "../../components/Headers/ListHeader";
import AdminLayout from "../../layouts/AdminLayout";
import useGetService from '../../services/getservice';
import usePutService from '../../services/putservice';
import ErrorMessage from '../../components/Validation/ErrorMessage';
import usePostService from '../../services/postservice';

const TOOLBAR_OPTIONS = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["clean"],
];

const RightDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [image, setimage] = useState("");
    let [pagename, setPagename] = useState('');
    const [imagepayload, setimagepayload] = useState('');
    const [buttonimageState, setbuttonimageState] = useState(false);
    let [fromData, setFromData] = useState(
        {
            name: "",
            description: "",
            button_url: "",
            image: ""
        },
    );
    let [error, setError] = useState({
        name: "",
        description: "",
        button_url: ""
    });
    const [pageTitle, setpageTitle] = useState("");
    const [description, setdescription] = useState(false);
    const [updateInfoButtonState, setUpdateInfoButtonState] = useState(false);
    const [uploadpayload, setuploadpayload] = useState('');

    let response = usePostService(imagepayload, "/api/imageUpload/cms_upload", buttonimageState);
    let { response: updateInfoResponse } = useGetService(`/api/admin/getServiceById/${id}`);

    let updateResponse = usePutService(uploadpayload, `/api/admin/updateService/${id}`, updateInfoButtonState)

    useEffect(() => {
        if (updateInfoResponse?.status === "200-OK") {
            let updateDetails = updateInfoResponse?.data?.data;
            setFromData({
                name: updateDetails?.name ? updateDetails?.name : '',
                description: updateDetails?.description ? updateDetails?.description : '',
                button_url: updateDetails?.button_url ? updateDetails?.button_url : '',
                image: updateDetails?.image ? updateDetails?.image : '',
            });
            setPagename(updateDetails?.page_name ? updateDetails?.page_name : '');
            setdescription(updateDetails?.description ? updateDetails?.description : '');
            setpageTitle(updateDetails?.page_title ? updateDetails?.page_title : '');
        }
    }, [updateInfoResponse]);

    useEffect(() => {
        if (response?.status === "200-OK") {
            if (id) {
                setuploadpayload({
                    ...fromData,
                    description: description,
                    image: response?.data?.fileData[0].file_name
                });
                setUpdateInfoButtonState(true);
            } else {
                let arrayformdata = { ...fromData, name: fromData?.name, description: description, image: response?.data?.fileData[0].file_name, page_name: pagename };
                setuploadpayload(arrayformdata);
                setUpdateInfoButtonState(true);
            }
        }
    }, [response]);

    useEffect(() => {
        if (updateResponse?.status === "200-OK" && updateInfoButtonState) {
            toast.success(updateResponse?.data?.message);
            setUpdateInfoButtonState(false);
            navigate('/content-management')
        }

    }, [updateResponse]);

    const handleInput = (e) => {
        setFromData({ ...fromData, [e.target.name]: e.target.value });
    }

    const validate = () => {
        let isError = false;
        let temp = { ...error };
        if (fromData?.name === "") {
            temp.name = "Title can't be empty"
            isError = true;
        }
        if (description === "") {
            temp.description = "Description can't be empty"
            isError = true;
        }
        if (fromData?.button_url === "") {
            temp.button_url = "Video url can't be empty"
            isError = true;
        }
        setError(temp);
        return isError;
    }

    const handleFieldImageChange = (e) => {
        setimage(e.target.files[0]);
    };

    const handleSubmit = () => {
        let isError = validate();
        if (!isError) {
            if (image !== "" && typeof image !== 'string') {
                var formData = new FormData();
                formData.append("image_upload", image);
                setimagepayload(formData)
                setbuttonimageState(true)
            } else {
                if (id) {
                    setuploadpayload({ ...fromData, description: description });
                    setUpdateInfoButtonState(true);
                }
            }
        }
    }

    return (
        <AdminLayout>
            <>
                {/* {loading && <Loader></Loader>} */}
                <ListHeader />
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <div className="row">
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <Row className="align-items-center">
                                                <Col xs="8">
                                                    <h3 className="mb-0">
                                                        Update {pageTitle}
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Form>
                                                <h6 className="heading-small text-muted mb-4">
                                                    {pageTitle} Information
                                                </h6>

                                                <div className="pl-lg-4">
                                                    <div
                                                        className="card-img-top"
                                                        style={{
                                                            borderRadius: "3%",
                                                            width: "320px",
                                                            height: "190px",
                                                            overflow: "hidden",
                                                            display: "block",
                                                            margin: "0 auto",
                                                        }}
                                                    >
                                                        <img
                                                            src={image !== "" ? URL?.createObjectURL(image) : fromData.image !== '' ? baseURL + 'public/cmsUploadFile/' + fromData.image : Placeholder}
                                                            crossOrigin="anonymous"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: "cover",
                                                            }}
                                                            alt=""
                                                        />{" "}
                                                        <div className="d-flex justify-space-between w-100 align-items-start mt-4"></div>
                                                    </div>
                                                    <div className="d-flex justify-content-center p-2">
                                                        <label className="fileUpload">
                                                            <input
                                                                type="file"
                                                                id="image"
                                                                name="logo"
                                                                hidden
                                                                accept="image/jpeg, image/png, image/jpg"
                                                                onChange={handleFieldImageChange}
                                                                multiple={false}
                                                                style={{
                                                                    display: "none",
                                                                }}
                                                            />
                                                            <span
                                                                className="d-flex justify-content-center"
                                                                style={{
                                                                    display: "inline-block",
                                                                    color: "red",
                                                                }}
                                                            >
                                                                {image !== "" ? "Change Background Image" : "Upload Background Image"}
                                                                {/* {(OverviewImage !==""||OverViewImageResponse !=="" ) ? "Change Image":"Upload Image"} */}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-title"
                                                                >
                                                                    Title
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-title"
                                                                    placeholder="Title"
                                                                    type="text"
                                                                    name="name"
                                                                    autoComplete="nope"
                                                                    value={fromData?.name}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error?.name ? (
                                                                    <ErrorMessage errorMsg={error.name} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-description"
                                                                >
                                                                    Description
                                                                </label>
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    value={description}
                                                                    onChange={setdescription}
                                                                    placeholder="Start writing..."
                                                                    modules={{
                                                                        toolbar: {
                                                                            container: TOOLBAR_OPTIONS,
                                                                        }
                                                                    }}
                                                                />
                                                                {error?.description ? (
                                                                    <ErrorMessage errorMsg={error.description} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-title"
                                                                >
                                                                    Video Link
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-title"
                                                                    placeholder="Button Url"
                                                                    type="url"
                                                                    name="button_url"
                                                                    autoComplete="nope"
                                                                    value={fromData?.button_url}
                                                                    onChange={(e) => handleInput(e)}
                                                                />
                                                                {error?.button_url ? (
                                                                    <ErrorMessage errorMsg={error.button_url} />
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </div>

                                                <div></div>
                                                <hr className="my-4" />
                                                <div className="pl-lg-4">
                                                    <FormGroup className="d-flex justify-content-end">
                                                        <Button
                                                            color="info"
                                                            className="no-transform"
                                                            onClick={() => handleSubmit()}
                                                        >
                                                            Update
                                                        </Button>
                                                        <Button
                                                            color="warning"
                                                            className="no-transform"
                                                            onClick={() => navigate("/content-management")}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </FormGroup>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        </AdminLayout>
    )
}

export default RightDetails;