import axiosInstanceClient from "../../Api/client";
import { baseURL } from "../../config/filepath"

const getTransferTrasaction = (payload) => {
    return axiosInstanceClient.get(baseURL + `client/transfer-transactions?page=${payload.page}&size=${payload.size}&order=${payload.order}&search=${payload.search}&status=${payload.status}`)
    .then((response) => {
        return response.data
    })
};


const getRecipient = (payload) =>  {
    return axiosInstanceClient.get(baseURL + `client/get-search-client-accounts?search=${payload}`)
    .then((response) => {
        return response.data
    })
};


const sendMoneyToRegisteredClient = (payload) =>  {
    return axiosInstanceClient.post(baseURL + `client/send-money`, payload)
    .then((response) => {
        return response.data
    })
};

const sendMoneyToUnRegisteredClient = (payload) =>  {
    return axiosInstanceClient.post(baseURL + `client/send-money-to-unregisterd-client`, payload)
    .then((response) => {
        return response.data
    })
};
// eslint-disable-next-line import/no-anonymous-default-export
export default { getTransferTrasaction  ,getRecipient ,sendMoneyToRegisteredClient , sendMoneyToUnRegisteredClient}