import React, {useState} from 'react'
import { Col, Container, Row } from 'reactstrap'
import RadioButton from '../../components/Buttons/RadioButton'
import InputField from '../../components/Buttons/InputField'
import CheckBox from '../../components/Buttons/CheckBox'
import ButtonComponent from '../../components/Buttons/ButtonSubmit'
import { Link } from "react-router-dom";

const Login = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);


  const handleRadioChange = (event) => {
      setSelectedOption(event.target.id);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Container fluid className='normal-user px-0 mx-0'>
      <Row className='row px-0 mx-0'>
        <Col md={12} xl={7} className='px-0 mx-0 left-column d-flex justify-content-center'>
          <div className='m-0 left-inner w-100'>
            <Row className='row px-0 mx-0'>
              <Col className='col-md-12 mx-0'>
                <h2 className=''>Login<span className='d-block mt-2'>Welcome! Let’s get started!</span></h2>
              </Col>

              <Col md={12} className='mx-0 mt-3'>
                <div className='radio-btn-wrap d-flex align-items-center flex-wrap'>
                  <div className='mr-18 mb-0 d-flex align-items-center'><p className='mb-2'>Client Type*</p></div>
                  <div className='mb-0 d-flex align-items-center flex-wrap'>
                    <div className='mr-2 mr-10 mb-2'>
                      <RadioButton
                          id="option1"
                          name="custom-radio"
                          checked={selectedOption === 'option1'}
                          onClick={handleRadioChange}
                          label="Normal"
                      />
                    </div>
                    <div className='mr-10 mb-2'>
                    <RadioButton
                          id="option2"
                          name="custom-radio"
                          checked={selectedOption === 'option2'}
                          onClick={handleRadioChange}
                          label="Associate"
                      />
                    </div>
                    <div className='mr-0 mb-2'>
                    <RadioButton
                          id="option3"
                          name="custom-radio"
                          checked={selectedOption === 'option3'}
                          onClick={handleRadioChange}
                          label="Agency"
                      />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={12} className='form-inputs mt-5'>
                <InputField
                  type="email"
                  label="Email Address"
                  placeholder="Enter your email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputField
                  type="password"
                  label="Password"
                  placeholder="Enter your password"
                  imageSrc="../assets/info.png"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='mt-4'
                />
              </Col>

              <Col md={12} className='mt-4 checkbox position-relative'>
                <CheckBox
                  className="custom-check"
                  id="terms-checkbox"
                  label={
                      <>
                        Yes, I agree to the <span className="highlighted-text">Terms & Conditions</span> agreement.
                      </>
                    }
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  />
              </Col>

              <Col md={12} className='mt-5'>
                <ButtonComponent>Login</ButtonComponent>
              </Col>

              <Col md={12} className='mt-4 signup-link'>
                  <p>Don’t have an account? 
                    <Link to='/html/signup' className='text-decoration-none'> Sign Up</Link> </p>
              </Col>

            </Row>  
          </div>
        </Col>
    
        <Col md={12} xl={5} className='px-0 mx-0 right-column'>
          <img src='../assets/login-banner.webp' />
        </Col>
      </Row>
    </Container>
  )
}

export default Login