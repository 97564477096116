export default {
    enterFirstName: 'Enter a first name',
    enterNpiNumber: 'Enter npi number',
    enterLastName: 'Enter a last name',
    enterAnEmail: 'Enter an email',
    invalidEmail: 'Enter a valid email',
    enterAPassword: 'Enter a password',
    enterAPhone: 'Enter a phone',
    enterARole: 'Enter a role',
    enterSkill: 'Enter Skill',
    enterADescription: 'Enter a description',
    selectARole: 'Select a role',
    phoneLength: 'Phone must be 10 digit',
    enterCompanyName: 'Enter a company name',
    enterCompanyAddress: 'Enter a company address',
    enterCompanyCity: 'Enter a company city',
    enterCompanyState: 'Enter a company state',
    enterCompanyZip: 'Enter a company zip',
    enterCompanyWebsite: 'Enter a company website',
    enterMaxSessionTimeOut: 'Enter a max session time-out',
    enterMaxSignInAttempt: 'Enter a max sign-in attempt',
    enterValidSignInAttempt: 'Max 10 attempts are allowed',
    enterQuestion: 'Enter a valid question',
    enterAnswer: 'Enter valid answer',
    enterKey: 'Enter a valid key',
    enterContent: 'Enter valid content',
    enterCity: 'Enter City',
    enterState: 'Enter State',
    enterCountry: 'Enter Country',
    enterZip: 'Enter Zip',
    enterCounty: 'Enter County',
    invalidZip: 'Enter a valid zip',
    enterDefendant: 'Enter Defendant Lawyer',
    enterPlaintiff: 'Enter Plaintiff Lawyer',
    enterDefendantLawyer: 'Select Defendant Lawyer',
    enterPlaintiffLawyer: 'Select Plaintiff Lawyer',
    enterwitness: 'Enter Witness',
    enterCasename: 'Enter Casename',
    enterCourt: 'Enter Court',
    enterClaim: 'Enter Claim',
    enterFile: 'Enter File',
    enterDetails: 'Enter Details',
    enterName: 'Enter Name',
    requestedByMessage: 'Select Name',
}
