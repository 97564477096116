// RechargeHistory.js
import React from 'react';

const RechargeHistory = () => {
    return (
        <div>
            <h2>Recharge History</h2>
        </div>
    );
};

export default RechargeHistory;
