/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
import { useNavigate, useParams } from "react-router";
import AdminLayout from "../../layouts/AdminLayout";
import ListHeader from "../../components/Headers/ListHeader";
import useConditiongetService from "../../services/conditiongetService";

const depositionSetTimeManagment = () => {
  const { depositionId } = useParams();
  const [isUpdate, setisUpdate] = useState(true);
  const [addRow, setAddRow] = useState([{ name: "", email : ""}]);
  console.log("depo",depositionId);
  let { response2,isFetchItems } = useConditiongetService(`api/depo/getDepoDetailsById/${depositionId}`, isUpdate);
  console.log("res",response2);
  const handleDelete=(index)=>{
    let newarray=addRow.filter((item,id)=>{
      return id!=index
    })
    setAddRow(newarray)
  }
  let handleAdd = () => {
    setAddRow([...addRow, { name: "", email: "" }])
 }
  return (
    <AdminLayout name="Deposition Management">
      <>
        {/* {loading && <Loader></Loader>} */}
        <ListHeader />
        {/* <ToastContainer /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col" >
              <div className="row">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="alhttps://meet.google.com/kuf-tfxf-mtuign-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            SET TIME
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          SET TIME
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            {/* <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Start Time
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id="input-last-name"
                                  placeholder="Last name"
                                  type="text"
                                  name="last_name"
                                  autoComplete="nope"
                                  value={last_name}
                                  onChange={(e) => handleInput(e)}
                                /> */}
                                {/* {error.last_name ? (
                                  <ErrorMessage errorMsg={error.last_name} />
                                ) : null} */}
                              {/* </FormGroup>
                            </Col> */}
                            {/* <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Last time
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  placeholder="Email"
                                  type="email"
                                  name="email"
                                  autoComplete="nope"
                                  value={email}
                                  onChange={(e) => handleInput(e)}
                                /> */}
                                {/* {error.email ? (
                                  <ErrorMessage errorMsg={error.email} />
                                ) : null} */}
                              {/* </FormGroup>
                            </Col> */}
                            {/* <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Type
                                </label>
                                <Input
                                  type="select"
                                  name="is_active"
                                  id="selectMulti"
                                  // value={is_active}
                                  // onChange={(e) => handleInput(e)}
                                >
                                  <option value="1">Virtual</option>
                                  <option value="0">Inperson</option>
                                </Input>
                              </FormGroup>
                            </Col> */}
                          </Row>
                          <Row>
                          {/* <h6 className="heading-small text-muted mb-4">
                          Attendees
                        </h6> */}
                          </Row>
                          {
                            addRow.map((value,index)=>{
                              return <Row >
                              <Col lg="3">
                                <FormGroup className="form-group">
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Email Id
                                  </label>
                                  <Input
                                    type="select"
                                    name="role_id"
                                    id="selectMulti"
                                    // value={item2.Id}
                                    // onChange={(e) =>
                                    //   handleInput(e, i2, "id")
                                    // }
                                  >
                                    <option value="">
                                      Choose from email id
                                    </option>
                                    {/* {subChildArrayList?.map((item3, i3) => (
                                      <option value={item3.Id}
                                       key={i3}
                                       className={formValues.some(obj => obj.Id === item3.Id)?"option-select-property":null}
                                       >
                                        {item3.amazonDisplayName}
                                      </option>
                                    ))} */}
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup className="form-group">
                                  <label
                                    className="form-control-label"
                                    // htmlFor="input-first-name"
                                  >
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Display Name"
                                    // value={item2.DisplayName}
                                    // onChange={(e) =>
                                    //   handleInput(e, i2, "name")
                                    // }
                                    onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup className="form-group">
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Show Calender
                                  </label>
                                  <Input
                                    type="select"
                                    name="role_id"
                                    id="selectMulti"
                                    // value={item2.Id}
                                    // onChange={(e) =>
                                    //   handleInput(e, i2, "id")
                                    // }
                                  >
                                    <option value="">
                                      Choose from Sub category
                                    </option>
                                    {/* {subChildArrayList?.map((item3, i3) => (
                                      <option value={item3.Id}
                                       key={i3}
                                       className={formValues.some(obj => obj.Id === item3.Id)?"option-select-property":null}
                                       >
                                        {item3.amazonDisplayName}
                                      </option>
                                    ))} */}
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col lg="2" >
                            { index!=0?      <div
                                    className="btn btn-lg btn-icon"
                                    onClick={() => handleDelete(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="currentColor"
                                      className="bi bi-trash"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                      />
                                    </svg>
                                  </div>:""}
                                </Col>
                            </Row>
                            })
                          }
                                <Row>
                              <Col
                                lg="12"
                                className=""
                              >
                                {/* <Button 
                                  className="add-more-icon mb-3"
                                  onClick={() => handleAdd()}
                                  // disabled={active !== item.categoryId}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                  </svg>
                                  <span>Add Attendees</span>
                                </Button> */}
                                
                              </Col>
                            </Row>
                        </div>
                        <hr className="my-4" />
                        {/* <div className="pl-lg-4"> */}
                          {/* <FormGroup className="d-flex justify-content-end">
                            {
                              (mode === 'update') ? <Button
                                color="info"
                                className="no-transform"
                                onClick={() => handleSubmit()}
                              >
                                Update
                              </Button> : <Button
                                color="info"
                                className="no-transform"
                                onClick={() => handleSubmit()}
                              >
                                Submit
                              </Button>
                            }
                            <Button
                              color="warning"
                              className="no-transform"
                              onClick={() => navigate("/user-management")}
                            >
                              Cancel
                            </Button>
                          </FormGroup> */}
                        {/* </div> */}
                        <div className="pl-lg-4">
                          <FormGroup className="d-flex justify-content-end">
                            <Button
                                color="info"
                                className="no-transform"
                                // onClick={() => handleSubmit()}
                              >
                                Next
                              </Button>
                            
                            <Button
                              color="warning"
                              className="no-transform"
                              // onClick={() => navigate("/user-management")}
                            >
                              Cancel
                            </Button>
                          </FormGroup>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </Row>
        </Container>
      </>
    </AdminLayout>
  )
}

export default depositionSetTimeManagment